import React, { useState } from "react";
import ModalWindow from "../../../../components/ModalWindow";
import providers from "../../../../lib/providers";
import { I18n, t } from "../../../../locales";
import BaseForm from "../../../BaseForm";
import BaseButton from "../../../BaseButton";
import ActionStatus from "../../../ActionStatus";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function ModalContactUs({ show, onClose }: Props) {
  const [processing, setProcessing] = useState(false);
  const [showSuccessStatus, setShowSuccessStatus] = useState(false);
  const { values, setValues, fields, errors, setErrors, isValid } = useViewController();

  const onCloseModal = () => {
    setValues({});
    setShowSuccessStatus(false);
    onClose();
  };

  const onSubmit = async () => {
    if (!isValid) {
      return;
    }

    const data = {
      name: values.name,
      email: values.email,
      message: values.message,
    };

    try {
      setProcessing(true);
      await providers.ContactUsProvider.contactUs({ data });
      setShowSuccessStatus(true);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <ModalWindow className="modal-contact-us" show={show} onClose={onCloseModal}>
      {showSuccessStatus ? (
        <ActionStatus
          status="success"
          title={t("Thank you for contacting support!")}
          className="modal-contact-us__success"
          description={t("We will contact you as soon as possible.")}
        >
          <BaseButton className="mt-6" name="awesome" onClick={onCloseModal} fluid large>
            {t("Awesome")}
          </BaseButton>
        </ActionStatus>
      ) : (
        <div>
          <div className="headline-1 text-center mb-2">Contact Us</div>
          <div className="text-center mb-8">
            <I18n
              path="Please fill in the form below and <mail>support@securetheoffer.com</mail> will contact you."
              components={{
                mail: (
                  <a className="modal-contact-us__link" href="mailto:support@securetheoffer.com">
                    mail
                  </a>
                ),
              }}
            />
          </div>

          <BaseForm
            className="modal-contact-us__form"
            fields={fields}
            values={values}
            onChange={setValues}
            errors={errors}
            setErrors={setErrors}
            onSubmitEnter={onSubmit}
          >
            <BaseButton className="mt-8 ml-auto" large loading={processing} disabled={!isValid} onClick={onSubmit}>
              {t("Submit")}
            </BaseButton>
          </BaseForm>
        </div>
      )}
    </ModalWindow>
  );
}
