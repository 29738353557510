import React from "react";
import { AttachedFileData, AttachedFileError } from "../../types";
import BaseIcon from "../BaseIcon";
import BaseButton from "../BaseButton";
import FileType from "../FileType";
import "./style.scss";

interface Props {
  className?: string;
  attachedFileData: AttachedFileData | AttachedFileError;
  removeAttachment?: (id: string) => void;
  link?: string;
  showPreview?: boolean;
}

function MessageAttachView({ className, attachedFileData, removeAttachment, link, showPreview = true }: Props) {
  const cls = ["file-attached"];
  if (className) cls.push(className);

  if ("error" in attachedFileData) {
    cls.push(" __error");
    return (
      <div className={cls.join(" ")}>
        <span className="file-attached__message label">{attachedFileData.error}</span>
        {removeAttachment && (
          <BaseButton
            className="file-attached__remove"
            small={true}
            icon={true}
            borderless={true}
            name="close-error"
            onClick={() => removeAttachment(attachedFileData.id)}
          >
            <BaseIcon size={18}>close</BaseIcon>
          </BaseButton>
        )}
      </div>
    );
  }

  const content = (
    <>
      {showPreview && attachedFileData.src && (
        <img className="file-attached__image mb-1" src={attachedFileData.src} alt={attachedFileData.fileName} />
      )}
      <FileType
        fileName={attachedFileData.fileName}
        fileSize={attachedFileData.size}
        uploadProgress={attachedFileData.uploadProgress}
      />
    </>
  );

  return link ? (
    <a className={cls.join(" ")} href={link} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  ) : (
    <div className={cls.join(" ")}>{content}</div>
  );
}

export default MessageAttachView;
