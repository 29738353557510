import { MediaConstraints } from "../types";
import { useEffect, useState } from "react";

export default function useMediaConstraints(defaultConstraints: MediaConstraints) {
  const storageFieldName = "mediaConstraints";
  const [mediaConstraints, setMediaConstraints] = useState<MediaConstraints>(() => {
    const storageConstraints = JSON.parse(localStorage.getItem(storageFieldName) ?? "{}");
    return {
      video: storageConstraints.video ?? defaultConstraints.video ?? false,
      audio: storageConstraints.audio ?? defaultConstraints.audio ?? false,
      videoEnabled: storageConstraints.videoEnabled ?? defaultConstraints.videoEnabled ?? false,
      audioEnabled: storageConstraints.audioEnabled ?? defaultConstraints.audioEnabled ?? false,
      shareEnabled: defaultConstraints.shareEnabled ?? false,
      blurEnabled: storageConstraints.blurEnabled ?? defaultConstraints.blurEnabled ?? false,
    };
  });

  useEffect(() => {
    localStorage.setItem(storageFieldName, JSON.stringify(mediaConstraints));
  }, [mediaConstraints]);

  return {
    mediaConstraints,
    setMediaConstraints,
  };
}
