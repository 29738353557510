import { useEffect, useState } from "react";
import { validateFieldsWithException } from "../../../../lib/validation";
import { parseErrors } from "../../../../lib/requestHelpers";
import { useTranslation } from "../../../../locales";
import useBaseForm from "../../../../hooks/useBaseForm";
import useMounted from "../../../../hooks/useMounted";
import useController from "./controller";

export default function useViewController(onNext: () => Promise<void>) {
  const { t } = useTranslation();
  const mounted = useMounted();
  const [processing, setProcessing] = useState(false);
  const { loading, info, sendData } = useController();
  const { fields, values, errors, setValues, setErrors } = useBaseForm([
    {
      name: "fullName",
      type: "text",
      label: t("Full Name"),
      validation: {
        required: true,
      },
    },
    {
      name: "contactEmail",
      type: "email",
      iconLeft: "mail",
      label: t("Contact Email"),
      validation: {
        required: true,
      },
    },
    {
      name: "phone",
      type: "tel",
      iconLeft: "phone",
      label: t("Phone Number"),
      validation: {
        required: true,
      },
    },
  ]);

  const onSubmit = async () => {
    try {
      setProcessing(true);
      validateFieldsWithException(fields, values);
      await sendData(values);
      await onNext();
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      mounted.current && setProcessing(false);
    }
  };

  useEffect(() => {
    if (!mounted.current) return;
    if (!info) {
      setValues({});
      return;
    }
    const firstName = info.firstName ? `${info.firstName} ` : "";
    const lastName = info.lastName ? `${info.lastName} ` : "";
    const fullName = `${firstName}${lastName}`.trim();
    setValues({
      fullName,
      contactEmail: info.contactEmail,
      phone: info.phone,
    });
  }, [info, setValues, mounted]);

  return {
    loading,
    processing,
    fields,
    values,
    errors,
    setValues,
    setErrors,
    onSubmit,
  };
}
