import { useEffect, useState } from "react";
import { Appointment } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { differenceInMilliseconds } from "@scrile/tools/dist/lib/TimeHelpers";
import emitter, { EVENTS } from "../../lib/emitter";
import { isPerformer } from "../../lib/usersHelper";
import useController from "./controller";

export default function useViewController() {
  const { subscribeToAppointment, searchActiveAppointments, user } = useController();
  const [show, setShow] = useState(false);
  const [forceHide, setForceHide] = useState(false);
  const [appointment, setAppointment] = useState<Appointment | null>(null);

  const closeWhenTimeEnds = (appointment: Appointment) => {
    const ms = differenceInMilliseconds(new Date(appointment.endTime), new Date(new Date().toISOString()));
    return setTimeout(() => {
      setShow(false);
    }, ms);
  };

  useEffect(() => {
    if (!user) {
      setShow(false);
      return;
    }
    let unsubscribe: () => void;
    let timeout: ReturnType<typeof setTimeout>;

    (async function () {
      const response = await searchActiveAppointments();
      setAppointment(response);
      setShow(!!response);

      if (response) {
        timeout = closeWhenTimeEnds(response);
      }

      const subscription = await subscribeToAppointment((appointment) => {
        setAppointment(appointment);
        setShow(true);
        clearTimeout(timeout);
        timeout = closeWhenTimeEnds(appointment);
      });

      unsubscribe = () => subscription.unsubscribe();
    })();

    return () => {
      timeout && clearTimeout(timeout);
      unsubscribe && unsubscribe();
    };
  }, [searchActiveAppointments, subscribeToAppointment, user]);

  useEffect(() => {
    if (!user) return;

    const hideBanner = () => {
      setForceHide(true);
    };
    const showBanner = () => {
      setForceHide(false);
    };

    emitter.on(EVENTS.APPOINTMENT_BANNER_HIDE, hideBanner);
    emitter.on(EVENTS.APPOINTMENT_BANNER_SHOW, showBanner);

    return () => {
      emitter.off(EVENTS.APPOINTMENT_BANNER_HIDE, hideBanner);
      emitter.off(EVENTS.APPOINTMENT_BANNER_SHOW, showBanner);
    };
  }, [user]);

  return {
    meetingPartner: isPerformer(user) ? appointment?.consumer : appointment?.provider,
    appointmentId: appointment?.id,
    show: !forceHide && show,
    onClose: () => setShow(false),
    appointment,
  };
}
