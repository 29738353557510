import { useState } from "react";
import { FieldInterface, FormValues } from "../../types";
import { useTranslation } from "../../locales";
import { validateFields } from "../../lib/validation";
import { parseErrors } from "../../lib/requestHelpers";

export function useViewController({
  onSendEmail,
  defaultEmail,
}: {
  onSendEmail: (email: string) => Promise<void>;
  defaultEmail?: string;
}) {
  const [values, setValues] = useState<FormValues>({ email: defaultEmail || "" });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const onChange = (values: FormValues) => setValues(values);
  const { t } = useTranslation();

  const fields: FieldInterface[] = [
    {
      name: "email",
      type: "text",
      placeholder: t("Your Email"),
      validation: {
        required: true,
      },
      textAlign: "center",
    },
  ];

  async function sendEmail() {
    const validation = validateFields(fields, values);
    if (validation.valid) {
      try {
        return await onSendEmail(values.email);
      } catch (e) {
        setErrors(parseErrors(e));
      }
    } else setErrors(validation.errors);
  }

  return {
    fields,
    values,
    errors,
    setErrors,
    onChange,
    onSendEmail: sendEmail,
  };
}
