import React, { useState } from "react";
import { useTranslation } from "../../../../locales";
import "./style.scss";
import { getMediaDuration } from "@scrile/tools/dist/lib/TimeHelpers";
import { MediaFilesController } from "../../../../lib/MediaFileController";
import { ProfileVideoData } from "../../controller";
import BaseIcon from "../../../../components/BaseIcon";
import ModalWindow from "../../../../components/ModalWindow";
import BaseButton from "../../../../components/BaseButton";

interface Props {
  show: boolean;
  video: ProfileVideoData;
  className?: string;
  onClose: () => void;
}

function PerformerVideoModal({ show, onClose, className, video }: Props) {
  const { t } = useTranslation();

  const cls = ["performer-video-modal"];
  if (className) cls.push(className);
  const [showVideo, setShowVideo] = useState(false);

  const duration = (video.fileData && getMediaDuration(Number(video.fileData.metadata.duration))) || "";
  const videoSrc = (video.fileData && MediaFilesController.getVideoUrl(video.fileData.urlPart)) || "";

  const stopVideo = () => {
    setShowVideo(false);
    onClose();
  };

  return (
    <ModalWindow show={show} onClose={stopVideo} className={cls.join(" ")} mobileFullScreen={false} modal>
      <BaseButton className="performer-video-modal__close" borderless onClick={stopVideo} icon={true}>
        <BaseIcon>close</BaseIcon>
      </BaseButton>
      <div className="performer-video-modal__wrapper">
        {!showVideo && (
          <div className="performer-video-modal__image-wrapper" onClick={() => setShowVideo(true)}>
            <img className="performer-video-modal__image" src={video?.thumbnailUrl} alt={t("Coach video")} />
            {duration && <span className="performer-video-modal__duration small pa-1">{duration}</span>}
            <div className="performer-video-modal__icon-wrapper">
              <BaseIcon className="performer-video-modal__icon" size={24}>
                play
              </BaseIcon>
            </div>
          </div>
        )}
        {showVideo && (
          <video className={"performer-video-modal__video"} src={videoSrc} controls poster={video?.thumbnailUrl} />
        )}
      </div>
    </ModalWindow>
  );
}

export default PerformerVideoModal;
