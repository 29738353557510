import React from "react";
import { FieldInterface } from "../../types";
import CheckboxInput from "../CheckboxInput";
import InputPassword from "../InputPassword";
import BaseSelect from "../BaseSelect";
import BaseTextarea from "../BaseTextarea";
import BaseInput from "../BaseInput";

export default function FieldPrinter({
  field,
  className,
  value,
  errorMessage,
  autoFocus,
  onChange,
}: {
  field: FieldInterface;
  className: string;
  value: string;
  onChange: (value: string | boolean) => void;
  errorMessage: string;
  autoFocus?: boolean;
}) {
  if (field.type === "checkbox") {
    return (
      <CheckboxInput
        {...field}
        className={className}
        key={field.name}
        name={field.name}
        value={value}
        onChange={onChange}
        autofocus={autoFocus}
      >
        {field.label}
      </CheckboxInput>
    );
  } else if (field.type === "password") {
    return (
      <InputPassword
        {...field}
        className={className}
        key={field.name}
        value={value}
        errorMessage={errorMessage}
        label={field.label}
        onChange={onChange}
        autofocus={autoFocus}
      />
    );
  } else if (field.type === "select") {
    return (
      <BaseSelect
        {...field}
        className={className}
        errorMessage={errorMessage}
        key={field.name}
        value={value}
        onChange={onChange}
        autofocus={autoFocus}
      />
    );
  } else if (field.type === "textarea") {
    return (
      <BaseTextarea
        {...field}
        className={className}
        errorMessage={errorMessage}
        key={field.name}
        name={field.name}
        value={value || ""}
        onInput={onChange}
        autofocus={autoFocus}
      />
    );
  } else {
    return (
      <BaseInput
        {...field}
        className={className}
        key={field.name}
        value={value}
        errorMessage={errorMessage}
        label={field.label}
        onChange={onChange}
        autofocus={autoFocus}
      />
    );
  }
}
