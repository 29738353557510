import { Props } from "./index";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";
import { TagCategoryWithTags } from "../../../../hooks/useCategories";
import { t } from "../../../../locales";
import config from "../../../../config";

interface CategoryPrint extends TagCategoryWithTags {
  value: boolean;
}

interface TagPrint extends TagInfo {
  value: boolean;
}

interface ItemToPrint {
  title: string;
  type: "tag" | "category";
  items: Array<CategoryPrint | TagPrint>;
}

export default function useViewController({
  categories,
  tags,
  values,
  onInput,
}: Pick<Props, "categories" | "tags" | "values" | "onInput">) {
  const [state, setState] = useState({ activeIndex: -1, filter: "", mobileHide: false });
  const [categoriesHide, setCategoriesHide] = useState<Record<number, boolean>>({});
  const [localValues, setLocalValues] = useState(Array.from(values));

  const itemsToPrint: ItemToPrint[] = useMemo(() => {
    const valueIds = localValues.map((i) => i.id);
    const matchFilter = (name: string) =>
      state.filter ? name.toLowerCase().match(new RegExp(`${state.filter.trim().toLowerCase()}`)) : true;
    const filteredCategories = categories.filter(
      (i) => i.id !== config.technicalCategoryId && !!i.tags.length && matchFilter(i.title)
    );
    const filteredTags = tags.filter((i) => i.categoryId !== config.technicalCategoryId && matchFilter(i.title));
    const technicalsTags = tags.filter((i) => i.categoryId === config.technicalCategoryId && matchFilter(i.title));
    const response: ItemToPrint[] = [
      {
        title: t("Practice area"),
        type: "category",
        items: filteredCategories.map((category) => ({
          ...category,
          value: localValues.some((tag) => tag.categoryId === category.id),
        })),
      },
      {
        title: t("Role"),
        type: "tag",
        items: filteredTags.map((i) => ({ ...i, value: valueIds.includes(i.id) })),
      },
      {
        title: t("Technicals"),
        type: "tag",
        items: technicalsTags.map((i) => ({ ...i, value: valueIds.includes(i.id) })) ?? [],
      },
    ];
    return response.filter((i) => i.items.length);
  }, [categories, tags, localValues, state.filter]);

  const toggleMobileHide = () => setState((s) => ({ ...s, mobileHide: !s.mobileHide }));

  const onFilter = (value: string) => setState((s) => ({ ...s, filter: value }));

  const onChange = (item: TagInfo | undefined, v: boolean) => {
    if (!item) return;
    let updatedValues = Array.from(localValues);
    const index = localValues.findIndex((i) => i.id === item.id);
    if (v && index < 0) {
      updatedValues = localValues.concat(item);
    } else if (!v && index >= 0) {
      updatedValues = localValues.filter((i) => i.id !== item.id);
    }
    setLocalValues(updatedValues);
    return updatedValues;
  };

  const onChangeCategories = (item: TagCategoryWithTags | undefined, v: boolean) => {
    if (!item) return;
    let updatedValues = Array.from(localValues);
    item.tags.forEach((tag) => {
      const index = updatedValues.findIndex((i) => i.id === tag.id);
      if (v && index < 0) {
        updatedValues = updatedValues.concat(tag);
      } else if (!v && index >= 0) {
        updatedValues = updatedValues.filter((i) => i.id !== tag.id);
      }
    });
    setLocalValues(updatedValues);
    return updatedValues;
  };

  const onChangeHandler = (id: string, v: boolean, type: string, search = false) => {
    const updatedValues =
      type === "tag"
        ? onChange(
            tags.find((i) => i.id === id),
            v
          )
        : onChangeCategories(
            categories.find((i) => i.id === id),
            v
          );
    if (search && updatedValues) onInput(updatedValues);
  };

  const onToggleCategory = useCallback(
    (index: number, single = false) => {
      const newValue = !categoriesHide[index];
      single && setState((s) => ({ ...s, activeIndex: newValue ? index : -1 }));
      setCategoriesHide((s) => ({ ...(single ? {} : s), [index]: newValue }));
    },
    [categoriesHide]
  );

  const onClearFilterTags = () => {
    setLocalValues([]);
    onInput([]);
  };

  const onApplyFilters = () => {
    onInput(localValues);
    onToggleCategory(state.activeIndex, true);
  };

  const onClick = useCallback(
    (e: Event) => {
      if (!!e.target && !(e.target as HTMLElement).closest(".base-filter-container") && state.activeIndex >= 0) {
        onToggleCategory(state.activeIndex, true);
      }
    },
    [state.activeIndex, onToggleCategory]
  );

  useEffect(() => {
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [onClick]);

  return {
    ...state,
    itemsToPrint,
    toggleMobileHide,
    categoriesHide,
    onChange,
    onChangeCategories,
    onFilter,
    onToggleCategory,
    onChangeHandler,
    onClearFilterTags,
    onApplyFilters,
  };
}
