import React, { KeyboardEvent, FormEvent } from "react";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import useMobileKeyboardOpen from "../../hooks/useMobileKeyboard";
import { BaseTextareaField } from "../../types";
import { useTranslation } from "../../locales";
import useViewController from "./viewController";
import "./style.scss";

interface Props extends BaseTextareaField {
  value: string;
  onInput: (value: string) => void;
  onSubmit?: () => void;
  setTextarea?: (textArea: HTMLTextAreaElement | null) => void;
  onFocus?: (e: React.FocusEvent) => void;
}

export default function BaseTextarea({
  name,
  value,
  onInput,
  onSubmit,
  onFocus,
  label,
  placeholder,
  rows = 2,
  maxRows = 5,
  rowHeight = 23,
  autofocus,
  autoGrow = false,
  showLengthCounter = false,
  maxLength,
  submit,
  className,
  labelClassName,
  errorMessage,
  setTextarea,
}: Props) {
  const { setKeyboardOpen } = useMobileKeyboardOpen();
  const { ref, count, currentHeight, maxHeight, minHeight, paddingY, scrollbarWidth } = useViewController({
    setTextarea,
    showLengthCounter,
    autoGrow,
    rowHeight,
    maxRows,
    rows,
    value,
  });
  const isError: boolean = (errorMessage && errorMessage.trim() !== "") || false;
  const { t } = useTranslation();

  const cls = ["base-textarea"];
  if (className) cls.push(className);
  if (label) cls.push("__label");
  if (showLengthCounter) cls.push("__counter");
  if (isError) cls.push("__error");

  const htmlFor = `${name}_${new Date().getTime()}`;
  const onChangeEvent = (e: FormEvent<HTMLTextAreaElement>) => {
    onInput((e.target as HTMLTextAreaElement).value);
  };

  const onKeydown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      e.key !== "Enter" ||
      !submit ||
      (submit === "shiftEnter" && !e.shiftKey) ||
      (submit === "enter" && e.shiftKey) ||
      isMobile()
    ) {
      return;
    }
    e.preventDefault();
    onSubmit && onSubmit();
  };

  const onFocusEvent = (e: React.FocusEvent) => {
    setKeyboardOpen(true);
    onFocus && onFocus(e);
  };

  const onBlur = () => {
    setKeyboardOpen(false);
  };

  return (
    <div className={cls.join(" ")}>
      {label && (
        <label className={"base-textarea__label " + (labelClassName ? labelClassName : "")} htmlFor={htmlFor}>
          {label}
        </label>
      )}
      <div className="base-textarea__container" style={{ marginRight: `-${scrollbarWidth}px` }}>
        <textarea
          ref={ref}
          name={name}
          id={htmlFor}
          value={value}
          autoFocus={autofocus}
          placeholder={placeholder}
          onChange={onChangeEvent}
          onKeyDown={onKeydown}
          maxLength={maxLength}
          onFocus={onFocusEvent}
          onBlur={onBlur}
          style={{
            minHeight: minHeight + paddingY + "px",
            maxHeight: maxHeight + paddingY + "px",
            height: currentHeight + paddingY + "px",
          }}
        />
        {showLengthCounter && (
          <div className="base-textarea__counter">
            {count}
            {maxLength && (
              <span className="base-textarea__counter-max">
                {" " + t("of {{maxLength}}", { maxLength: maxLength || "" })}
              </span>
            )}
          </div>
        )}
      </div>
      {isError && <div className={"base-textarea__error"}>{errorMessage}</div>}
    </div>
  );
}
