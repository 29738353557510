import React from "react";
import { useTranslation } from "../../../../locales";
import photoSrc from "../../../../assets/about-us/hi-logo.png";
import photoSrc__x2 from "../../../../assets/about-us/hi-logo-2x.png";
import "./style.scss";

export default function BlockAboutUsHeader() {
  const { t } = useTranslation();

  return (
    <div className="block-about-us-header d-flex">
      <div className="block-about-us-header__content">
        <div className="block-about-us-header__hi-logo d-flex __align-center">
          <img
            className="block-about-us-header__hi-logo-img"
            src={photoSrc}
            alt="Wasim Tahir"
            srcSet={photoSrc__x2 + " 2x"}
          />
          <div className="fw-bold ml-2">{t("Harvard Innovation Labs")}</div>
        </div>
        <div className="block-about-us-header__text">
          {t(
            "SecureTheOffer is now part of Harvard Innovation Labs, recognizing our innovative interview coaching and commitment to excellence in the financial services sector."
          )}
        </div>
      </div>
    </div>
  );
}
