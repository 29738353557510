import React from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "../../locales";
import { isPerformer } from "../../lib/usersHelper";
import PerformerBookingWindow from "./components/PerformerBookingWindow";
import PerformerProfileCard from "./components/PerformerProfileCard";
import PerformerReviewsCard from "./components/PerformerReviewsCard";
import BaseButton from "../../components/BaseButton";
import BaseIcon from "../../components/BaseIcon";
import BaseSpinner from "../../components/BaseSpinner";
import BasePage from "../../components/BasePage";
import ContentHeader from "../../components/ContentHeader";
import AddToFavorite from "../../components/AddToFavorite";
import useViewController from "./viewController";
import "./style.scss";
import useCustomDomain from "../../hooks/useCustomDomain";
import PerformerVideo from "./components/PerformerVideo";
import PerformerVideoModal from "./components/PerformerVideoModal";

interface Props {
  username: string;
}

function PagePerformerProfile({ username }: Props) {
  const { isCustomDomain } = useCustomDomain();

  const {
    user,
    showBook,
    setShowBook,
    appointments,
    appointmentsLoading,
    video,
    videoLoading,
    showVideoModal,
    setShowVideoModal,
    reviewsLoading,
    mobileReviewsMode,
    setMobileReviewsMode,
    isTablet,
    onReviewAdded,
    reviewItems,
    getNextReviewsPage,
    enableReviewsScroll,
    totalReviewsRecords,
  } = useViewController(username);
  const { t } = useTranslation();

  const onCloseBook = () => {
    setShowBook(false);
  };

  if (user && !isPerformer(user)) {
    return <Redirect to={"/"} />;
  }

  if (user) {
    const prepend = (
      <>
        <div className="page-performer-profile__header-wrapper">
          <ContentHeader className="page-performer-profile__screen-name-header text-left">
            {user.screenName}
          </ContentHeader>
          {!isCustomDomain && (
            <div className="page-performer-profile__status-line">
              {!isTablet && <AddToFavorite className="page-performer-profile__favorite mt-n2" withText={false} user={user} />}
            </div>
          )}
        </div>
        {video?.fileData && !isTablet && (
          <PerformerVideo
            className="mb-8"
            user={user}
            video={video}
            loading={videoLoading}
            onClick={() => setShowVideoModal(true)}
          />
        )}
        <div className="page-performer-profile__switch-buttons">
          <BaseButton
            name="about"
            className={"page-performer-profile__switch-button body-big" + (!mobileReviewsMode ? " __active" : "")}
            onClick={() => setMobileReviewsMode(false)}
            opacity
            large
          >
            {t("About")}
          </BaseButton>
          <BaseButton
            name="reviews"
            className={"page-performer-profile__switch-button body-big" + (mobileReviewsMode ? " __active" : "")}
            onClick={() => setMobileReviewsMode(true)}
            opacity
            large
          >
            {t("Reviews")}
            <span className="page-performer-profile__total-reviews small ml-1">{totalReviewsRecords}</span>
          </BaseButton>
        </div>
      </>
    );
    const side = (
      <div>
        {video?.fileData && isTablet && (
          <PerformerVideo
            className="mb-8"
            user={user}
            video={video}
            loading={videoLoading}
            onClick={() => setShowVideoModal(true)}
          />
        )}
        <PerformerBookingWindow
          user={user}
          appointments={appointments}
          loading={appointmentsLoading}
          show={showBook}
          onClose={onCloseBook}
        />
      </div>
    );
    return (
      <BasePage className="page-performer-profile" prepend={prepend} side={side} revers={true}>
        {(!mobileReviewsMode || isTablet) && (
          <PerformerProfileCard className="page-performer-profile__profile-card" user={user} />
        )}
        {(mobileReviewsMode || isTablet) && (
          <PerformerReviewsCard
            user={user}
            onReviewAdded={onReviewAdded}
            loading={reviewsLoading}
            items={reviewItems}
            enableScroll={enableReviewsScroll}
            getNextPage={getNextReviewsPage}
            totalRecords={totalReviewsRecords}
          />
        )}
        <BaseButton className="page-performer-profile__book-show-button" onClick={() => setShowBook(true)}>
          <BaseIcon size={32}>book</BaseIcon>
        </BaseButton>
        {video && <PerformerVideoModal show={showVideoModal} video={video} onClose={() => setShowVideoModal(false)} />}
      </BasePage>
    );
  } else {
    return (
      <div className="page-performer-profile__spinner-container">
        <BaseSpinner size={45} />
      </div>
    );
  }
}

export default PagePerformerProfile;
