import React, { useEffect } from "react";
import { useTranslation } from "../../../../locales";
import ChangeEmail from "../../../ChangeEmail";
import BaseSpinner from "../../../BaseSpinner";
import { useController } from "./controller";

interface Props {
  email?: string;
  onCancel: () => void;
  onResetStart: (data: { email: string; status: boolean }) => void;
}

export default function EnterEmail({ email, onCancel, onResetStart }: Props) {
  const { onSendEmail } = useController(onResetStart);
  const { t } = useTranslation();

  useEffect(() => {
    if (email) {
      onSendEmail(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  if (email)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BaseSpinner />
      </div>
    );
  return <ChangeEmail title={t("Forgot Password")} onCancel={onCancel} sendEmail={onSendEmail} />;
}
