import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGetSet } from "react-use";
import useIntersectionObserver from "@react-hook/intersection-observer";
import { isSafari } from "@scrile/tools/dist/lib/browserUtils";
import { InfiniteScrollItem } from "./index";

export function useViewController<T>({
  startPosition,
  list,
  pageSize,
  enable,
  onPrev,
  onNext,
  scrollableWrapperRef,
}: {
  list?: InfiniteScrollItem<T>[];
  startPosition: number;
  pageSize: number;
  onPrev?: (cb: Function) => void;
  onNext?: (cb: Function) => void;
  enable: boolean;
  scrollableWrapperRef?: React.RefObject<HTMLElement>;
}) {
  const observerPrev = useRef(null);
  const observerNext = useRef(null);

  const [currentPosition, setCurrentPosition] = useState(
    Math.max(Math.min(startPosition, list?.length ?? 0 - pageSize), 0)
  );
  const [endPosition, setEndPosition] = useState(Math.min(currentPosition + pageSize, list?.length ?? 0));
  const [loadingPrev, setLoadingPrev] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [getScrolling, setScrolling] = useGetSet(false);
  const { isIntersecting: isPrevIntersecting } = useIntersectionObserver(observerPrev.current, {
    rootMargin: "0px 0px 20% 0px",
    threshold: 1,
  });
  const { isIntersecting: isNextIntersecting } = useIntersectionObserver(observerNext.current, {
    rootMargin: "0px 0px 20% 0px",
    threshold: 1,
  });

  const itemsToPrint = useMemo(() => list?.slice(currentPosition, endPosition) ?? [], [
    list,
    currentPosition,
    endPosition,
  ]);

  const allPrinted = useMemo(() => {
    if (!list) return true;
    return list.length === itemsToPrint.length;
  }, [list, itemsToPrint]);

  const showPrevButton = useMemo(() => {
    return (enable && !!onPrev) || currentPosition > 0 || loadingPrev;
  }, [enable, onPrev, currentPosition, loadingPrev]);

  const showNextButton = useMemo(() => {
    return (enable && !!onNext) || endPosition < (list?.length ?? 0) || loadingNext;
  }, [enable, onNext, endPosition, list, loadingNext]);

  useEffect(() => {
    if (allPrinted && !enable) return;
    if (isPrevIntersecting) loadPrev();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrevIntersecting]);
  useEffect(() => {
    if (allPrinted && !enable) return;
    if (isNextIntersecting) loadNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNextIntersecting]);

  const loadPrev = async () => {
    setLoadingPrev(true);
    if (allPrinted) {
      onPrev && onPrev(() => setLoadingPrev(false));
      return;
    }

    const initial = document.documentElement.scrollHeight;
    await new Promise((r) => setTimeout(r, 50));
    setCurrentPosition(Math.max(currentPosition - pageSize, 0));
    setLoadingPrev(false);
    if (Math.abs(document.documentElement.scrollHeight - initial) > 0) {
      window.scrollBy({
        top: document.documentElement.scrollHeight - initial,
        behavior: "auto",
      });
    }
  };

  const loadNext = async (): Promise<void> => {
    setLoadingNext(true);
    if (getScrolling()) {
      await new Promise((r) => setTimeout(r, 100));
      return loadNext();
    }
    if (endPosition === (list?.length ?? 0)) {
      onNext &&
        onNext(() => {
          setEndPosition(Math.min(endPosition + pageSize, list?.length ?? 0));
          setLoadingNext(false);
        });
      return;
    }
    await new Promise((r) => setTimeout(r, 50));
    setEndPosition(Math.min(endPosition + pageSize, list?.length ?? 0));
    setLoadingNext(false);
  };

  const scrollTimeout = useRef<any>();
  useEffect(() => {
    const onScroll = () => {
      scrollTimeout.current && clearTimeout(scrollTimeout.current);
      setScrolling(true);
      scrollTimeout.current = setTimeout(() => setScrolling(false), 100);
    };
    const element = scrollableWrapperRef?.current ?? window;
    if (isSafari()) {
      element.addEventListener("scroll", onScroll);
    }
    return () => element.removeEventListener("scroll", onScroll);
  }, [scrollableWrapperRef, setScrolling]);

  return {
    showPrevButton,
    showNextButton,
    loadingPrev,
    loadingNext,
    loadPrev,
    loadNext,
    observerPrev,
    observerNext,
    itemsToPrint,
  };
}
