type GtagType = (
  command: string,
  eventName: string,
  eventParams?: { event_category: string; event_action: string }
) => void;

export const gtagTrackEvent = (
  eventName: string,
  eventParams?: { event_category: string; event_action: string }
): void => {
  if (typeof (window as any).gtag === "function") {
    ((window as any).gtag as GtagType)("event", eventName, eventParams);
  }
};
