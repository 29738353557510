import providers from "../../../../lib/providers";
import { InputPasswordResetStart, InputPasswordResetVerify } from "@scrile/api-provider/dist/api/PasswordResetProvider";
import { ErrorDTO } from "@scrile/api-provider/dist/lib/apiErrorHandler";

export function useController(onCodeVerified: (data: { email: string; token: string }) => void) {
  const onResendCode = async (email: string) => {
    const data: InputPasswordResetStart = {
      email,
    };
    await providers.PasswordResetProvider.start({ data });
  };

  const onSendCode = async ({ email, code }: { email: string; code: string }) => {
    const data: InputPasswordResetVerify = {
      email,
      code,
    };
    providers.PasswordResetProvider.setReceiveErrors(false);
    const { token } = await providers.PasswordResetProvider.verify({ data });
    if (!token) {
      const error = new Error("Invalid verification code");
      const e: ErrorDTO = {
        error,
        message: error.message,
        status: "error",
        code: "BAD_REQUEST",
      };
      // eslint-disable-next-line
      throw [e];
    }
    onCodeVerified({ email, token });
  };

  return {
    onResendCode,
    onSendCode,
  };
}
