import React from "react";
import { useTranslation } from "../../locales";
import InfiniteScroll from "../../components/InfiniteScroll";
import BasePage from "../../components/BasePage";
import PerformerList from "./components/PerformerList";
import useController from "./controller";
import BaseFilters from "./components/BaseFilters";
import "./style.scss";

interface Props {
  onlyFollowings?: boolean;
}

function PagePerformers({onlyFollowings = false}: Props) {
  const { users, loading, scroll, loadMore, filtersDTO, setFilters, totalRecords, userFavoriteIds } = useController({onlyFollowings});
  const { t } = useTranslation();

  return (
    <BasePage loading={loading} className="page-performers">
      <BaseFilters filtersDTO={filtersDTO} setFilters={setFilters} totalRecords={totalRecords} />
      {users.length > 0 && (
        <InfiniteScroll enable={scroll} onNext={loadMore}>
          <PerformerList users={users} userFavoriteIds={userFavoriteIds} />
        </InfiniteScroll>
      )}
      {!loading && users.length === 0 && (
        <div className="d-center headline-2" style={{ height: "100%" }}>
          {t("No matching result found")}
        </div>
      )}
    </BasePage>
  );
}

export default PagePerformers;
