import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Subscription } from "apollo-client/util/Observable";
import { Message } from "@scrile/api-provider/dist/api/MessagesProvider";
import providers from "../../../../../../lib/providers";
import useAuthUser from "../../../../../../hooks/useAuthUser";

function useController(
  token: string,
  containerRef: MutableRefObject<HTMLDivElement | null>,
  shouldScrollToBottom: MutableRefObject<boolean>
) {
  const subscription = useRef<Subscription | null>(null);
  const { user } = useAuthUser();
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    if (token.length > 0) {
      (async () => {
        subscription.current = await providers.LivechatsProvider.subscribeMessageReceived(token, (data: Message) => {
          if (!containerRef.current) return;
          const bottomScrollTopPosition = containerRef.current.scrollHeight - containerRef.current.offsetHeight;
          if (data.senderId === user?.id || bottomScrollTopPosition - containerRef.current.scrollTop <= 100) {
            shouldScrollToBottom.current = true;
          }
          setMessages((prevState: Message[]) => [data, ...prevState]);
        });
      })();
    }
    return () => {
      subscription.current && subscription.current.unsubscribe();
    };
  }, [token, user, shouldScrollToBottom, containerRef]);

  return {
    messages,
  };
}

export default useController;
