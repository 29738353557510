import React, { useEffect } from "react";
import { InputPerformerListSearch } from "@scrile/api-provider/dist/projects/webvideo/PerformerListProvider";
import { lockBodyScroll } from "@scrile/tools/dist/lib/Scrollhelpers";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import { useTranslation } from "../../../../locales";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import BaseCounter from "../../../../components/BaseCounter";
import FilterByName from "../FilterByName";
import useViewController from "./viewController";
import FilterByTags from "../FilterByTags";
import "./style.scss";

export interface Props {
  filtersDTO: InputPerformerListSearch;
  setFilters: (filtersDTO: InputPerformerListSearch) => void;
  totalRecords: number;
}

function BaseFilters(props: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { desktopUp } = useBreakPoints();
  const { counter, onFilterTags, onNameChange } = useViewController(props);
  const { t } = useTranslation();

  useEffect(() => {
    lockBodyScroll(!desktopUp && isComponentVisible);
  }, [isComponentVisible, desktopUp]);

  return (
    <div className="filters">
      <FilterByName className="filters__by-name" onChange={onNameChange} />
      <div className="filters__wrapper-mobile" ref={ref}>
        {!desktopUp && (
          <BaseButton
            borderless={true}
            className="filters__activator-button"
            onClick={() => setIsComponentVisible(!isComponentVisible)}
          >
            <BaseIcon className="filters__activator-icon">filter</BaseIcon>
            <BaseCounter className="filters__activator-counter">{counter}</BaseCounter>
          </BaseButton>
        )}

        <div className={"filters-mobile " + (isComponentVisible ? "__active" : "")}>
          {!desktopUp && (
            <div className="filters-mobile__header">
              <div className="filters-mobile__header-wrapper-icon">
                <BaseIcon>filter</BaseIcon>
                <BaseCounter className="filters-mobile__header-counter">{counter}</BaseCounter>
              </div>
              <div className="filters-mobile__header-result">
                <h2 className="filters-mobile__heading mr-4">{t("Filters")}</h2>
                <span className="filters-mobile__result-line">
                  {t("{{totalRecords}} results", { totalRecords: props.totalRecords })}
                </span>
              </div>

              <BaseButton
                className="filters-mobile__close"
                borderless={true}
                onClick={() => setIsComponentVisible(!isComponentVisible)}
              >
                <BaseIcon>close</BaseIcon>
              </BaseButton>
            </div>
          )}
          <div className="filters-mobile__main">
            <FilterByTags onFilterTags={onFilterTags} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaseFilters;
