import React from "react";
import { PropsMessageItem } from "../types";
import MessageAttachView from "../../../../MessageAttachView";
import DefaultMessageView from "../DefaultMessageView";
import useViewController from "./viewController";
import "./style.scss";

export default function MessageDefault({
  message,
  className,
  rendered,
  avatarSize,
  showScreenName,
  startMessage,
  endMessage,
  isLiveChat,
  authUser,
}: PropsMessageItem) {
  const { highlightMessage, selfMessage, desktopUp, attachedFileData, link } = useViewController(
    message,
    authUser,
    isLiveChat
  );

  const cls = ["default-message"];
  if (className) cls.push(className);
  const clsContent = ["default-message__content"];
  if (highlightMessage) clsContent.push("__highlight-message");
  if (isLiveChat && !desktopUp) clsContent.push("__mobile-live-message");

  return (
    <DefaultMessageView
      message={message}
      className={cls.join(" ")}
      rendered={rendered}
      avatarSize={avatarSize}
      showScreenName={showScreenName}
      startMessage={startMessage}
      endMessage={endMessage}
      isLiveChat={isLiveChat}
      selfMessage={selfMessage}
      highlightMessage={highlightMessage}
      contentMessage={
        <div className={clsContent.join(" ")}>
          <pre className="default-message__text">{message.text}</pre>
          {attachedFileData && (
            <MessageAttachView
              className="default-message__attach"
              attachedFileData={attachedFileData}
              link={link}
              showPreview={desktopUp || !isLiveChat}
            />
          )}
        </div>
      }
    />
  );
}
