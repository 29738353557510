import React, { useMemo } from "react";
import ReactTags, { Tag } from "react-tag-autocomplete";
import { TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";
import { useTranslation } from "../../locales";
import BaseTooltip from "../BaseTooltip";
import "./style.scss";

interface Props {
  name: string;
  className?: string;
  label?: string;
  placeholder?: string;
  value: TagInfo[];
  suggestions: TagInfo[];
  onDelete: (index: number) => void;
  onAddition: (tag: TagInfo) => void;
  disabled?: boolean;
  tooltipText?: string;
}

function escapeForRegExp(s: string) {
  return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
}

function matchPartial(s: string) {
  return new RegExp(`(?:^|\\s)${escapeForRegExp(s)}`, "i");
}

function TagInput({
  name,
  label,
  className,
  placeholder = "",
  value,
  suggestions,
  onDelete,
  onAddition,
  disabled,
  tooltipText,
}: Props) {
  const { t } = useTranslation();
  const htmlFor = `${name}_${new Date().getTime()}`;

  const cls = ["tag-input"];
  if (className) cls.push(className);
  if (disabled) cls.push("__disabled");

  const suggestionsTransform = (query: string, suggs: Tag[]): Tag[] =>
    suggs.filter((s: Tag) => matchPartial(query).test(s.name) && value.filter((v) => v.id === s.id).length === 0);

  const localSuggestions: Tag[] = useMemo(() => suggestions.map((s) => ({ id: s.id, name: s.title })), [suggestions]);

  const localValue = useMemo(() => value.map((v) => ({ id: v.id, name: v.title })), [value]);

  const onLocalAddition = (tag: Tag) => {
    const id = tag.id;
    const t = suggestions.find((item) => item.id === id);
    if (t) {
      onAddition(t);
    }
  };

  return (
    <div className={cls.join(" ")}>
      {(label || tooltipText) && (
        <div className="tag-input__top-wrapper">
          {label && (
            <label className="tag-input__label label" htmlFor={htmlFor}>
              {label}
            </label>
          )}
          {tooltipText && <BaseTooltip>{tooltipText}</BaseTooltip>}
        </div>
      )}
      <div className="tag-input__input-wrapper">
        <ReactTags
          id={htmlFor}
          placeholderText={placeholder}
          tags={localValue}
          suggestions={localSuggestions}
          minQueryLength={1}
          suggestionsTransform={suggestionsTransform}
          onDelete={onDelete}
          onAddition={onLocalAddition}
          noSuggestionsText={t("No matching result found")}
        />
      </div>
    </div>
  );
}

export default TagInput;
