import { useEffect, useState } from "react";
import { InputPerformerListSearch } from "@scrile/api-provider/dist/projects/webvideo/PerformerListProvider";
import { UserPublicDataSearchResponse } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useAuthUser from "../../hooks/useAuthUser";
import providers from "../../lib/providers";

interface Props {
  onlyFollowings?: boolean;
}

function useController({ onlyFollowings }: Props) {
  const [state, setState] = useState<UserPublicDataSearchResponse<UserPublicData> | null>(null);
  const [filtersDTO, setFiltersDTO] = useState<InputPerformerListSearch>({ sort: "TAG_POSITION;ID,DESC", page: 0 });
  const [userFavoriteIds, setUserFavoriteIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user: isAuth } = useAuthUser();

  const setFilters = async (data: InputPerformerListSearch, reload = true, cb?: Function) => {
    return await getData(data, reload, cb);
  };

  const loadMore = async (cb?: Function) => {
    if (state && !state.hasNextPage) return;
    return getData({ ...filtersDTO, page: (state?.page ?? -1) + 1 }, false, cb);
  };

  const getData = async (data: InputPerformerListSearch, reload = true, cb?: Function) => {
    try {
      const response = await providers.PerformerListProvider.performerListSearch(
        { data: { ...data, onlyFollowings } },
        [
          "id",
          "username",
          "screenName",
          "disabled",
          "calendlyScheduleUrl",
          { onlineStatus: ["isOnline"] },
          { publicLivechatUser: ["broadcasting"] },
          { avatar: ["urlPart"] },
          { questionary: ["fields", { tags: ["id", "slug", "title", "categoryId"] }] },
        ]
      );

      setState((prevState) => {
        return reload
          ? response
          : {
              ...response,
              result: prevState?.result.concat(response.result) || response.result,
            };
      });
      setFiltersDTO(data);

      if (isAuth) {
        if (onlyFollowings) {
          setUserFavoriteIds(() => response.result.map((i) => i.id));
          return;
        }

        const ids = response.result.map((i) => i.id);
        if (ids.length > 0) {
          const favoritesId = await providers.FollowingsProvider.searchFollowings({ ids });
          setUserFavoriteIds(() => favoritesId.map((i) => i.userId));
        }
      }
    } finally {
      cb && cb();
    }
  };

  useEffect(() => {
    setLoading(true);
    getData(filtersDTO).finally(() => setLoading(false));
    return () => {
      setState(null);
      setFiltersDTO({ sort: ["ID,DESC"], page: 0 });
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return {
    userFavoriteIds,
    loading,
    filtersDTO,
    users: state?.result || [],
    scroll: !loading && (state?.hasNextPage ?? false),
    setFilters,
    loadMore,
    totalRecords: state?.totalRecords ?? 0,
  };
}

export default useController;
