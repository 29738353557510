import { useLocation, Redirect } from "react-router-dom";
import React, { useMemo } from "react";
import useCustomDomain from "../../hooks/useCustomDomain";

export default function LinkedModalPageView({ path }: { path: string }) {
  const location = useLocation();
  const { isCustomDomain } = useCustomDomain();
  const to = useMemo(
    () => ({
      ...location,
      pathname: path,
      state: {
        location: {
          pathname: isCustomDomain ? "/" : "/coaches",
        },
      },
    }),
    [isCustomDomain, location, path]
  );
  return <Redirect to={to} />;
}
