import React from "react";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import BaseAvatar from "../../../BaseAvatar";
import "./style.scss";

interface Props {
  className?: string;
  user?: User | UserPublicData | null;
  size: number;
}

function RippleAvatarAnimation({ className, user, size }: Props) {
  const cls = ["ripple-avatar-animation"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      <div className="ripple-avatar-animation__wrapper">
        <BaseAvatar className="ripple-avatar-animation__avatar" size={size} user={user} />
      </div>
    </div>
  );
}

export default RippleAvatarAnimation;
