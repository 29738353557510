import { useState } from "react";
import { FieldInterface, FormValues } from "../../../../types";
import { useTranslation } from "../../../../locales";
import { validateFields } from "../../../../lib/validation";
import { parseErrors } from "../../../../lib/requestHelpers";
import { useController } from "./controller";

export function useViewController(onSuccess?: (status: boolean) => void) {
  const { onSetPassword } = useController();
  const [isComplete, setIsComplete] = useState(false);
  const [values, setValues] = useState<FormValues>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const onChange = (values: FormValues) => setValues(values);
  const { t } = useTranslation();

  const fields: FieldInterface[] = [
    {
      name: "password",
      type: "password",
      placeholder: t("New Password"),
      validation: {
        required: true,
      },
      textAlign: "center",
    },
  ];

  const setPassword = async () => {
    const validation = validateFields(fields, values);
    if (validation.valid) {
      try {
        const result = await onSetPassword({
          password: values.password,
          token: values.token,
        });
        setIsComplete(result);
        result && onSuccess && onSuccess(result);
        return result;
      } catch (e) {
        setErrors(parseErrors(e));
      }
    } else setErrors(validation.errors);
  };

  return {
    isComplete,
    fields,
    values,
    errors,
    setErrors,
    onChange,
    setPassword,
  };
}
