import { useMemo } from "react";
import { MessageThread } from "@scrile/api-provider/dist/api/MessageThreadsProvider";
import { User } from "@scrile/api-provider/dist/api/UserProvider";

function useController(messageThread: MessageThread, authUser: User) {
  const senderUser = useMemo(() => messageThread?.users.find((i) => i.id !== authUser.id), [
    messageThread,
    authUser.id,
  ]);

  return {
    senderUser,
  };
}

export default useController;
