import React, { useEffect, useState } from "react";
import { InputCCBillPurchaseDeposit } from "@scrile/api-provider/dist/api/CCBillPurchaseProvider";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import useAppState from "../../../../hooks/useAppState";
import useBaseForm from "../../../../hooks/useBaseForm";
import { useTranslation } from "../../../../locales";
import { validateFieldsWithException } from "../../../../lib/validation";
import { countryList } from "../../../../lib/countryList";
import { parseErrors } from "../../../../lib/requestHelpers";
import { FormValue, OptionItem } from "../../../../types";
import { submitPaymentCB } from "../../types";
import useController from "./controller";

function useViewController(
  setOnSubmitPaymentCB: (cb: submitPaymentCB) => void,
  pricePackageId: string,
  currentSystem: PaymentSystemPublic | null
) {
  const { t } = useTranslation();
  const { systemSettings } = useAppState();
  const { sendSubmit, creditCardsAssignment, loading } = useController(currentSystem);
  const [selectCardsOption, setSelectCardsOption] = useState<OptionItem[]>([]);
  const [selectValue, setSelectValue] = useState<FormValue>(null);

  const { fields, values, errors, setValues, setErrors } = useBaseForm([
    {
      name: "cardNumber",
      type: "number",
      label: t("Credit card number"),
      validation: {
        required: true,
      },
    },
    {
      name: "expiry",
      type: "text",
      label: t("Expiry"),
      className: "card-edit__short-input mr-4",
      validation: {
        required: true,
      },
      placeholder: "MM/YY",
      maskOptions: {
        regex: "\\d{2}\\/\\d{2}",
        placeholder: "MM/YY",
      },
    },
    {
      name: "cvc",
      type: "password",
      label: t("CVV Code"),
      className: "card-edit__short-input",
      iconRightClassName: "d-none",
      validation: {
        required: true,
      },
    },
    {
      name: "nameOfCardHolder",
      type: "text",
      className: "mb-4",
      label: t("Name of card holder"),
      validation: {
        required: true,
      },
    },
    {
      name: "address",
      type: "text",
      label: React.createElement(React.Fragment, {}, [
        React.createElement(
          "h3",
          { className: "mb-4 card-edit__header-address", style: { lineHeight: "15px" }, key: "h3_address" },
          t("Billing address")
        ),
        React.createElement(
          "span",
          { className: "mb-4 card-edit__label-address", key: "span_address" },
          t("Street address")
        ),
      ]),
      className: "card-edit__short-input mr-4",
      validation: {
        required: true,
      },
    },
    {
      name: "city",
      type: "text",
      label: t("City"),
      className: "card-edit__short-input mt-7",
      validation: {
        required: true,
      },
    },
    {
      name: "country",
      type: "select" as const,
      label: t("County"),
      placeholder: t("Choose"),
      className: "card-edit__short-input mr-4",
      options: countryList.map((i) => ({ value: i.code, text: t(i.name) })),
      validation: {
        required: true,
      },
    },
    {
      name: "state",
      type: "text",
      label: t("State"),
      className: "card-edit__short-input",
      validation: {
        required: true,
      },
    },
    {
      name: "zip",
      type: "text",
      label: t("Zip code"),
      className: "card-edit__short-input",
      validation: {
        required: true,
      },
    },
    {
      name: "saveCard",
      type: "checkbox",
      className: "card-edit__checkbox mt-1 mb-4 fw-semibold",
      label: t("Save card"),
    },
  ]);

  useEffect(() => {
    const optionItems: OptionItem[] = creditCardsAssignment.map((card) => ({
      text: "***" + card.cardNumberEnding,
      value: {
        id: card.id,
        system: card.publicPaymentSystem,
      },
    }));
    optionItems.push({ text: t("Add card"), value: { id: "0", system: currentSystem } });

    setSelectCardsOption(optionItems);
    setSelectValue(optionItems[0].value);
  }, [creditCardsAssignment, t, currentSystem]);

  const getName = (nameOnCard: string) => {
    const [firstName, ...lastName] = nameOnCard.split(" ");
    return { firstName, lastName: lastName.join(" ") };
  };

  const getExpirationDate = (expirationDate: string) => {
    const [expMonth, expYear] = expirationDate.split("/");
    return { expMonth: Number(expMonth), expYear: new Date(`01/01/${expYear}`).getFullYear() };
  };

  const onSubmit = async () => {
    const { firstName, lastName } = getName(values.nameOfCardHolder || "");
    const { expMonth, expYear } = getExpirationDate(values.expiry || "");
    const data: InputCCBillPurchaseDeposit = {
      pricePackageId: pricePackageId,
      paymentSystemId: selectValue?.publicPaymentSystem?.id || currentSystem?.id || "",
      currency: systemSettings?.mainCurrency || "",
      billingDetails: {
        cardNumber: values.cardNumber,
        expMonth: expMonth,
        expYear: expYear,
        cvc: values.cvc,
        firstName: firstName,
        lastName: lastName,
        address: values.address,
        zip: values.zip,
        country: values.country,
        state: values.state,
        city: values.city,
        saveCard: !!values.saveCard,
      },
    };

    try {
      validateFieldsWithException(fields, values);
      return await sendSubmit(data);
    } catch (e) {
      const errors = parseErrors(e);
      if (errors.expMonth || errors.expYear) {
        const tempErrors = [errors?.expMonth ?? null, errors?.expYear ?? null].filter((e) => e !== null);
        errors.expiry = tempErrors.join(". ") + (tempErrors.length > 0 ? "." : "");
        errors.expMonth && delete errors.expMonth;
        errors.expYear && delete errors.expYear;
      }
      if (errors.firstName || errors.lastName) {
        const tempErrors = [
          (errors.firstName && t("First name {{error}}", { error: errors.firstName })) || null,
          (errors.lastName && t("Last name {{error}}", { error: errors.lastName })) || null,
        ].filter((e) => e !== null);
        errors.nameOfCardHolder = tempErrors.join(". ") + (tempErrors.length > 0 ? "." : "");
        errors.firstName && delete errors.firstName;
        errors.lastName && delete errors.lastName;
      }

      setErrors(errors);
    }
  };

  const onSubmitBySavedCard = async () => {
    const data: InputCCBillPurchaseDeposit = {
      pricePackageId: pricePackageId,
      paymentSystemId: selectValue?.publicPaymentSystem?.id || currentSystem?.id || "",
      currency: systemSettings?.mainCurrency || "",
    };
    try {
      return await sendSubmit(data);
    } catch (e) {
      setErrors(parseErrors(e));
    }
  };

  if (selectValue?.id === "0") {
    setOnSubmitPaymentCB(onSubmit);
  } else {
    setOnSubmitPaymentCB(onSubmitBySavedCard);
  }

  return {
    fields,
    values,
    setValues,
    errors,
    setErrors,
    selectValue,
    setSelectValue,
    selectCardsOption,
    haveCardsSaved: selectCardsOption.length > 1,
    isNewCard: !loading && selectValue?.id === "0",
  };
}

export default useViewController;
