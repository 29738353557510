import React, { useMemo } from "react";
import useBaseForm from "../../../../hooks/useBaseForm";
import { I18n, t } from "../../../../locales";

export default function useViewController() {
  const { values, setValues, fields, errors, setErrors } = useBaseForm([
    {
      name: "name",
      type: "text",
      label: t("Name"),
      validation: {
        required: true,
      },
      autocomplete: "off",
    },
    {
      name: "email",
      type: "email",
      iconLeft: "mail",
      placeholder: t("Email"),
      validation: {
        required: true,
      },
      autocomplete: "off",
    },
    {
      name: "message",
      type: "textarea",
      label: t("Message"),
      placeholder: t("Type your query"),
      autoGrow: true,
      maxRows: 4,
    },
    {
      name: "agreement",
      type: "checkbox",
      label: (
        <span className="caption">
          <I18n
            path="I agree to the <terms>Privacy Policy</terms>"
            components={{
              terms: (
                <a
                  className="link"
                  href="/static/privacy-policy.html"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  terms
                </a>
              ),
            }}
          />
        </span>
      ),
      style: { justifyContent: "flex-start" },
      validation: {
        required: true,
      },
    },
  ]);

  const isValid = useMemo(() => {
    const nameLength = values?.name?.length ?? 0;
    const emailLength = values?.email?.length ?? 0;
    const messageLength = values?.message?.length ?? 0;
    return nameLength !== 0 && emailLength !== 0 && messageLength !== 0 && values?.agreement;
  }, [values]);

  return {
    values,
    setValues,
    fields,
    errors,
    setErrors,
    isValid,
  };
}
