import useWebRTC from "../../hooks/useWebRTC";
import useBlurController from "../../hooks/useBlurController";
import { useCallback, useEffect, useState } from "react";
import { MediaConstraints } from "../../types";
import { isEqual, uniqBy } from "lodash";
import { useUnmount } from "react-use";
import useMediaConstraints from "../../hooks/useMediaConstraints";
import { addTracksToStream, getVideoTracks, removeTracksFromStream } from "../../lib/mediaStreamHelpers";

export function useController() {
  const WebRTC = useWebRTC();
  const { blurStart, blurStop } = useBlurController();
  const { mediaConstraints, setMediaConstraints } = useMediaConstraints({
    video: true,
    audio: true,
    videoEnabled: true,
    audioEnabled: false,
    shareEnabled: false,
    blurEnabled: false,
  });
  const [stream, setStream] = useState<MediaStream>(new MediaStream([]));
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([]);
  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);

  const onAudioChange = async (constraints: MediaConstraints) => {
    if (constraints.audioEnabled) {
      if (!isEqual(mediaConstraints.audio, constraints.audio) || !stream.getAudioTracks().length) {
        removeTracksFromStream(stream, stream.getAudioTracks());
        let newStream = await navigator.mediaDevices.getUserMedia({
          audio: constraints.audio,
        });
        addTracksToStream(stream, newStream.getAudioTracks());
        removeTracksFromStream(newStream, newStream.getTracks());
      } else {
        stream.getAudioTracks().forEach((t) => (t.enabled = true));
      }
    } else {
      stream.getAudioTracks().forEach((t) => (t.enabled = false));
    }

    return stream;
  };

  const onVideoChange = async (constraints: MediaConstraints) => {
    if (!isEqual(mediaConstraints.video, constraints.video) || !constraints.videoEnabled) {
      removeTracksFromStream(stream, getVideoTracks(stream));
    }
    if (constraints.videoEnabled) {
      let newStream = await navigator.mediaDevices.getUserMedia({
        video: constraints.video,
      });
      addTracksToStream(stream, getVideoTracks(newStream));
      removeTracksFromStream(newStream, newStream.getTracks());
    }

    return new MediaStream(stream.getTracks());
  };

  const onBlurChange = useCallback(
    async (constraints: MediaConstraints, outStream: MediaStream = stream) => {
      let data: MediaStream;

      if (constraints.blurEnabled) {
        const videoTracks = getVideoTracks(outStream);
        if (!videoTracks.length) {
          constraints.blurEnabled = false;
          await blurStop();
          return outStream;
        }
        data = await blurStart(videoTracks[0]);
      } else {
        data = await blurStop();
      }
      addTracksToStream(data, outStream.getAudioTracks());
      return data;
    },
    [blurStart, blurStop, stream]
  );

  const onChangeConstraints = async (constraints: MediaConstraints) => {
    try {
      setProcessing(true);
      let data: MediaStream | null = null;
      const localConstraints: MediaConstraints = {
        ...mediaConstraints,
        ...constraints,
      };
      if (!isEqual(mediaConstraints.blurEnabled, localConstraints.blurEnabled)) {
        data = await onBlurChange(localConstraints);
      } else if (
        !isEqual(mediaConstraints.audio, localConstraints.audio) ||
        !isEqual(mediaConstraints.audioEnabled, localConstraints.audioEnabled)
      ) {
        data = await onAudioChange(localConstraints);
      } else if (
        !isEqual(mediaConstraints.video, localConstraints.video) ||
        !isEqual(mediaConstraints.videoEnabled, localConstraints.videoEnabled)
      ) {
        if (localConstraints.blurEnabled) {
          await blurStop();
        }
        data = await onVideoChange(localConstraints);
        if (localConstraints.blurEnabled && localConstraints.videoEnabled) {
          data = await onBlurChange(localConstraints, data);
        }
      }

      if (data) {
        setMediaConstraints(localConstraints);
        setStream(data);
      }
    } catch (e) {
      console.error(e);
      onChangeConstraints({
        ...mediaConstraints,
        ...constraints,
      }).then();
    } finally {
      setProcessing(false);
    }
  };

  const setDevicesList = useCallback(async () => {
    const { audioInputList, videoInputList } = await WebRTC.getDeviceList();
    setAudioInputs(uniqBy(audioInputList.reverse(), "groupId").reverse());
    setVideoInputs(videoInputList);
  }, [WebRTC]);

  const closeStream = () => {
    WebRTC.closeAllStreams();
    stream.getTracks().forEach((t) => t.stop());
  };

  const onProduceStream = useCallback(async () => {
    try {
      setLoading(true);
      await setDevicesList();
      if (mediaConstraints.videoEnabled || mediaConstraints.audioEnabled) {
        let newStream = await navigator.mediaDevices.getUserMedia({
          video: mediaConstraints.videoEnabled ? mediaConstraints.video : false,
          audio: mediaConstraints.audioEnabled ? mediaConstraints.audio : false,
        });

        if (mediaConstraints.blurEnabled) {
          newStream = await onBlurChange({ ...mediaConstraints }, newStream);
        }
        setStream(newStream);
      }
    } finally {
      setLoading(false);
    }
  }, [setDevicesList, mediaConstraints, onBlurChange]);

  useEffect(() => {
    onProduceStream().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUnmount(closeStream);

  return {
    stream,
    mediaConstraints,
    audioInputs,
    videoInputs,
    onChangeConstraints,
    closeStream,
    processing,
    loading,
  };
}
