import React, { useMemo } from "react";
import { I18n, useTranslation } from "../../locales";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import BaseButton from "../../components/BaseButton";
import ContentHeader from "../../components/ContentHeader";
import BaseForm from "../../components/BaseForm";
import BaseIcon from "../../components/BaseIcon";
import SocialLogin from "../../components/SocialLogin";
import { useViewController } from "./viewController";
import "./style.scss";

interface Props {
  onClose: () => void;
  role?: string;
}

export default function ClientSignUp({ onClose, role }: Props) {
  const { fields, values, errors, signUpAsCoach, onJoin, onChange, setErrors } = useViewController(role);
  const { t } = useTranslation();
  const { getLocation, notification } = useModalLinkPrinter();

  const headline = useMemo(
    () => (
      <>
        <ContentHeader>{signUpAsCoach ? t("Sign Up as Coach") : t("Sign Up")}</ContentHeader>
        {signUpAsCoach && (
          <p className="client-signup__header-hint text-center">
            {t(
              'Fill in your email address, choose a password and click "Sign Up". You will receive a code in your inbox with which to verify your email address. Once verified you will be be able to submit your CV for consideration.'
            )}
          </p>
        )}
      </>
    ),
    [signUpAsCoach, t]
  );

  const fieldsToPrint = fields.map((f) => {
    if (f.type === "checkbox" && f.name === "terms") {
      f.label = (
        <span className="client-signup__checkbox-label">
          <I18n
            path="I agree to the <terms>Terms & Conditions</terms>"
            components={{
              terms: (
                <a
                  className="link"
                  href="/static/terms-of-use.html"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  terms
                </a>
              ),
            }}
          />
        </span>
      );
    }
    return f;
  });

  return (
    <>
      <BaseForm
        className="client-signup"
        fields={fieldsToPrint}
        values={values}
        notification={notification}
        onChange={onChange}
        errors={errors}
        setErrors={setErrors}
        headline={headline}
        onSubmitEnter={onJoin}
      >
        <div className="client-signup__control-wrapper __buttons">
          <BaseButton name="submit" large={true} fluid={true} onClick={() => onJoin()}>
            {t("Sign Up")}
          </BaseButton>
        </div>
        <SocialLogin className="client-signup__social" onClose={onClose} title={t("or Sign Up with")} />
        <div className="client-signup__control-wrapper __switch">
          <span className="caption">{t("Already have an account?")}</span>
          <BaseButton
            opacity={true}
            to={getLocation("/login", { notification })}
            className="client-signup__switch-button fw-normal"
          >
            <BaseIcon className="mr-1">signin</BaseIcon>
            <span>{t("Log In")}</span>
          </BaseButton>
        </div>
      </BaseForm>
    </>
  );
}
