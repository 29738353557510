import { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { throttle } from "lodash";
import { MessageThreadsSearchResult } from "@scrile/api-provider/dist/api/MessageThreadsProvider";
import useAuthUser from "../../../../hooks/useAuthUser";
import emitter, { EVENTS } from "../../../../lib/emitter";
import providers from "../../../../lib/providers";

function useController() {
  const [threads, setThreads] = useState<MessageThreadsSearchResult>();
  const [loadingThreads, setLoadingThreads] = useState<boolean>(false);

  const { user } = useAuthUser();

  useEffectOnce(() => {
    if (!user) return;
    setLoadingThreads(true);
    getMessageThreads().finally(() => {
      setLoadingThreads(false);
    });
  });

  const loadMore = async (cb?: Function) => {
    if (threads && !threads.hasNextPage) return;
    return await getMessageThreads(threads ? threads.page + 1 : 0, false).finally(() => cb && cb());
  };

  const getMessageThreads = async (page = 0, replace = true) => {
    const response = await providers.MessageThreadsProvider.find({
      data: { userId: user?.id || "", sort: "UPDATED_TIME,DESC", page, size: 20 },
    });
    setThreads((prevState) => {
      return {
        ...response,
        result: replace || !prevState?.result ? response.result : prevState.result.concat(response.result),
      };
    });
  };

  useEffect(() => {
    if (!user) return;
    const getNewMessage = throttle(() => {
      return getMessageThreads();
    }, 1000);

    emitter.on(EVENTS.MESSAGES_NEW_MESSAGE, getNewMessage);
    emitter.on(EVENTS.MESSAGES_MARKED_READ, getNewMessage);

    return () => {
      emitter.off(EVENTS.MESSAGES_NEW_MESSAGE, getNewMessage);
      emitter.off(EVENTS.MESSAGES_MARKED_READ, getNewMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    messageThreadList: threads?.result || [],
    scrollThreads: !loadingThreads && (threads?.hasNextPage ?? false),
    threads,
    loadingThreads,
    loadMore,
    user,
  };
}

export default useController;
