import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/scrollbar/scrollbar.scss";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";

interface Props<T> {
  list: T[];
  itemPrinter: (item: T) => JSX.Element;
  grayControls?: boolean;
}

export default function LandingSlider<T>({ list, itemPrinter, grayControls }: Props<T>) {
  const { desktopUp: isDesktop } = useBreakPoints();
  const classNameNavigation: string[] = [];
  if (!list || (!!list && list.length < 4)) {
    classNameNavigation.push("__hidden");
  }

  const cls = ["landing-slider"];
  if (grayControls) cls.push("__gray-controls");

  return (
    <div className={cls.join(" ")}>
      {!isDesktop && (
        <SimpleBar style={{ maxHeight: "100%" }} className="landing-slider__simplebar" direction="x">
          <ul className="landing-slider__simplebar-list">
            {list.map((item, i) => (
              <li className="landing-slider__simplebar-item" key={i}>
                {itemPrinter(item)}
              </li>
            ))}
          </ul>
        </SimpleBar>
      )}
      {isDesktop && (
        <Swiper
          slidesPerView={3}
          slidesPerGroup={3}
          spaceBetween={30}
          loopedSlides={3}
          speed={300}
          loopFillGroupWithBlank={false}
          style={{ maxHeight: "100%" }}
          className="landing-slider__swiper"
          modules={[Navigation, Pagination]}
          pagination={{
            el: ".landing-slider__pagination",
            bulletActiveClass: "__active",
            type: "bullets",
            clickable: true,
          }}
          navigation={{
            nextEl: ".landing-slider__button.__next",
            prevEl: ".landing-slider__button.__prev",
            disabledClass: "__disabled-custom",
          }}
        >
          {list.map((item, i) => (
            <SwiperSlide className="landing-slider__slide" key={i}>
              {itemPrinter(item)}
            </SwiperSlide>
          ))}
          <div className="landing-slider__controls d-center mt-8">
            <div className="landing-slider__pagination d-center" />
            <div className={["landing-slider__navigation ", classNameNavigation].join(" ")}>
              <BaseButton className="landing-slider__button __prev">
                <BaseIcon className="landing-slider__button-icon __prev">back</BaseIcon>
              </BaseButton>
              <BaseButton className="landing-slider__button __next">
                <BaseIcon className="landing-slider__button-icon __next">back</BaseIcon>
              </BaseButton>
            </div>
          </div>
        </Swiper>
      )}
    </div>
  );
}
