import React from "react";
import { Link } from "react-router-dom";
import { isPerformer } from "../../../../../lib/usersHelper";
import { PropsMessageItem } from "../types";
import BaseAvatar from "../../../../BaseAvatar";
import useController from "./controller";
import "./style.scss";

interface Props extends Omit<PropsMessageItem, "authUser"> {
  selfMessage: boolean;
  highlightMessage: boolean;
  contentMessage?: React.ReactNode;
  authUser?: unknown;
}

export default function DefaultMessageView({
  message,
  className,
  rendered,
  avatarSize,
  startMessage,
  endMessage,
  showScreenName,
  isLiveChat,
  contentMessage,
  selfMessage,
  highlightMessage,
}: Props) {
  const { desktopUp, ref, time, senderUser } = useController(message, rendered);
  const cls = ["default-message-view"];
  if (className) cls.push(className);
  if (selfMessage) cls.push("__self-message");
  if (highlightMessage) cls.push("__highlight-message");
  if (isLiveChat && !desktopUp) cls.push("__mobile-live-message");
  if ((startMessage && !endMessage) || (!startMessage && !endMessage)) cls.push("__compact-message");

  const avatarContent =
    isPerformer(senderUser) && !isLiveChat ? (
      <Link className="d-block" to={`/${senderUser?.username}`}>
        <BaseAvatar user={senderUser} className="default-message-view__avatar" size={avatarSize} />
      </Link>
    ) : (
      <BaseAvatar user={senderUser} className="default-message-view__avatar" size={avatarSize} />
    );

  return (
    <div className={cls.join(" ")} ref={ref} id={`message-${message.id}`}>
      {startMessage ? (
        avatarContent
      ) : (
        <div className="default-message-view__avatar __empty" style={{ width: avatarSize, minWidth: avatarSize }} />
      )}

      <div className="default-message-view__container">
        {showScreenName && startMessage && <div className="default-message-view__name">{senderUser?.screenName}</div>}
        {contentMessage}
        {endMessage && <div className="default-message-view__time small">{time}</div>}
      </div>
    </div>
  );
}
