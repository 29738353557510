import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import { useTranslation } from "../../../../locales";
import BaseIcon from "../../../BaseIcon";
import BaseButton from "../../../BaseButton";
import "./style.scss";

interface Props {
  className?: string;
}

function Navigation({ className }: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { t } = useTranslation();

  const cls = ["navigation"];
  if (className) cls.push(className);

  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const onClickItem = useCallback(() => {
    setIsComponentVisible(false);
  }, [setIsComponentVisible]);

  return (
    <div className={cls.join(" ")} ref={ref}>
      <div className="navigation__activator mr-2">
        <BaseButton borderless={true} icon={true} className="navigation__burger-button" onClick={toggleMenu}>
          <BaseIcon className={isComponentVisible ? "d-block" : "d-none"} size={"24"}>
            close
          </BaseIcon>
          <BaseIcon className={isComponentVisible ? "d-none" : "d-block"} size={"24"}>
            menu
          </BaseIcon>
        </BaseButton>
      </div>
      <div className={"navigation__content" + (isComponentVisible ? " __active" : "")}>
        <nav className="navigation__nav">
          <NavLink exact={true} to="/coaches" className="link navigation__nav-link" onClick={onClickItem}>
            {t("Find a Coach")}
          </NavLink>
          <NavLink exact={true} to="/insights" className="link navigation__nav-link" onClick={onClickItem}>
            {t("Insights")}
          </NavLink>
          <NavLink exact={true} to="/faq" className="link navigation__nav-link" onClick={onClickItem}>
            {t("FAQ")}
          </NavLink>
          <NavLink exact={true} to="/about-us" className="link navigation__nav-link" onClick={onClickItem}>
            {t("About Us")}
          </NavLink>
        </nav>
      </div>
    </div>
  );
}

export default Navigation;
