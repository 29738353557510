import { useEffect, useState, MouseEvent, RefObject, useMemo } from "react";
import { useLocation } from "react-use";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import useAuthUser from "../../hooks/useAuthUser";
import { useTranslation } from "../../locales";
import useCustomDomain from "../../hooks/useCustomDomain";

function useController(produceUser: User | UserPublicData) {
  const { t } = useTranslation();
  const [successfulCopy, setSuccessfulCopy] = useState(false);

  const location = useLocation();
  const { user } = useAuthUser();
  const { isCustomDomain, user: userFromDomain } = useCustomDomain();
  const chatLink = useMemo(
    () =>
      `${location?.origin}${
        isCustomDomain && produceUser?.id === userFromDomain?.id ? "" : `/${produceUser.username}`
      }/live`,
    [isCustomDomain, userFromDomain, produceUser, location]
  );

  const mailSubject = t("Live chat invite from {{screenName}}", { screenName: user?.screenName || "" });
  const mailText = t("{{screenName}} has invited you to join a live chat. {{chatLink}}", {
    screenName: user?.screenName || "",
    chatLink,
  });

  const onCopyText = (e: MouseEvent, ref: RefObject<HTMLInputElement>) => {
    e.stopPropagation();
    if (!ref.current) return;

    ref.current.select();
    ref.current.blur();
    const status = document.execCommand("copy");
    window.getSelection()?.removeAllRanges();
    if (status) setSuccessfulCopy(true);
  };

  const onFocusInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.select();
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (successfulCopy) {
      timeout = setTimeout(() => {
        setSuccessfulCopy(false);
      }, 1000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [successfulCopy]);

  return { onFocusInput, onCopyText, successfulCopy, chatLink, mailSubject, mailText };
}

export default useController;
