import { useCallback } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useAuthUser from "../../../../hooks/useAuthUser";
import { isPerformer } from "../../../../lib/usersHelper";
import providers from "../../../../lib/providers";

export default function useController(user: UserPublicData) {
  const { user: authUser } = useAuthUser();

  const getIsHavePastAppointments = useCallback(async () => {
    if (!authUser || isPerformer(authUser)) return false;

    const res = await providers.AppointmentProvider.search({
      consumerId: authUser.id,
      providerId: user.id,
      sort: "START_TIME,DESC",
      startTimeTo: new Date().toISOString(),
      size: 1,
    });

    return res.result.length > 0;
  }, [authUser, user]);

  return {
    getIsHavePastAppointments,
  };
}
