import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { WebRTCConnection } from "@scrile/api-provider/dist/api/WebRTCProvider";
import { MediaConstraints } from "../../../../types";

export default function useViewController(
  onChangeConstrains: (constraints: MediaConstraints) => Promise<void>,
  produceMediaConstraints: MediaConstraints,
  onProduceStream: () => Promise<boolean>,
  produceConnection: WebRTCConnection | undefined,
  available: boolean
) {
  const [produce, setProduce] = useState(false);
  const debounceTime = 200;

  const startProduce = useCallback(async () => {
    if (!produceConnection) {
      setProduce(await onProduceStream());
    }
  }, [produceConnection, onProduceStream]);

  const onStartProduce = debounce(async () => {
    await onChangeConstrains({
      videoEnabled: true,
      audioEnabled: true,
    });
    await startProduce();
  }, debounceTime);

  useEffect(() => {
    if (available && !produce && (produceMediaConstraints.audioEnabled || produceMediaConstraints.videoEnabled)) {
      startProduce().then();
    }
  }, [produceMediaConstraints, available, produce, startProduce]);

  return {
    produce,
    onStartProduce,
  };
}
