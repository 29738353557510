import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import BaseAvatar from "../../../../../BaseAvatar";
import "./style.scss";

interface Props {
  user: UserPublicData;
}

const Index = ({ user }: Props) => {
  return (
    <div className="users-list-item">
      <BaseAvatar className="users-list-item__avatar" size={30} user={user} />
      <span className="users-list-item__name label">{user.screenName}</span>
    </div>
  );
};

export default Index;
