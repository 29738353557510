import React from "react";
import { Link, Redirect, Route } from "react-router-dom";
import { useTranslation } from "../../locales";
import { isPerformer } from "../../lib/usersHelper";
import ContentHeader from "../../components/ContentHeader";
import BasePage from "../../components/BasePage";
import MessageThreadList from "./components/MessageThreadList";
import useController from "./controller";
import MessagesList from "./components/MessagesList";
import BaseButton from "../../components/BaseButton";
import BaseIcon from "../../components/BaseIcon";
import "./style.scss";

function PageMessages() {
  const { t } = useTranslation();
  const { user, senderUser, setSenderUser, desktopUp, onClickBackButton, match, isSelectThread } = useController();

  if (!user) return <Redirect to="/login" />;
  const contentHeader = (
    <ContentHeader className="page-messages__heading">
      {!desktopUp && isSelectThread ? senderUser?.screenName : t("Messages")}
    </ContentHeader>
  );
  return (
    <BasePage
      className="page-messages"
      prepend={
        <div className={"page-messages__header " + (!desktopUp && !isSelectThread ? "__center-text" : "")}>
          {isSelectThread && (
            <BaseButton
              round={true}
              borderless={true}
              onClick={onClickBackButton}
              className="page-messages__back-button mr-2"
            >
              <BaseIcon>fas-angle-left</BaseIcon>
            </BaseButton>
          )}
          {!desktopUp && isSelectThread && isPerformer(senderUser) ? (
            <Link to={`/${senderUser?.username}`} className="page-messages__heading-link">
              {contentHeader}
            </Link>
          ) : (
            contentHeader
          )}
        </div>
      }
    >
      <div className="page-messages__container">
        <Route exact={!desktopUp} path={match.url}>
          <MessageThreadList className="page-messages__content-threads" />
          {!isSelectThread && (
            <div className="page-messages__empty-right-block">
              <h3>{t("Chat list empty")}</h3>
            </div>
          )}
        </Route>
        <Route path={`${match.url}/:userId`}>
          <MessagesList setSenderUser={setSenderUser} />
        </Route>
      </div>
    </BasePage>
  );
}

export default PageMessages;
