import React, { useState } from "react";
import { useTranslation } from "../../locales";
import TextTruncate from "react-text-truncate";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import "./style.scss";

interface Props {
  lines: number;
  className?: string;
  children: string;
  hasMore?: boolean;
}

function TextClamp({ lines, className, children, hasMore }: Props) {
  const { t } = useTranslation();
  const [more, setMore] = useState(false);
  const cls = ["text-clamp"];
  if (className) cls.push(className);

  return (
    <>
      <TextTruncate
        containerClassName={cls.join(" ")}
        line={more ? 9999 : lines}
        text={children}
        textTruncateChild={
          hasMore && (
            <BaseButton
              className="text-clamp__button __blue-hover __shadow"
              opacity={true}
              onClick={() => setMore(true)}
            >
              {t("More")}
              <BaseIcon className="ml-1" size={12.5}>
                fas-angle-down
              </BaseIcon>
            </BaseButton>
          )
        }
      />
      {more ? (
        <BaseButton className="text-clamp__button __blue-hover" opacity={true} onClick={() => setMore(false)}>
          {t("Less")}
          <BaseIcon className="ml-1" size={12.5}>
            fas-angle-up
          </BaseIcon>
        </BaseButton>
      ) : null}
    </>
  );
}

export default TextClamp;
