import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay } from "swiper";
import useViewController from "./viewController";
import "swiper/swiper.scss";
import "./style.scss";

interface Props {
  className?: string;
  list: "founder" | "companies";
}

export default function SliderCompanies({ className, list }: Props) {
  const { companyImageInfo } = useViewController({ list });
  const cls = ["slider-companies"];
  if (className) cls.push(className);

  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={26}
      loopedSlides={4}
      loop={true}
      speed={5000}
      autoplay={{ delay: 300, disableOnInteraction: false }}
      loopFillGroupWithBlank={false}
      centeredSlides={true}
      modules={[Autoplay]}
      className={cls.join(" ")}
    >
      {companyImageInfo.map((el, i) => (
        <SwiperSlide className="slider-companies__slide" key={i}>
          <img src={el.src} alt={el.alt} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
