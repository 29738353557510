import React from "react";
import { Testimonial } from "@scrile/api-provider/dist/api/TestimonialsProvider";
import BaseIcon from "../../../../../../components/BaseIcon";
import BaseAvatar from "../../../../../../components/BaseAvatar";
import "./style.scss";

interface Props {
  item: Testimonial;
}

export default function TestimonialItem({ item }: Props) {
  return (
    <div className="testimonial-item pa-6">
      <BaseIcon className="testimonial-item__double-quotes" size={32}>
        double-quotes
      </BaseIcon>
      <div className="testimonial-item__header mb-4">
        <BaseAvatar size={80} user={item} />
        <div className="testimonial-item__info">
          <div className="testimonial-item__name">{item.name}</div>
          <div className="testimonial-item__role mt-1">{item.role}</div>
        </div>
      </div>
      <div className="testimonial-item__text">{item.text}</div>
      <div className="testimonial-item__background mt-4">{item.background}</div>
    </div>
  );
}
