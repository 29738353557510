import { ChangeEvent, useRef, useState } from "react";
import useController from "./controller";

export default function useViewController() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const { onSendFile, removeFile, userCV } = useController();

  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      await onSendFile(e.target.files);
    } finally {
      setLoading(false);
    }
  };

  return {
    inputRef,
    onChange,
    onButtonClick,
    removeFile,
    loading,
    userCV,
  };
}
