import { OptionItem } from "../../../../types";

export enum PayoutSystemType {
  PAYPAL = "paypal",
  OTHER = "other",
}

export const payeeSystemsList: OptionItem[] = [
  {
    value: PayoutSystemType.PAYPAL,
    text: "PayPal",
  },
  {
    value: PayoutSystemType.OTHER,
    text: "Other",
  },
];
