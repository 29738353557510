import React from "react";
import BaseIcon from "../BaseIcon";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  className?: string;
  totalPages: number;
  currentPageChanged: (currentPage: number) => void;
}

export default function BasePaginator({ className, totalPages, currentPageChanged }: Props) {
  const { pages, currentPage, onClickPrev, onClickNext, setCurrentPage } = useViewController(
    totalPages,
    currentPageChanged
  );

  const cls = ["base-paginator"];
  if (className) cls.push(className);

  if (totalPages < 2) return null;
  return (
    <div className={cls.join(" ")}>
      <BaseIcon onClick={onClickPrev} className="base-paginator__back-arrow">
        back
      </BaseIcon>
      <div className="base-paginator__pages">
        {pages.map((i, index) => {
          if (i === -1) {
            return (
              <BaseIcon className="base-paginator__more-icon" key={index}>
                more
              </BaseIcon>
            );
          } else {
            return (
              <span
                onClick={() => setCurrentPage(i)}
                className={"base-paginator__item" + (currentPage === i ? " __current" : "")}
                key={index}
              >
                {i + 1}
              </span>
            );
          }
        })}
      </div>

      <BaseIcon onClick={onClickNext} className="base-paginator__forward-arrow">
        back
      </BaseIcon>
    </div>
  );
}
