import React from "react";
import { Link } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { useTranslation } from "../../../../locales";
import BaseIcon from "../../../../components/BaseIcon";
import CardAvatar from "../../../../components/CardAvatar";
import BaseTags from "../../../../components/BaseTags";
import BaseCard from "../../../../components/BaseCard";
import BaseButton from "../../../../components/BaseButton";
import AddToFavorite from "../../../../components/AddToFavorite";
import CalendlyButton from "../../../../components/CalendlyButton";
import TextClamp from "../../../../components/TextClamp";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  user: UserPublicData;
  favorite?: boolean;
}

function PerformerCard({ user, favorite }: Props) {
  const { t } = useTranslation();
  const { askLink, showBooking, showButtons, firmName, universityName } = useViewController(user);

  return (
    <BaseCard className="performer-card">
      <div className="performer-card__inner">
        <CardAvatar
          to={user.username}
          user={user}
          className="performer-card__avatar-container"
          classNameAvatar="performer-card__avatar"
        />
        <div className="performer-card__status-line">
          <AddToFavorite className="performer-card__favorite pa-0" user={user} favorite={favorite} />
        </div>
        <div className="performer-card__container">
          <div className="performer-card__description">
            <Link className="performer-card__link" to={user.username}>
              <h3 className="performer-card__name">{user.screenName}</h3>
            </Link>
            <div className="performer-card__info d-flex __align-center __wrap">
              {user?.questionary?.fields.location && (
                <div className="performer-card__location">
                  <BaseIcon className="performer-card__location-icon">location</BaseIcon>
                  <span className="performer-card__location-text">{user.questionary?.fields.location as string}</span>
                </div>
              )}
              {firmName && (
                <Link className="performer-card__professional d-center" to={user.username}>
                  <BaseIcon className="performer-card__professional-icon">available</BaseIcon>
                  <span className="performer-card__professional-text ml-2">{firmName}</span>
                </Link>
              )}
              {universityName && (
                <Link className="performer-card__academic d-center" to={user.username}>
                  <BaseIcon className="performer-card__academic-icon">academic</BaseIcon>
                  <span className="performer-card__academic-text ml-2">{universityName}</span>
                </Link>
              )}
            </div>
            {user.questionary?.fields.about && (
              <Link className="performer-card__about" to={user.username}>
                <TextClamp lines={isMobile() ? 3 : 2}>
                  {user?.questionary?.fields?.about as string}
                </TextClamp>
              </Link>
            )}
            {user.questionary?.tags && <BaseTags user={user} className="performer-card__tags" />}
            {showButtons && (
              <div className="performer-card__bottom-buttons">
                {askLink && (
                  <BaseButton to={askLink} outline={true} className="performer-card__button performer-card__ask-button">
                    {t("Ask a question")}
                  </BaseButton>
                )}
                {showBooking && (
                  <CalendlyButton
                    className="performer-card__button"
                    providerUser={user}
                    scheduleUrl={user.calendlyScheduleUrl!}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseCard>
  );
}

export default PerformerCard;
