import { useState } from "react";
import { useMount } from "react-use";
import { ReviewStatistic } from "@scrile/api-provider/dist/api/ReviewProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import providers from "../../../../lib/providers";

export interface UserPublishDataWithRating extends UserPublicData {
  reviewStatistic: ReviewStatistic | undefined;
}

export default function useController() {
  const [statisticReview, setStatisticReview] = useState<UserPublishDataWithRating[]>([]);
  useMount(async () => {
    const performers = await providers.PerformerListProvider.performerListSearch({ data: { size: 99999 } }, [
      "id",
      "username",
      "screenName",
      { avatar: ["urlPart"] },
      { questionary: ["fields"] },
    ]);
    const rating = await providers.ReviewProvider.findRatings({ data: { subjectType: "user", size: 99999 } });
    const sortRating = rating.result.reduce<Record<string, ReviewStatistic>>((out, i) => {
      out[i.subject.subjectId] = i;
      return out;
    }, {});
    const performersWithRating = performers.result.map((i) => ({ ...i, reviewStatistic: sortRating[i.id] }));
    performersWithRating.sort(
      (a: UserPublishDataWithRating, b: UserPublishDataWithRating) =>
        (b.reviewStatistic?.averageRating || 0) - (a.reviewStatistic?.averageRating || 0)
    );
    setStatisticReview(performersWithRating);
  });

  return {
    statisticReview,
  };
}
