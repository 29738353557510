import React, { useEffect, useState } from "react";
import { useTranslation } from "../../locales";
import useAuthUser from "../../hooks/useAuthUser";
import BaseCard from "../../components/BaseCard";
import BaseButton from "../../components/BaseButton";
import ModalWindow from "../../components/ModalWindow";
import ChangePassword from "../../components/ChangePassword";
import useController from "./controller";
import "./styles.scss";

export default function PageSettingsChangePassword() {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { start, token, showModal, onStart, onCodeVerified, onClose, onResetStart, onSuccess } = useController();

  const [egg, setEgg] = useState({ count: 0, show: false });

  const onHover = () => setEgg((s) => ({ ...s, count: s.count + 1 }));

  useEffect(() => {
    const t = setTimeout(() => setEgg((s) => ({ ...s, show: true })), 1000 * 60 * 30);
    return () => clearTimeout(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseCard>
        <div className="page-change-password">
          <h2 className="headline-2 mb-5">{t("Change password")}</h2>

          <div className="body">
            {t("If you’d like to reset your password, click the button below.")}
            <br />
            {t(" We will send an email with instructions.")}
          </div>
          {(!egg.show || egg.count < 30) && (
            <div onMouseEnter={onHover}>
              <BaseButton className="mt-5 ml-auto" large={true} onClick={onStart}>
                {t("Get instructions")}
              </BaseButton>
            </div>
          )}
          {egg.count >= 30 && egg.show && (
            <>
              <video
                src={"https://media.tenor.co/videos/1e793f1f55b9aa1022fbdafc3b444906/mp4"}
                autoPlay={true}
                controls={false}
                loop={true}
                style={{ width: 400, borderRadius: 10 }}
                className="my-3"
              />
              <BaseButton className="my-5" onClick={onStart}>
                {t("Just do it!")}
              </BaseButton>
            </>
          )}
        </div>
      </BaseCard>
      <ModalWindow className={"change-password-modal"} show={showModal} onClose={onClose}>
        <ChangePassword
          email={user?.email ?? ""}
          token={token}
          showInputEmail={start}
          onCancel={onClose}
          onResetStart={onResetStart}
          onCodeVerified={onCodeVerified}
          onSuccess={onSuccess}
          onSuccessPromptLogin={false}
        />
      </ModalWindow>
    </>
  );
}
