import React from "react";
import { NavLink } from "react-router-dom";
import BaseIcon from "../../../BaseIcon";
import BaseCounter from "../../../BaseCounter";
import "./style.scss";

interface Props {
  unreadThreadsCount: number;
}

export default function QuickSmallNavigator({ unreadThreadsCount }: Props) {
  return (
    <div className="quick-small-navigator">
      <NavLink to="/favorites" className="quick-small-navigator__link mr-6">
        <BaseIcon>favorites</BaseIcon>
      </NavLink>
      <NavLink to="/messages" className="quick-small-navigator__link mr-5">
        <div className="quick-small-navigator__link-content">
          <BaseIcon>messages</BaseIcon>
          <BaseCounter className="quick-small-navigator__message-counter">{unreadThreadsCount}</BaseCounter>
        </div>
      </NavLink>
    </div>
  );
}
