import React from "react";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  className?: string;
  streamStartedDate: string;
  grayRecordIcon?: boolean;
  children: string;
  showTimeIfNegative?: boolean;
  hideTimer?: boolean;
}

function ChatTimer({ className, streamStartedDate, showTimeIfNegative, hideTimer, children, grayRecordIcon }: Props) {
  const { time, isChatStarted } = useViewController(streamStartedDate);

  const cls = ["chat-timer", "small"];
  if (className) cls.push(className);
  if (!isChatStarted || grayRecordIcon) cls.push("__gray-circle");

  return time ? (
    <div className={cls.join(" ")}>
      <span className="ml-1">{children}</span>
      {!hideTimer && (showTimeIfNegative || isChatStarted) && <span className="time caption ml-1">{time}</span>}
    </div>
  ) : null;
}

export default ChatTimer;
