import React, { useMemo } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import PerformerCard from "../PerformerCard";
import "./style.scss";

interface Props {
  users: UserPublicData[];
  userFavoriteIds: string[];
}

function PerformerList({ users, userFavoriteIds }: Props) {
  const favorite = useMemo(() => {
    const favoritesMap = new Map();
    userFavoriteIds.forEach((item, i) => favoritesMap.set(item, i));
    return favoritesMap;
  }, [userFavoriteIds]);

  return (
    <div className="performer-list">
      {users.map((user) => {
        return <PerformerCard key={user.id} user={user} favorite={favorite.has(user.id)} />;
      })}
    </div>
  );
}

export default PerformerList;
