import React from "react";
import "./style.scss";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { MediaFilesController } from "../../lib/MediaFileController";

interface Props {
  className?: string;
  src?: string;
  user?: Pick<User | UserPublicData, "avatar"> | null;
  size: number;
}

function BaseAvatar({ className = "", user, size, src: srcImage }: Props) {
  const src =
    (user?.avatar && MediaFilesController.getImageThumbnail(user.avatar.urlPart, "240p")) ||
    srcImage ||
    "/images/default-avatar.svg";
  const cls = ["base-avatar"];

  cls.push(className);

  return (
    <div className={cls.join(" ")} style={{ width: size, height: size }}>
      <img src={src} alt="avatar" className="base-avatar__avatar" />
    </div>
  );
}

export default BaseAvatar;
