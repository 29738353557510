import { useState } from "react";
import { useMount } from "react-use";
import { Testimonial } from "@scrile/api-provider/dist/api/TestimonialsProvider";
import providers from "../../../../lib/providers";

export default function useViewController() {
  const [loading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  useMount(() => {
    (async function () {
      setLoading(true);
      try {
        const testimonials = await providers.TestimonialsProvider.findAll();
        setTestimonials(testimonials);
      } finally {
        setLoading(false);
      }
    })();
  });

  return { loading, testimonials };
}
