import React from "react";
import "./styles.scss";

interface Props {
  className?: string;
}

export default function BaseDivider({ className }: Props) {
  const cls = ["base_divider"];
  if (className != null) cls.push(className);

  return <div className={cls.join(" ")}></div>;
}