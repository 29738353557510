import React from "react";
import { useTranslation } from "../../../../locales";
import ElementLoading from "../../../../components/ElementLoading";
import BaseDivider from "../../../../components/BaseDivider";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import ProfessionalFields from "../ProfessionalFields";
import useController from "./controller";
import "./style.scss";

export default function ProfessionalPage() {
  const { onSave, onChange, onDelete, onAdd, loading, processing, jobs, setCheckValidation } = useController();
  const { t } = useTranslation();

  return (
    <div className="professional-page">
      {loading ? (
        <ElementLoading loading={loading} />
      ) : (
        <>
          <ProfessionalFields
            job={jobs[0]}
            jobIndex={0}
            onChange={(job) => onChange(job, 0)}
            setCheckValidation={setCheckValidation}
          />
          {jobs.slice(1).map((job, index) => {
            index += 1;
            return (
              <div key={index}>
                <BaseDivider className="professional-page__divider" />
                <ProfessionalFields
                  job={job}
                  jobIndex={index}
                  onDelete={() => onDelete(index)}
                  onChange={(job) => onChange(job, index)}
                  setCheckValidation={setCheckValidation}
                />
              </div>
            );
          })}
          <BaseButton className="professional-page__add-field pa-0 mt-6" opacity onClick={onAdd}>
            <BaseIcon className="mr-1">circle-plus</BaseIcon>
            {t("Add job")}
          </BaseButton>
          <BaseButton className="professional-page__save ml-auto mt-8" loading={processing} large onClick={onSave}>
            {t("Save changes")}
          </BaseButton>
        </>
      )}
    </div>
  );
}
