import { useMemo } from "react";

interface CompanyImageInfo {
  src: string;
  alt: string;
}

export default function useViewController({ list }: { list: "founder" | "companies" }) {
  const context = useMemo<ReturnType<typeof require.context> | null>(() => {
    if (list === "companies") {
      return require.context("../../assets/about-us/companies", true, /\.svg$/);
    } else if (list === "founder") {
      return require.context("../../assets/about-us/founder", true, /\.svg$/);
    }
    return null;
  }, [list]);
  const companyImageInfo = useMemo<CompanyImageInfo[]>(() => {
    if (context === null) return [];
    return context.keys().map((key) => ({
      src: context(key),
      alt: key.substr(2, key.length - 6),
    }));
  }, [context]);

  return {
    companyImageInfo,
  };
}
