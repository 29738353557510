import { useEffect, useState } from "react";
import { differenceInMilliseconds, parseISO, toTimePassed } from "@scrile/tools/dist/lib/TimeHelpers";

function useViewController(streamStartedDate: string) {
  const [time, setTime] = useState("");
  const [isChatStarted, setIsChatStarted] = useState(false);

  useEffect(() => {
    if (!streamStartedDate) {
      setTime("");
      return;
    }
    const countDate = () => {
      const diff = differenceInMilliseconds(new Date(), parseISO(streamStartedDate));
      setIsChatStarted(diff >= 0);
      setTime(toTimePassed(Math.max(0, diff)));
    };
    countDate();
    const timeout = setInterval(countDate, 1000);
    return () => {
      timeout && clearInterval(timeout);
    };
  }, [streamStartedDate]);

  return {
    time,
    isChatStarted,
  };
}

export default useViewController;
