import React from "react";
import { LocationDescriptor } from "history";
import useAppState from "../../hooks/useAppState";
import { printCredits } from "../../lib/moneyHelper";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import "./style.scss";

interface Props {
  className?: string;
  showRightIcon: boolean;
  onClick?: () => void;
  to?: LocationDescriptor;
}

function BaseBalance({ className, onClick, to, showRightIcon }: Props) {
  const { balance, balanceLoading } = useAppState();

  const cls = ["base-balance"];
  if (className) cls.push(className);

  const content = (
    <>
      <BaseIcon className="mr-1">wallet</BaseIcon>
      <span className={"base-balance__text"}>{printCredits(balance)}</span>
      {showRightIcon && <BaseIcon className="ml-auto">circle-plus</BaseIcon>}
    </>
  );

  return (
    <div className={cls.join(" ")}>
      <BaseButton
        className="base-balance__button fw-normal"
        loading={balanceLoading}
        opacity={true}
        onClick={onClick}
        to={to}
      >
        {content}
      </BaseButton>
    </div>
  );
}

export default BaseBalance;
