import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { ProfileVideo } from "@scrile/api-provider/dist/api/QuestionaryInfoProvider";
import { useTranslation } from "../../../../locales";
import "./style.scss";
import { ProfileVideoData } from "../../controller";
import { getMediaDuration } from "@scrile/tools/dist/lib/TimeHelpers";
import BaseIcon from "../../../../components/BaseIcon";
import BaseSpinner from "../../../../components/BaseSpinner";

interface Props {
  user: UserPublicData;
  className?: string;
  video: ProfileVideoData;
  loading: boolean;
  onClick: () => void;
}

function PerformerVideo({ user, loading, className, onClick, video }: Props) {
  const { t } = useTranslation();

  const cls = ["performer-video"];
  if (className) cls.push(className);
  const videoInfo: ProfileVideo | undefined = user.questionary?.fields.video;
  const duration = (video.fileData && getMediaDuration(Number(video.fileData.metadata.duration))) || "";

  return (
    <div className={cls.join(" ")}>
      <div className="performer-video__image-wrapper" style={{ paddingBottom: `${(9 / 16) * 100}%` }} onClick={onClick}>
        {loading ? (
          <div className="performer-video__preloader">
            <BaseSpinner />
          </div>
        ) : (
          <>
            <img className="performer-video__image" src={video.thumbnailUrl} alt={t("Coach video")} />
            {duration && <span className="performer-video__duration small pa-1">{duration}</span>}
            <div className="performer-video__icon-wrapper">
              <BaseIcon className="performer-video__icon" size={24}>
                play
              </BaseIcon>
            </div>
          </>
        )}
      </div>
      {videoInfo?.title && <h4 className="performer-video__title body-big mt-4 mb-2">{videoInfo.title}</h4>}
      {videoInfo?.description && <div className="performer-video__description">{videoInfo.description}</div>}
    </div>
  );
}

export default PerformerVideo;
