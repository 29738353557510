import { webvideo } from "@scrile/api-provider/dist";
import Configuration from "@scrile/api-provider/dist/Configuration";
import config from "../config";
import emitter from "./emitter";
const providers = webvideo({
  url: config.apiUrl,
  notificationCB: (event, payload) => {
    emitter.emit(event, payload);
  },
  appStorage: {
    getString(key: string, defaultValue: string): string | Promise<string> {
      return localStorage.getItem(key) || defaultValue;
    },
    setString(key: string, value: string): void | Promise<void> {
      localStorage.setItem(key, value);
    },
  },
  websocketOnly: true,
});

Configuration.featureLiveChat = true;

export default providers;
