import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../locales";
import useAuthUser from "../../hooks/useAuthUser";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import useViewController from "./viewController";
import ModalContactUs from "./components/ModalContactUs";
import "./style.scss";

function PageFooter() {
  const { t } = useTranslation();
  const { isShowFooter, showModal, setShowModal } = useViewController();
  const { getLocation } = useModalLinkPrinter();
  const { user } = useAuthUser();
  if (!isShowFooter) {
    return null;
  }
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer__inner">
            <ul className="footer__links">
              <li className="footer__links-item">
                <span className="footer__link link" onClick={() => setShowModal(true)}>
                  {t("Contact Us")}
                </span>
              </li>
              {!user && (
                <li className="footer__links-item">
                  <Link className="footer__link" to={getLocation("/signup/coach")}>
                    {t("Become a Coach")}
                  </Link>
                </li>
              )}
              <li className="footer__links-item">
                <a className="footer__link" href="/static/terms-of-use.html" target="_blank">
                  {t("Terms & Conditions")}
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="/static/privacy-policy.html" target="_blank">
                  {t("Privacy Policy")}
                </a>
              </li>
            </ul>
            <div className="footer__copyright">{new Date().getFullYear() + " © SecureTheOffer"}</div>
          </div>
        </div>
      </footer>
      <ModalContactUs show={showModal} onClose={() => setShowModal(false)} />
    </>
  );
}

export default PageFooter;
