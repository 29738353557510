import providers from "../../lib/providers";

export default function useController() {
  const sendTip = async (recipientId: string, threadId: string, amount: number) => {
    await providers.TipProvider.create({
      data: { recipientId, threadId, amount },
    });
  };

  return { sendTip };
}
