import React from "react";
import { Link } from "react-router-dom";
import BaseAvatar from "../../../../components/BaseAvatar";
import BaseRating from "../../../../components/BaseRating";
import { UserPublishDataWithRating } from "../BlockOurCoaches/controller";
import "./style.scss";

interface Props {
  item: UserPublishDataWithRating;
}

export default function CoachItem({ item }: Props) {
  const profession =
    item.questionary?.fields?.professional?.jobs[item.questionary.fields.professional.jobs.length - 1]?.firm ?? null;
  return (
    <Link className="coach-item" to={item.username ?? ""}>
      <div className="coach-item__top">
        <BaseAvatar className="coach-item__avatar" user={item} size={80} />
        <div className="coach-item__info">
          <h3 className="coach-item__name">{item.screenName}</h3>
          <div className="coach-item__rating-container">
            <BaseRating value={Math.round(item.reviewStatistic?.averageRating || 0)} />
            <span className="coach-item__rating-count ml-1">({item.reviewStatistic?.count || 0})</span>
          </div>
          {profession && <div className="coach-item__profession truncate-ellipsis mt-2">{profession}</div>}
        </div>
      </div>
      <div className="coach-item__about">{item.questionary?.fields?.about as string}</div>
    </Link>
  );
}
