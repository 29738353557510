import React from "react";
import { Message } from "@scrile/api-provider/dist/api/MessagesProvider";
import { getComponent, getContextModules } from "../../../../lib/requireContextHelper";
import { PropsMessageItem } from "./types";

const context = require.context("./", true, /\.\/Message[a-zA-Z]*\/index.ts/);
const modules = getContextModules(context);

export function getMessageItem(message: Message): React.FC<PropsMessageItem> | null {
  return getComponent(modules, message.details, null);
}
