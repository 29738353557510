import React, { useEffect, useMemo, useState } from "react";
import { payeeSystemsList, PayoutSystemType } from "./config";
import { validateFieldsWithException } from "../../../../lib/validation";
import { parseErrors } from "../../../../lib/requestHelpers";
import useBaseForm from "../../../../hooks/useBaseForm";
import { I18n, t } from "../../../../locales";
import { BaseSelectField, OptionItem } from "../../../../types";
import useController from "./controller";
import { getCountryList } from "@scrile/tools/dist/lib/countryList";

export default function useViewController() {
  const { sendData, configuredPayoutSystem, currentPayeeInfo, loading } = useController();
  const [processing, setProcessing] = useState(false);

  const payoutSystems: OptionItem[] = useMemo(
    () =>
      payeeSystemsList.filter(
        (i) => !!configuredPayoutSystem.find((s) => s.systemName === i.value && s.currencies.length > 0)
      ),
    [configuredPayoutSystem]
  );

  const countryOptions: OptionItem[] = useMemo(
    () =>
      getCountryList().map((country) => {
        return {
          text: country.name,
          value: country.code,
        };
      }),
    []
  );

  const { values, setValues, fields, errors, setErrors } = useBaseForm([
    {
      name: "firstName",
      type: "text",
      className: "payment-info__field __half",
      label: t("First name"),
      validation: {
        required: true,
      },
    },
    {
      name: "lastName",
      type: "text",
      label: t("Last name"),
      className: "payment-info__field __half",
      validation: {
        required: true,
      },
    },
    {
      name: "phone",
      type: "text",
      label: t("Phone number"),
      className: "payment-info__field __half",
      validation: {
        required: true,
      },
    },
    {
      name: "country",
      type: "select",
      label: t("Country"),
      placeholder: t("Choose"),
      className: "payment-info__field __half",
      options: countryOptions,
    },
    {
      name: "zip",
      type: "text",
      label: t("ZIP code"),
      className: "payment-info__field __half",
      validation: {
        required: true,
      },
    },
    {
      name: "city",
      type: "text",
      label: t("City"),
      className: "payment-info__field __half",
      validation: {
        required: true,
      },
    },
    {
      name: "address",
      type: "text",
      label: t("Address"),
      className: "payment-info__field",
      validation: {
        required: true,
      },
    },
    {
      name: "payoutSystem",
      type: "select",
      label: t("Payout system"),
      placeholder: t("Choose"),
      className: "payment-info__field __half",
      options: payoutSystems,
    },
    {
      name: "currency",
      type: "select",
      label: t("Currency"),
      placeholder: t("Choose"),
      className: "payment-info__field __half",
      options: [],
    },
    {
      name: "tcAgree",
      type: "checkbox",
      label: (
        <span className="caption">
          <I18n
            path="You agree to our <terms>Terms & Conditions</terms>"
            components={{
              terms: (
                <a
                  className="link"
                  href="/static/terms-of-use.html"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  terms
                </a>
              ),
            }}
          />
        </span>
      ),
      className: "payment-info__field",
      style: { justifyContent: "flex-start" },
      validation: {
        required: true,
        customMessage: t("You must agree to our Terms & Conditions"),
      },
    },
  ]);

  // get currency list for current selected payout system
  const currencyList = useMemo(() => {
    const selectedPayoutSystem = configuredPayoutSystem.find((i) => i.systemName === values.payoutSystem);
    return selectedPayoutSystem ? selectedPayoutSystem.currencies.map((i) => ({ value: i, text: i })) : [];
  }, [values, configuredPayoutSystem]);

  const field = fields.find((i) => i.name === "currency") as BaseSelectField;
  field.options = currencyList;

  // apply payout system related fields based on selected payout system.
  values.payoutSystem &&
    fields.splice(
      fields.length - 1,
      0,
      values.payoutSystem === PayoutSystemType.PAYPAL
        ? {
            name: `${PayoutSystemType.PAYPAL}Account`,
            type: "text",
            label: t("PayPal email address"),
            className: "payment-info__field",
            validation: {
              required: true,
            },
          }
        : {
            name: `${PayoutSystemType.OTHER}Details`,
            type: "text",
            label: t("Details"),
            className: "payment-info__field",
            validation: {
              required: true,
            },
          }
    );

  useEffect(() => {
    if (!currentPayeeInfo) return;
    setValues({
      firstName: currentPayeeInfo.firstName,
      lastName: currentPayeeInfo.lastName,
      phone: currentPayeeInfo.phone,
      country: currentPayeeInfo.country,
      zip: currentPayeeInfo.zip,
      city: currentPayeeInfo.city,
      address: currentPayeeInfo.address,
      payoutSystem: currentPayeeInfo.payoutSystem,
      currency: currentPayeeInfo.currency,
      otherDetails: currentPayeeInfo?.other?.details ?? "",
      paypalAccount: currentPayeeInfo?.paypal?.account ?? "",
    });
  }, [currentPayeeInfo, setValues]);

  const onSendData = async () => {
    setProcessing(true);
    try {
      validateFieldsWithException(fields, values);
      await sendData(values);
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setProcessing(false);
    }
  };

  return {
    loading,
    processing,
    onSendData,
    values,
    setValues,
    fields,
    errors,
    setErrors,
  };
}
