import React, { MutableRefObject } from "react";
import { isIOS, isMacOS, isMobile } from "@scrile/tools/dist/lib/browserUtils";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./style.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
}

export default function CustomScrollBar(props: React.PropsWithChildren<Props>) {
  if (isIOS() || isMacOS() || isMobile()) {
    return (
      <div
        {...(props.containerRef ? { ref: props.containerRef } : {})}
        style={{ maxHeight: "100%", height: "100%", ...props.style }}
        className={["custom-scroll-bar", props.className].join(" ")}
      >
        {props.children}
      </div>
    );
  }
  return (
    <SimpleBar
      scrollableNodeProps={{ ref: props.containerRef }}
      style={{ maxHeight: "100%", height: "100%", ...props.style }}
      className={["custom-scroll-bar", props.className].join(" ")}
    >
      {props.children}
    </SimpleBar>
  );
}
