import React from "react";
import { CalendlyEventListener } from "react-calendly";
import PaymentBooking from "../../modals/PaymentBooking";
import { useTranslation } from "../../locales";
import { isPerformer } from "../../lib/usersHelper";
import useViewController from "./viewController";

export default function CalendlyEventListenerCustom() {
  const {
    user,
    showModal,
    providerUser,
    appointment,
    onEventScheduled,
    onClosePaymentBooking,
  } = useViewController();
  const { t } = useTranslation();
  if (isPerformer(user)) return null;
  return (
    <>
      <CalendlyEventListener onEventScheduled={onEventScheduled} />
      <PaymentBooking
        show={showModal}
        appointment={appointment}
        onClose={onClosePaymentBooking}
        title={t("Booking confirmed")}
        content={t("You have booked an appointment with {{screenName}}", { screenName: providerUser?.screenName || "" })}
      />
    </>
  );
}
