import { useState } from "react";
import { FieldInterface, FormValues } from "../types";

export default function useBaseForm(fields: FieldInterface[], defaultValues: FormValues = {}) {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [values, setValues] = useState<FormValues>(defaultValues);
  return {
    fields,
    values,
    errors,
    setValues,
    setErrors,
  };
}
