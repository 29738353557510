import { useEffect, useState } from "react";
import { FieldInterface, FormValues } from "../../types";
import { validateFields } from "../../lib/validation";
import { useTranslation } from "../../locales";
import { parseErrors } from "../../lib/requestHelpers";

export function useViewController({
  onResendCode,
  onSendCode,
}: {
  onResendCode: () => Promise<void>;
  onSendCode: (code: string) => void;
}) {
  const [values, setValues] = useState<FormValues>({});
  const onChange = (values: FormValues) => setValues(values);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [timer, setTimer] = useState(60);

  const { t } = useTranslation();

  const fields: FieldInterface[] = [
    {
      name: "code",
      type: "number",
      placeholder: t("Code"),
      validation: {
        required: true,
      },
      textAlign: "center",
    },
  ];

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    timer !== 0 && (timeout = setTimeout(() => setTimer(timer - 1), 1000));
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [timer]);

  const resendCode = async () => {
    try {
      await onResendCode();
      setTimer(60);
    } catch (e) {
      setErrors(e);
    }
  };

  const sendCode = async () => {
    const validation = validateFields(fields, values);
    try {
      if (!validation.valid) {
        setErrors(validation.errors);
        return;
      }
      return await onSendCode(values.code);
    } catch (e) {
      setErrors(parseErrors(e));
    }
  };

  return {
    fields,
    values,
    onChange,
    errors,
    setErrors,
    timer,
    onResendCode: resendCode,
    onSendCode: sendCode,
  };
}
