import React, { useState } from "react";
import { TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";
import { useTranslation } from "../../../../locales";
import useCategories from "../../../../hooks/useCategories";
import BaseFilterContainer from "../BaseFilterContainer";

export default function FilterByTags({ onFilterTags }: { onFilterTags: (items: number[] | undefined) => void }) {
  const { t } = useTranslation();
  const [values, setValues] = useState<TagInfo[]>([]);
  const { categories, tags } = useCategories();

  const onInput = (values: TagInfo[]) => {
    setValues(values);
    if (values.length === 0) onFilterTags(undefined);
    else onFilterTags(values.map((i) => parseInt(i.id)));
  };

  return (
    <BaseFilterContainer
      categories={categories}
      tags={tags}
      values={values}
      onInput={onInput}
      filterPlaceholder={t("Search practice area")}
      className="filter-by-tags"
    />
  );
}
