import { FieldInterface, FormValues } from "../types";
import { ErrorDTO } from "@scrile/api-provider/dist/lib/apiErrorHandler";
import { t } from "../locales";
interface ValidationResult {
  valid: boolean;
  errors: Record<string, string>;
}

export function validateFields(fields: FieldInterface[], values: FormValues): ValidationResult {
  const result: ValidationResult = {
    valid: true,
    errors: {},
  };
  for (const field of fields) {
    let validField = true;
    if ("validation" in field && field.validation?.required) {
      validField = !!values[field.name];
      if (!validField) {
        result.errors[field.name] = field.validation?.customMessage || t("This field cannot be empty");
      }
    }
    result.valid = result.valid ? validField : result.valid;
  }
  return result;
}

export function validateFieldsWithException(fields: FieldInterface[], values: FormValues) {
  const validation: ValidationResult = validateFields(fields, values);

  if (!validation.valid) {
    const errors: ErrorDTO[] = [];
    Object.keys(validation.errors).forEach((key) => {
      const error = new Error(validation.errors[key]);
      const e: ErrorDTO = {
        error,
        message: t(error.message),
        status: "error",
        code: "UNKNOWN_ERROR",
        details: {
          className: "",
          message: "",
          messageTemplate: "",
          propertyPath: key,
        },
      };
      errors.push(e);
    });
    throw errors;
  }
}
