import React from "react";
import { t } from "../../../../locales";
import FullWidthPageBackground from "../../../../components/FullWidthPageBackground";
import SliderCompanies from "../../../../components/SliderCompanies";
import CoachItem from "../CoachItem";
import LandingSlider from "../LandingSlider";
import useController, { UserPublishDataWithRating } from "./controller";
import "./style.scss";

export default function BlockOurCoaches() {
  const { statisticReview } = useController();
  const itemPrinter = (item: UserPublishDataWithRating) => <CoachItem item={item} />;

  return (
    <div className="block-our-coaches">
      <FullWidthPageBackground className="block-our-coaches__background" />
      <div className="block-our-coaches__container">
        <h2 className="block-our-coaches__title">{t("Our Coaches")}</h2>
        <div className="block-our-coaches__middle-content">
          <p className="block-our-coaches__description body-big fw-normal">
            {t(
              "We have curated a high-calibre roster of coaches. They are selected for their excellent academic and professional credentials and their strong soft-skills. All of our coaches have been successful in securing offers from prestigious financial institutions, and all have been interviewers themselves while working at those institutions."
            )}
          </p>
          <SliderCompanies className="block-our-coaches__slider" list={"companies"} />
        </div>
        <div className="block-our-coaches__coaches">
          <LandingSlider<UserPublishDataWithRating> grayControls list={statisticReview} itemPrinter={itemPrinter} />
        </div>
      </div>
    </div>
  );
}
