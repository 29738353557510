import { useEffect, useMemo, useRef, useState } from "react";
import { isEqual, uniqBy } from "lodash";
import { useMount, useUnmount } from "react-use";
import { WebRTCConnection } from "@scrile/api-provider/dist/api/WebRTCProvider";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { parseISO } from "@scrile/tools/dist/lib/TimeHelpers";
import useLiveChat from "../pages/PageLiveChat/hooks/useLiveChat";
import { MediaConstraints } from "../types";
import useWebRTC from "./useWebRTC";
import providers from "../lib/providers";

function useProduceController(userId: string, livechatSubject: Subject<LivechatSubjectType>) {
  const { loading, joinData, token, userList, clientServerTimeDiff, endChat } = useLiveChat({
    userId,
    livechatSubject,
    connectionDelay: 3000,
  });

  const WebRTC = useWebRTC();
  const [stream, setStream] = useState<MediaStream>(new MediaStream([]));
  const connection = useRef<WebRTCConnection>();
  const [connecting, setConnecting] = useState(true);
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([]);
  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>([]);
  const [mediaConstraints, setMediaConstraints] = useState<MediaConstraints>({
    video: true,
    audio: true,
    videoEnabled: true,
    audioEnabled: true,
  });

  const streamStartedDate = useMemo(() => {
    if (!joinData) {
      return "";
    }
    return new Date(parseISO(joinData.me.joinTime).getTime() + clientServerTimeDiff).toISOString();
  }, [clientServerTimeDiff, joinData]);

  const setDevicesList = async () => {
    const { audioInputList, videoInputList } = await WebRTC.getDeviceList();
    setAudioInputs(uniqBy(audioInputList.reverse(), "groupId").reverse());
    setVideoInputs(videoInputList);
  };

  const closeStream = () => {
    WebRTC.closeAllStreams();
    if (stream.getTracks().length > 0) {
      stream.getTracks().forEach((t) => t.stop());
    }
  };

  const createLocalStream = async () => {
    setStream(await WebRTC.getStream({ audio: true, video: true }));
  };

  const produceStream = async () => {
    try {
      setConnecting(true);
      connection.current = await providers.LivechatsProvider.broadcast({ token });
      setDevicesList();
      setStream(
        await WebRTC.produceStream(connection.current, { video: mediaConstraints.video, audio: mediaConstraints.audio })
      );
    } finally {
      setConnecting(false);
    }
  };

  const onChangeConstraints = async (constraints: MediaConstraints) => {
    const localConstraints = {
      ...mediaConstraints,
      ...constraints,
    };
    if (!isEqual(mediaConstraints.audio, localConstraints.audio)) {
      stream.getAudioTracks().forEach((t) => {
        t.stop();
        stream.removeTrack(t);
      });
    }
    setMediaConstraints(localConstraints);
    if (!connection.current) return;

    const data = {
      audio: localConstraints.audioEnabled ? localConstraints.audio : false,
      video: localConstraints.videoEnabled ? localConstraints.video : false,
    };
    setStream(await WebRTC.produceStream(connection.current, data));
  };

  const shouldCallProduceStream = useRef(true);
  useEffect(() => {
    if (!joinData || !shouldCallProduceStream.current) return;

    shouldCallProduceStream.current = false;
    produceStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinData, shouldCallProduceStream.current]);

  useMount(() => createLocalStream());
  useUnmount(closeStream);

  return {
    stream,
    connecting,
    loading,
    userList,
    streamStartedDate,
    threadId: joinData?.threadId,
    token,
    mediaConstraints,
    audioInputs,
    videoInputs,
    onChangeConstraints,
    closeStream,
    endChat,
    users: joinData?.users ?? [],
  };
}

export default useProduceController;
