import React from "react";
import { useTranslation } from "../../../../locales";
import "./style.scss";

interface Props {
  className?: string;
}

export default function DotFlashingAnimation({ className }: Props) {
  const { t } = useTranslation();
  const cls = ["dot-flashing"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      <div className="dot-flashing__container">
        <div className="dot-flashing__text body-big fw-normal mb-5">{t("Waiting for participants")}</div>
        <div className="dot-flashing__animation">
          <div className="dot-flashing__dot __left" />
          <div className="dot-flashing__dot __center" />
          <div className="dot-flashing__dot __right" />
        </div>
      </div>
    </div>
  );
}
