import { useState } from "react";
import { useMount } from "react-use";
import { UserVerification } from "@scrile/api-provider/dist/api/UserVerificationProvider";
import { getFileMimeType } from "@scrile/tools/dist/lib/FileHelpers";
import useAuthUser from "../../../../hooks/useAuthUser";
import useMounted from "../../../../hooks/useMounted";
import providers from "../../../../lib/providers";
import fileUploader from "../../../../lib/fileUploader";

export default function useController() {
  const isMounted = useMounted();
  const { user } = useAuthUser();

  const [status, setStatus] = useState<UserVerification | null>(null);

  const getData = async () => {
    if (!user) return;
    const response = await providers.UserVerificationProvider.search({ data: { userIds: [user.id] } });
    isMounted.current && setStatus(response.result.find((i) => i.userId === user.id) ?? null);
  };

  useMount(() => {
    (async function () {
      await getData();
    })();
  });

  const removeFile = async (type: string = "user_cv") => {
    await providers.FileProvider.delete({
      data: {
        subjectId: user?.id ?? "",
        type,
      },
    });
    isMounted.current && setStatus((s) => (s ? { ...s, userCV: null } : null));
  };

  const onSendFile = async (files: FileList | null, type = "user_cv") => {
    if (!files) {
      await removeFile(type);
      return;
    }
    if (!user || files.length === 0) return;
    const file = files[0];
    const uploadData = await providers.FileProvider.getUploadUrl({ fileIds: [{ subjectId: user.id, type }] });
    if (uploadData.length === 0) return;
    const { fileUpload, fileId } = uploadData[0];
    fileUploader.addToQueue([
      {
        file,
        fileType: getFileMimeType(file),
        urlPart: fileUpload.urlPart,
        id: `${fileId.subjectId}_${fileUpload.uuid}`,
        onSuccess: async () => {
          await providers.FileProvider.update({
            data: {
              fileId,
              uuid: fileUpload.uuid,
            },
          });
          await getData();
        },
      },
    ]);
  };

  return {
    onSendFile,
    removeFile,
    userCV: status?.userCV,
  };
}
