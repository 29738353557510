import React, { createContext, Reducer, useContext, useMemo, useReducer } from "react";
import { InputUserPublicDataSearch, UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import providers from "../lib/providers";
import config from "../config";

export interface DomainState {
  user: UserPublicData | null;
  isLoading: boolean;
}

const defaultState: DomainState = {
  user: null,
  isLoading: true,
};

type Action =
  | {
      type: "setUser";
      payload: UserPublicData | null;
    }
  | {
      type: "setLoading";
      payload: boolean;
    };

const reducer: Reducer<DomainState, Action> = (state, action) => {
  switch (action.type) {
    case "setUser":
      return { ...state, user: action.payload };
    case "setLoading":
      return { ...state, isLoading: action.payload };
  }
};

const CustomDomainContext = createContext<[DomainState, React.Dispatch<Action>]>([
  defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export function CustomDomainContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(reducer, {
    user: null,
    isLoading: true,
  });

  return React.createElement(CustomDomainContext.Provider, { value: [state, dispatch] }, props.children);
}

export default function UseCustomDomain() {
  const [{ user, isLoading }, dispatch] = useContext(CustomDomainContext);

  const isCustomDomain = user !== null;

  const loadData = useMemo(() => async () => {
    try {
      if (!config.featureCustomDomain) return;
      const searchInputData: InputUserPublicDataSearch = { domain: window.location.host };
      const response = await providers.UserPublicDataProvider.search({ data: searchInputData });
      dispatch({
        type: "setUser",
        payload: response?.result.find((i) => i.domain === window.location.host) || null,
      });
    } finally {
      dispatch({ type: "setLoading", payload: false });
    }
  }, [dispatch])

  return { user, isCustomDomain, isLoading, loadData };
}
