import { InputReviewCreate } from "@scrile/api-provider/dist/api/ReviewProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import providers from "../../lib/providers";

export default function useController(recipientUser: UserPublicData) {
  const publishReview = async (comment: string, rating: number) => {
    const data: InputReviewCreate = {
      subject: {
        subjectId: recipientUser.id,
        subjectType: "user",
      },
      rating,
      comment: comment.trim(),
    };
    return await providers.ReviewProvider.create({ data });
  };

  return {
    publishReview,
  };
}
