import { useState } from "react";
import { useMount } from "react-use";
import { PayoutSystemSettings } from "@scrile/api-provider/dist/api/PayoutSystemSettingsProvider";
import { InputPayeeInformation, PayeeInformation } from "@scrile/api-provider/dist/api/PayeeInformationProvider";
import useAuthUser from "../../../../hooks/useAuthUser";
import providers from "../../../../lib/providers";
import { FormValues } from "../../../../types";
import { PayoutSystemType } from "./config";

export default function useController() {
  const [loading, setLoading] = useState(true);
  const [configuredPayoutSystem, setConfiguredPayoutSystem] = useState<PayoutSystemSettings[]>([]);
  const [currentPayeeInfo, setCurrentPayeeInfo] = useState<PayeeInformation | null>(null);
  const { user } = useAuthUser();

  const getCurrentPayeeInfo = async () => {
    if (!user) return;
    setCurrentPayeeInfo(await providers.PayeeInformationProvider.find({ id: user.id }));
  };

  const getConfigurePayoutSystems = async () => {
    const result = await providers.PayoutSystemSettingsProvider.findAll();
    setConfiguredPayoutSystem(result);
  };

  useMount(() => {
    Promise.all([getConfigurePayoutSystems(), getCurrentPayeeInfo()]).finally(() => setLoading(false));
  });

  const sendData = async (values: FormValues) => {
    const data: InputPayeeInformation = {
      userId: user?.id ?? "",
      address: values.address ?? "",
      city: values.city ?? "",
      country: values.country ?? "",
      currency: values.currency ?? "",
      firstName: values.firstName ?? "",
      lastName: values.lastName ?? "",
      payoutSystem: values.payoutSystem ?? "",
      phone: values.phone ?? "",
      state: values.state ?? "",
      zip: values.zip ?? "",
      ...(values.payoutSystem === PayoutSystemType.PAYPAL
        ? {
            paypal: {
              account: values.paypalAccount || "",
            },
          }
        : {}),
      ...(values.payoutSystem === PayoutSystemType.OTHER
        ? {
            other: {
              details: values.otherDetails || "",
            },
          }
        : {}),
    };
    await providers.PayeeInformationProvider.update({ data });
  };

  return {
    sendData,
    loading,
    currentPayeeInfo,
    configuredPayoutSystem,
  };
}
