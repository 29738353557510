import React from "react";
import { useTranslation } from "../../../../locales";
import photoSrc from "../../../../assets/about-us/hi-logo.png";
import photoSrc__x2 from "../../../../assets/about-us/hi-logo-2x.png";
import FullWidthPageBackground from "../../../../components/FullWidthPageBackground";
import "./style.scss";

export default function BlockAboutUsFooter() {
  const { t } = useTranslation();

  return (
    <div className="block-about-us-footer d-flex">
      <div className="block-about-us-footer__content">
        <div className="block-about-us-footer__hi-logo d-flex __align-center">
          <img
            className="block-about-us-footer__hi-logo-img"
            src={photoSrc}
            alt="Wasim Tahir"
            srcSet={photoSrc__x2 + " 2x"}
          />
          <div className="block-about-us-footer__hi-logo-text fw-bold ml-3">{t("Harvard Innovation Labs")}</div>
        </div>
        <div className="block-about-us-footer__text">
          {t(
            "We are proud to announce that SecureTheOffer has been accepted into Harvard Innovation Labs, an endorsement of our innovative approach to interview coaching and dedication to excellence in the financial services sector."
          )}
        </div>
      </div>
      <FullWidthPageBackground className="block-about-us-footer__background" />
    </div>
  );
}
