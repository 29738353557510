import { useState } from "react";
import { t } from "../../../../locales";

interface CoachingService {
  title: string;
  content: string;
}

export default function useViewController() {
  const [isActiveIndex, setIsActiveIndex] = useState<number | undefined>(0);

  const coachingServiceItems: Array<CoachingService> = [
    {
      title: t("CV & Cover Letter"),
      content: t(
        "Our coaches will review your CV and cover letter to ensure it is suitable in both form and content. They will provide direct edits and mark-ups on your documents as well as verbal feedback during your live session."
      ),
    },
    {
      title: t("Behavioural & Fit"),
      content: t(
        "Behavioural and fit questions are used to test your motivation, to test your insight into the company and the role, and to test a range of competencies. Our coaches will help you craft and refine best-in-class answers."
      ),
    },
    {
      title: t("Technical Guidance"),
      content: t(
        "From simple discounted cash flow (DCF) through to complex options pricing, testing technical competency is standard in financial services interviews. Our coaches will guide you to the best resources, help clarify and simplify concepts and review practice work."
      ),
    },
    {
      title: t("Industry Insight"),
      content: t(
        "Our coaches will impart their knowledge on relevant financial market trends, on recent regulatory developments, on the activities your target role entails, and more. This is the type of contextual information you will need to succeed."
      ),
    },
    {
      title: t("Case Interview"),
      content: t(
        "Often interviews will include consulting style exercises, from simple market sizing questions through to multi-part case studies. Our coaches will help you with case fundamentals – frameworks, structuring, estimation – so you can ‘crack the case’."
      ),
    },
  ];

  const onClickCoachingService = (index: number) => {
    setIsActiveIndex((oldIndex) => {
      if (oldIndex === index) {
        return undefined;
      }
      return index;
    });
  };

  return {
    coachingServiceItems,
    isActiveIndex,
    onClickCoachingService,
  };
}
