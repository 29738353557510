import React from "react";
import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { getDate } from "../../../../../lib/timeHelper";
import { printCredits } from "../../../../../lib/moneyHelper";
import "./style.scss";

interface Props {
  transaction: Transaction;
  description?: JSX.Element;
  type?: string;
}

function Index({ transaction, description, type }: Props) {
  const date = getDate(transaction.time);

  return (
    <div className="transaction-item">
      <h3 className="transaction-item__heading fw-semibold">{type}</h3>
      <p className="transaction-item__amount fw-semibold">{printCredits(transaction.amount, true)}</p>
      <div className="fw-semibold transaction-item__description">{description}</div>
      <div className="transaction-item__duration" />
      <div className="transaction-item__date">
        <span>{`${date.dayOfWeek} ${date.dayOfMonth},`}</span>
        <span className="transaction-item__date-month">{`${date.month} ${date.year}`}</span>
      </div>
    </div>
  );
}

export default Index;
