import React, { useRef } from "react";
import { TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import { TagCategoryWithTags } from "../../../../hooks/useCategories";
import CheckboxInput from "../../../../components/CheckboxInput";
import BaseIcon from "../../../../components/BaseIcon";
import { useTranslation } from "../../../../locales";
import useViewController from "./viewController";
import BaseButton from "../../../../components/BaseButton";
import FilterList from "../FilterList";
import "./style.scss";

export interface Props {
  categories: TagCategoryWithTags[];
  tags: TagInfo[];
  values: TagInfo[];
  onInput: (values: TagInfo[]) => void;
  filterPlaceholder?: string;
  className?: string;
}

export default function BaseFilterContainer({
  categories,
  tags,
  values,
  onInput,
  filterPlaceholder,
  className,
}: Props) {
  const { t } = useTranslation();
  const {
    itemsToPrint,
    filter,
    mobileHide,
    categoriesHide,
    activeIndex,
    onToggleCategory,
    onFilter,
    onChangeHandler,
    onApplyFilters,
    onClearFilterTags,
  } = useViewController({
    categories,
    tags,
    values,
    onInput,
  });
  const { desktopUp } = useBreakPoints();

  const inputRef = useRef<HTMLInputElement>(null);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const onClear = () => {
    onFilter("");
    inputRef.current && inputRef.current.focus();
  };

  const cls = ["base-filter-container"];
  if (mobileHide) cls.push("__hide");
  if (className) cls.push(className);

  if (categories.length === 0) return null;

  return (
    <div className={cls.join(" ")}>
      <div className="base-filter-container__title-wrap mb-4">
        <div className="base-filter-container__search-wrap">
          <BaseIcon className="base-filter-container__search-icon">search</BaseIcon>
          <input
            ref={inputRef}
            className="base-filter-container__search-input"
            type="text"
            value={filter}
            onChange={(e) => onFilter(e.target.value)}
            onKeyDown={onKeyDown}
            placeholder={filterPlaceholder}
          />
          <BaseIcon className="base-filter-container__search-clear-icon" onClick={onClear}>
            close
          </BaseIcon>
        </div>
      </div>
      <div className="base-filter-container__list-wrap">
        <div className="base-filter-container__block-items">
          {itemsToPrint.map((item, index) => (
            <div key={index}>
              <BaseButton
                className="base-filter-container__block-item"
                large={desktopUp}
                borderless={desktopUp}
                opacity={!desktopUp}
                fluid={!desktopUp}
                onClick={() => onToggleCategory(index, desktopUp)}
              >
                <div className="d-flex __space-between f-grow">
                  <span>{item.title}</span>
                  {!desktopUp && (
                    <BaseIcon className="ml-2">{categoriesHide[index] ? "fas-angle-down" : "fas-angle-up"}</BaseIcon>
                  )}
                </div>
              </BaseButton>
              {!desktopUp && !!itemsToPrint[index].items.length && (
                <div className={`base-filter-container__item-list py-2 pl-7${!categoriesHide[index] ? " __hide" : ""}`}>
                  {itemsToPrint[index].items.map((i) => (
                    <CheckboxInput
                      className="base-filter-container__checkbox"
                      name={i.id}
                      value={i.value}
                      onChange={(v) => onChangeHandler(i.id, v, itemsToPrint[index].type, true)}
                      key={i.id}
                    >
                      {i.title}
                    </CheckboxInput>
                  ))}
                </div>
              )}
            </div>
          ))}
          {!desktopUp && itemsToPrint.length === 0 && <span>{t("No matching result found")}</span>}
          {desktopUp && (
            <FilterList show={activeIndex >= 0}>
              {activeIndex >= 0 &&
                itemsToPrint[activeIndex].items.map((i) => (
                  <CheckboxInput
                    className="base-filter-container__checkbox"
                    name={i.id}
                    value={i.value}
                    onChange={(v) => onChangeHandler(i.id, v, itemsToPrint[activeIndex].type)}
                    key={i.id}
                  >
                    {i.title}
                  </CheckboxInput>
                ))}
              <div className="d-flex __space-between mt-3">
                <BaseButton className={"base-filter-container__clear"} outline onClick={() => onClearFilterTags()}>
                  {t("Clear")}
                </BaseButton>
                <BaseButton className={"base-filter-container__apply"} onClick={() => onApplyFilters()}>{t("Apply")}</BaseButton>
              </div>
            </FilterList>
          )}
        </div>
      </div>
    </div>
  );
}
