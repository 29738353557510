import { useState } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { useHistory, useRouteMatch } from "react-router-dom";
import useAuthUser from "../../hooks/useAuthUser";
import useBreakPoints from "../../hooks/useBreakPoints";
import { useHideFooter } from "../../hooks/useHideFooter";

function useController() {
  const { user } = useAuthUser();
  const history = useHistory();
  const match = useRouteMatch();
  const { desktopUp } = useBreakPoints();
  useHideFooter();

  const [senderUser, setSenderUser] = useState<UserPublicData | null>(null);

  const onClickBackButton = () => {
    history.push("/messages");
  };

  return {
    senderUser,
    setSenderUser,
    desktopUp,
    user,
    onClickBackButton,
    match,
    isSelectThread: history.location.pathname !== match.url,
  };
}

export default useController;
