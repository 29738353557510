import useAuthUser from "../../hooks/useAuthUser";
import providers from "../../lib/providers";
import { useCallback, useEffect, useState } from "react";
import { TransactionsSearchResult } from "@scrile/api-provider/dist/api/TransactionProvider";

export default function useController() {
  const { user } = useAuthUser();
  const [state, setState] = useState<TransactionsSearchResult | null>(null);
  const [loading, setLoading] = useState(false);

  const getData = async (page = 0, replace = true) => {
    const response = await providers.TransactionProvider.find({
      data: {
        userId: user?.id,
        page,
        size: 20,
        sort: "TIME,DESC",
      },
    });
    setState((s) => {
      if (!s) return response;
      return {
        ...response,
        ...(!replace ? { result: [...s.result, ...response.result] } : {}),
      };
    });
  };

  const loadNext = useCallback(
    (cb: Function) => {
      if (!state || !state.hasNextPage) return;
      return getData(state.page + 1, false).finally(() => cb && cb());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    getData().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    enableScroll: state?.hasNextPage ?? !loading,
    transactions: state?.result ?? [],
    loading,
    loadNext,
  };
}
