import { useCallback } from "react";
import { Appointment } from "@scrile/api-provider/dist/api/AppointmentProvider";
import useAuthUser from "../../hooks/useAuthUser";
import { isPerformer } from "../../lib/usersHelper";
import providers from "../../lib/providers";

export default function useController() {
  const { user } = useAuthUser();
  const searchActiveAppointments = useCallback(async () => {
    const nowDate = new Date().toISOString();
    const response = await providers.AppointmentProvider.search({
      providerId: isPerformer(user) ? user?.id : undefined,
      consumerId: !isPerformer(user) ? user?.id : undefined,
      sort: "START_TIME,DESC",
      startTimeTo: nowDate,
      endTimeFrom: nowDate,
    });

    return response.result.length > 0 ? response.result[0] : null;
  }, [user]);

  const subscribeToAppointment = useCallback(async (cb: (appointment: Appointment) => void) => {
    return await providers.AppointmentProvider.subscribeToAppointment(cb);
  }, []);

  return {
    searchActiveAppointments,
    subscribeToAppointment,
    user,
  };
}