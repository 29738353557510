import { useMemo, useRef, useState } from "react";
import { useGetSet, useMount, useUnmount } from "react-use";
import {
  LiveChatJoinResponse,
  LivechatSubjectType,
  LiveChatUserBroadcastingEvent,
  LiveChatUserUpdateAction,
  LiveChatUserUpdateEvent,
} from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { parseISO } from "@scrile/tools/dist/lib/TimeHelpers";
import useMounted from "../../../hooks/useMounted";
import useOnAuthorised from "../../../hooks/useOnAuthorised";
import providers from "../../../lib/providers";

export interface ChangeStreamEvent {
  event: "change_stream";
  data: {
    audio?: MediaTrackConstraints | boolean;
    video?: MediaTrackConstraints | boolean;
  };
}

interface State {
  joinData: LiveChatJoinResponse | null;
  loading: boolean;
}

export type UserBroadcastingCB = (data: LiveChatUserBroadcastingEvent) => void;
export type UserListUpdatedCB = (data: LiveChatUserUpdateEvent) => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {};

export default function useLiveChat({
  userId,
  livechatSubject,
  connectionDelay = 0,
}: {
  userId: string;
  livechatSubject: Subject<LivechatSubjectType>;
  connectionDelay?: number;
}) {
  const keepAliveStart = useRef<boolean>(true);
  const mounted = useMounted();
  const [getState, setState] = useGetSet<State>({
    joinData: null,
    loading: false,
  });

  const temp = getState();
  const userList = useMemo(() => (temp.joinData?.users ?? []).filter((i) => i.userId !== userId), [userId, temp]);

  const [clientServerTimeDiff, setClientServerTimeDiffState] = useState(0);
  const setClientServerTimeDiff = (serverTime: Date) => {
    setClientServerTimeDiffState(new Date().getTime() - serverTime.getTime());
  };

  const updateUserList = (data: LiveChatUserUpdateEvent) => {
    setState((state) => {
      if (!state.joinData) {
        return state;
      }
      let users = state.joinData.users;
      if (data.action === LiveChatUserUpdateAction.DELETE) {
        users = state.joinData.users.filter((i) => i.id !== data.user.id);
      }
      if (data.action === LiveChatUserUpdateAction.UPDATE) {
        if (state.joinData.users.find((i) => data.user.id === i.id)) {
          users = state.joinData.users.map((i) => (i.id === data.user.id ? data.user : i));
        } else users = state.joinData.users.concat([data.user]);
      }
      return {
        ...state,
        joinData: {
          ...state.joinData,
          users,
        },
      };
    });
  };

  const endChat = async () => {
    const state = getState();
    if (!state.joinData) return;
    setState((prevState) => ({
      ...prevState,
      joinData: null,
    }));
    return providers.LivechatsProvider.end({ token: state.joinData.token });
  };

  const onUserBroadcastingCB = useRef<UserBroadcastingCB>(emptyFunc);
  const setOnUserBroadcastingCB = (cb: UserBroadcastingCB) => (onUserBroadcastingCB.current = cb);
  const userBroadcastingUnsubscribe = useRef(emptyFunc);
  const subscribeUserBroadcasting = async (token: string) => {
    if (!token) {
      throw new Error("Empty token");
    }
    const subscription = await providers.LivechatsProvider.subscribeUserBroadcasting(token, (data) =>
      onUserBroadcastingCB.current(data)
    );
    if (!mounted.current) return;
    userBroadcastingUnsubscribe.current = () => subscription.unsubscribe();
  };

  const onUserListUpdatedCB = useRef<UserListUpdatedCB>(emptyFunc);
  const setOnUserListUpdatedCB = (cb: UserListUpdatedCB) => (onUserListUpdatedCB.current = cb);
  const userListUpdateUnsubscribe = useRef(emptyFunc);
  const subscribeUserListUpdated = async (joinData: LiveChatJoinResponse) => {
    const token = joinData.token ?? "";
    const subscription = await providers.LivechatsProvider.subscribeUserUpdate(
      token,
      (data: LiveChatUserUpdateEvent) => {
        if (!mounted.current) return;
        onUserListUpdatedCB.current(data);
        updateUserList(data);
      }
    );
    if (!mounted.current) {
      return;
    }
    userListUpdateUnsubscribe.current = () => {
      subscription.unsubscribe();
    };
  };

  const keepAlive = async (token: string): Promise<void> => {
    await new Promise((r) => setTimeout(r, 1000));
    if (!keepAliveStart.current) return;
    await providers.LivechatsProvider.keepalive({ token });
    return keepAlive(token);
  };

  const onCreated = async () => {
    setState((s) => ({ ...s, loading: true }));
    await new Promise((r) => setTimeout(r, connectionDelay));
    const joinData = await providers.LivechatsProvider.join({
      subject: livechatSubject,
    });
    try {
      mounted.current && setState((s) => ({ ...s, joinData }));
      setClientServerTimeDiff(parseISO(joinData.serverTime));
      await subscribeUserListUpdated(joinData);
      await subscribeUserBroadcasting(joinData.token);
      keepAlive(joinData?.token ?? "");
    } finally {
      mounted.current && setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useMount(() => onCreated());
  useUnmount(() => {
    endChat();
    keepAliveStart.current = false;
    userListUpdateUnsubscribe.current();
    userBroadcastingUnsubscribe.current();
  });

  useOnAuthorised(async () => {
    await endChat();
    await onCreated();
  });

  return {
    loading: getState().loading,
    joinData: getState().joinData,
    token: getState().joinData?.token ?? "",
    userList,
    setOnUserListUpdatedCB,
    setOnUserBroadcastingCB,
    clientServerTimeDiff,
    endChat,
  };
}
