import React, { ChangeEvent, useRef } from "react";
import { useTranslation } from "../../locales";
import BasePhoto from "../BasePhoto";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import "./style.scss";

interface Props {
  name?: string;
  className?: string;
  photoClassName?: string;
  aspectRatio?: number;
  title?: string;
  src?: string;
  alt?: string;
  buttonText?: string;
  accept?: string[];
  duration?: string;
  showEmptyImage?: boolean;
  onInput: (files: FileList | null) => void;
}

function UploadMedia({
  className,
  photoClassName = "",
  aspectRatio = 1,
  title,
  src,
  alt,
  buttonText,
  accept,
  showEmptyImage = true,
  duration,
  onInput,
  name,
}: Props) {
  const { t } = useTranslation();
  const cls = ["upload-media"];
  if (className) cls.push(className);
  const inputRef = useRef<HTMLInputElement>(null);
  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onInput(e.target.files);
    e.target.value = "";
  };
  const acceptTypes = accept ? accept.join(", ") : "*/*";

  return (
    <div className={cls.join(" ")}>
      {(showEmptyImage || src) && (
        <div
          className={photoClassName.concat(" upload-media__wrapper mb-4")}
          style={{ paddingBottom: `${aspectRatio * 100}%` }}
        >
          <BasePhoto className="upload-media__image" src={src} alt={alt} title={title} />
          {src && <button className="upload-media__closer" onClick={() => onInput(null)} />}
          {duration && <span className="upload-media__duration small pa-1">{duration}</span>}
        </div>
      )}
      <BaseButton className="upload-media__button" outline={true} onClick={onButtonClick}>
        <BaseIcon className="upload-media__icon mr-1">circle-plus</BaseIcon>
        <span>{buttonText || t("Upload photo")}</span>
      </BaseButton>
      <input
        type="file"
        accept={acceptTypes}
        onChange={onChange}
        style={{ display: "none" }}
        name={name}
        ref={inputRef}
      />
    </div>
  );
}

export default UploadMedia;
