import React from "react";
import "./style.scss";

export default function PageError({ error }: { error: Error }) {
  return (
    <div className="error-page">
      <h1 className="error-page__header">{"Uh oh... that shouldn't have happened"}</h1>
      <div className="error-page__error-stack">{error.stack}</div>
    </div>
  );
}
