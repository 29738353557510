import React from "react";
import { Testimonial } from "@scrile/api-provider/dist/api/TestimonialsProvider";
import { t } from "../../../../locales";
import LandingSlider from "../LandingSlider";
import TestimonialItem from "./components/TestimonialItem";
import useViewController from "./viewController";
import "./style.scss";


export default function BlockTestimonials() {
  const itemPrinter = (item: Testimonial) => <TestimonialItem item={item} />;

  const { testimonials } = useViewController();
  if (!testimonials.length) {
      return null;
  }
  return (
    <div className="block-testimonials">
      <h2 className="block-testimonials__title">{t("Testimonials")}</h2>
      <div className="block-testimonials__container">
        <LandingSlider<Testimonial> list={testimonials} itemPrinter={itemPrinter} />
      </div>
    </div>
  );
}
