import React from "react";
import { TipMessageDetails } from "@scrile/api-provider/dist/api/TipProvider";
import { printCredits } from "../../../../../lib/moneyHelper";
import { useTranslation } from "../../../../../locales";
import { PropsMessageItem } from "../types";
import DefaultMessageView from "../DefaultMessageView";
import "./style.scss";

export default function MessageTipItem({
  message,
  className,
  rendered,
  avatarSize,
  showScreenName,
  startMessage,
  endMessage,
  isLiveChat,
  authUser,
}: PropsMessageItem) {
  const selfMessage = String((message.details as TipMessageDetails).senderId) === authUser?.id;
  const { t } = useTranslation();
  return (
    <DefaultMessageView
      message={message}
      className={className}
      rendered={rendered}
      avatarSize={avatarSize}
      showScreenName={showScreenName}
      startMessage={startMessage}
      endMessage={endMessage}
      isLiveChat={isLiveChat}
      selfMessage={selfMessage}
      highlightMessage={false}
      contentMessage={
        <div className={"message-tip-item__content " + (selfMessage ? "text-right" : "text-left")}>
          <div className="message-tip-item__text small mb-2">{t("Payment")}</div>
          <div className="message-tip-item__amount headline-2 fw-normal">
            {printCredits((message.details as TipMessageDetails).amount)}
          </div>
        </div>
      }
    />
  );
}
