import { useState } from "react";
import useController from "./controller";

export default function useViewController(onClose: () => void) {
  const [success, setSuccess] = useState(false);
  const { sendVerificationRequest, onCancel } = useController(onClose);

  const onNext = async () => {
    const response = await sendVerificationRequest();
    setSuccess(response);
  };

  return {
    onNext,
    onCancel,
    success,
  };
}
