import React from "react";
import BaseIcon from "../BaseIcon";
import BaseButton from "../BaseButton";
import {UserPublicData} from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import {useTranslation} from "../../locales";
import useController from "./controller";
import "./style.scss";

interface Props {
  user: UserPublicData;
  className?: string;
  withText?: boolean;
  favorite?: boolean;
}

export default function AddToFavorite ({ user, className, withText, favorite }: Props) {
  const { t } = useTranslation();
  const {isFavorite, isLoading, isYourself, loginLink, onFavoriteToggle} = useController({ user, favorite });

  const cls = ["add-to-favorite"];
  if (className) cls.push(className);
  if (isFavorite) cls.push("add-to-favorite__icon");

  return (
    <>
     {!isYourself && (
        <BaseButton
          icon={!withText}
          borderless
          disabled={isLoading}
          className={cls.join(' ')}
          to={loginLink}
          onClick={onFavoriteToggle}
        >
          <BaseIcon
            size={"24"}
          >
            {isFavorite ? "favorites_active" : "favorites"}
          </BaseIcon>
          {withText && (
            <span className="add-to-favorite__text">
              {isFavorite ? t("Remove from favorites") : t("Add to favorites")}
            </span>
          )}
        </BaseButton>
      )}
    </>
  )
}
