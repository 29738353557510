import { InputStripeDepositPackagePurchase } from "@scrile/api-provider/dist/api/StripePurchaseProvider";
import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { t } from "../../../../locales";
import providers from "../../../../lib/providers";

type RequestState = "success" | "canceled" | "error";
const open = (w: number, h: number) => {
  const left = window.screen.width / 2 - w / 2,
    top = window.screen.height / 2 - h / 2;
  const config = `width=${w}, height=${h}, top=${top}, left=${left}, toolbar=0, location=1, directories=0, status=0, menubar=0, scrollbars=1, resizable=0, copyhistory=0`;
  return window.open("about:blank", "_blank", config);
};

const waitRequestStatus = (getUrl: () => Promise<string>) =>
  new Promise<RequestState>(async (resolve, reject) => {
    if ((window as any).setStripeRequestStatus) {
      reject([new Error(t("Other payment in process"))]);
      return;
    }
    let requestStatus: "success" | "canceled";
    (window as any).setStripeRequestStatus = (status: "success" | "canceled") => {
      requestStatus = status;
    };
    const tab = open(980, 900);
    if (!tab) {
      delete (window as any).setStripeRequestStatus;
      reject([new Error(t("Cant create payment window"))]);
      return;
    }
    try {
      tab.location.href = await getUrl();
    } catch (e) {
      tab.close();
      reject(e);
    }
    const interval = setInterval(() => {
      if (tab.closed) {
        if (requestStatus === "success") {
          resolve("success");
        } else if (requestStatus === "canceled") {
          resolve("canceled");
        } else {
          resolve("error");
        }
        delete (window as any).setStripeRequestStatus;
        clearInterval(interval);
      }
    }, 500);
  });

const makePurchase = async (getUrl: () => Promise<string>) => {
  const requestState = await waitRequestStatus(getUrl);
  if (requestState === "canceled") {
    throw new Error(t("Payment canceled"));
  } else if (requestState === "error") {
    throw new Error(t("Cant verify transaction"));
  }
  return true;
};

export default function useController() {
  const sendSubmit = (data: InputStripeDepositPackagePurchase): Promise<Transaction> => {
    return new Promise(async (resolve, reject) => {
      try {
        let timeout: ReturnType<typeof setTimeout>;
        const subscription = await providers.PaymentSystemsProvider.subscribeDepositPurchase((t) => {
          timeout && clearTimeout(timeout);
          subscription.unsubscribe();
          resolve(t);
        });
        try {
          await makePurchase(() => providers.StripePurchaseProvider.getDepositUrl({ data }));
        } catch (e) {
          subscription.unsubscribe();
          reject(e);
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    sendSubmit,
  };
}
