import React, { useRef, useState } from "react";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";

interface Props {
  item: FAQItem;
  className: string;
}

export interface FAQItem {
  question: string;
  answer: string;
}

export default function FaqItem({ item, className }: Props) {
  const [active, setActive] = useState(false);
  const cls = ["faq-item"];
  cls.push(className);
  if (active) cls.push("__active");
  const ref = useRef<HTMLDivElement>(null);

  const onClick = () => {
    if (ref.current) {
      ref.current.style.height = (!active ? ref.current.scrollHeight : 0) + "px";
    }
    setActive(!active);
  };

  return (
    <div className={cls.join(" ")} onClick={onClick}>
      <div className="faq-item__title">
        <span className="faq-item__question pr-4">{item.question}</span>
        <div className="faq-item__question-icon-wrapper d-center">
          <BaseIcon className="faq-item__question-icon">close</BaseIcon>
        </div>
      </div>
      <div className="faq-item__text" ref={ref}>
        {item.answer}
      </div>
    </div>
  );
}
