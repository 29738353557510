import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { useTranslation } from "../../../../locales";
import { isPerformer } from "../../../../lib/usersHelper";
import { printCredits } from "../../../../lib/moneyHelper";
import useMessages from "../../../../hooks/useMessages";
import useAuthUser from "../../../../hooks/useAuthUser";
import BaseIcon from "../../../../components/BaseIcon";
import BaseButton from "../../../../components/BaseButton";
import BaseCard from "../../../../components/BaseCard";
import CalendlyButton from "../../../../components/CalendlyButton";
import CustomScrollBar from "../../../../components/CustomScrollBar";
import BaseSpinner from "../../../../components/BaseSpinner";
import "./style.scss";

interface Props {
  user: UserPublicData;
  appointments: AppointmentType[];
  loading: boolean;
  show: boolean;
  onClose: () => void;
}

function PerformerBookingWindow({ user, appointments, loading, show, onClose }: Props) {
  const { t } = useTranslation();
  const { getMessageUrl } = useMessages();
  const askLink = getMessageUrl(user);
  const authUser = useAuthUser();

  const cls = ["performer-book"];
  if (!show) cls.push("__hide");
  return (
    <div className={cls.join(" ")}>
      <div className="performer-book__backdrop" onClick={onClose} />
      <BaseCard className="performer-book__inner">
        <div className="performer-book__content">
          <h3 className="performer-book__heading body-big mb-4">{t("Services")}</h3>
          {loading ? (
            <BaseSpinner className="ma-auto" />
          ) : appointments.length > 0 ? (
            <CustomScrollBar
              style={{ maxHeight: 215 }}
              className={"performer-book__services" + (appointments.length > 3 ? " __bot-shadow" : "")}
            >
              {appointments.map((appointment) => {
                return (
                  <div className="performer-book__service-item divider-bottom" key={appointment.id}>
                    <h4 title={appointment.name} className="performer-book__service-item-heading body fw-semibold mr-6">
                      {appointment.name}
                    </h4>
                    <div className="performer-book__service-item-amount body">{printCredits(appointment.cost)}</div>
                    <span className="performer-book__service-item-duration label">
                      {appointment.duration} {t("min")}
                    </span>
                  </div>
                );
              })}
            </CustomScrollBar>
          ) : (
            <p className="body pb-5">
              {t(
                "The consultant has not provided the services yet, but you can get a consultation by asking a question."
              )}
            </p>
          )}
        </div>
        {!isPerformer(authUser.user) && user.calendlyScheduleUrl && appointments.length > 0 && (
          <CalendlyButton className="mt-8" fullwidth providerUser={user} scheduleUrl={user.calendlyScheduleUrl!} />
        )}
        {askLink && (
          <BaseButton to={askLink} outline={true} className="performer-book__ask-button mt-3">
            {t("Ask a question")}
          </BaseButton>
        )}
        <BaseButton className="performer-book__close" borderless={true} onClick={onClose}>
          <BaseIcon>close</BaseIcon>
        </BaseButton>
      </BaseCard>
    </div>
  );
}

export default PerformerBookingWindow;
