import React from "react";
import { useTranslation } from "../../../../locales";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";

export default function BlockAbout() {
  const { t } = useTranslation();

  return (
    <div className="block-about">
      <video className="block-about__video" src="/landing/landing-video.mp4" autoPlay muted controls />
      <div className="block-about__content">
        <h1 className="block-about__title">{t("Secure the offer from your target financial institution")}</h1>
        <div className="block-about__description fw-normal">
          {t(
            "We are the only coaching platform dedicated to financial services interviews. " +
              "You will find a curated roster of coaches from the most prestigious firms. " +
              "Our coaches are here to help you with your interview journey."
          )}
        </div>
        <div className="block-about__price-wrapper">
          <div className="block-about__price">{t("£175 per session")}</div>
          <BaseButton className="block-about__btn" to={"/coaches"} large>
            {t("Find a Coach")}
            <BaseIcon className="block-about__btn-icon ml-1">back</BaseIcon>
          </BaseButton>
        </div>
      </div>
    </div>
  );
}
