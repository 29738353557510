import { NavigationMenuItemInterface } from "../../../../types";
import useAuthUser from "../../../../hooks/useAuthUser";
import config from "../../../../config";

function useViewController() {
  const { user } = useAuthUser();

  const topNavigationMenu: NavigationMenuItemInterface[] = [
    {
      title: "About information",
      name: "about",
      to: "/user/settings",
    },
    {
      title: "Professional",
      name: "professional",
      to: "/user/settings/professional",
    },
    {
      title: "Academic",
      name: "academic",
      to: "/user/settings/academic",
    },
    {
      title: "Video",
      name: "video",
      to: "/user/settings/video",
    },
  ];

  return { topNavigationMenu, showNavMenu: user?.role === config.userPerformerRole };
}

export default useViewController;
