import React from "react";
import { useTranslation } from "../../../../locales";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";

export default function BlockFromFounders() {
  const { t } = useTranslation();

  return (
    <div className="block-from-founders">
      <div className="block-from-founders__block-message">
        <div className="block-from-founders__title">
          <BaseIcon className="block-from-founders__double-quotes">double-quotes</BaseIcon>
          <div className="block-from-founders__title-text">{t("A message from the founders")}</div>
        </div>
        <div className="block-from-founders__text">
          <p>
            {t(
              "Securing a role in the financial services sector is challenging and competitive. Recognizing this, we, Wasim and Jonny, co-founded SecureTheOffer to transform the way candidates prepare for interviews."
            )}
          </p>
          <br />
          <p>
            {t(
              "With a combined experience spanning over three decades in diverse roles across financial institutions, we have firsthand insights into the interview processes in private equity, investment banking, equity research, and more. Our careers have been marked by successful interviews and numerous job offers, reflecting our deep understanding of the industry."
            )}
          </p>
          <br />
          <p>
            {t(
              "For several years now, we have been sharing our interview insights and techniques by individually coaching hundreds of different candidates for their desired roles. We founded SecureTheOffer to expand this mentorship, sharing those insights and techniques, and those of our hand-picked coaches, with even more aspiring candidates."
            )}
          </p>
          <br />
          <p>{t("We at SecureTheOffer are here to partner with you through every step of your interview journey.")}</p>
        </div>
      </div>
    </div>
  );
}
