import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { formatMoney } from "@scrile/tools/dist/lib/MoneyHelpers";
import useController from "./controller";
import getIntegration from "../../integrations";

function useViewController(paymentPackage: PaymentPackage | null) {
  const { price, currentSystem, loading } = useController(paymentPackage);

  const amountParts = formatMoney(paymentPackage?.amount || 0);
  const Component = getIntegration(currentSystem);

  return {
    price,
    currentSystem,
    loading,
    amountParts,
    Component,
  };
}

export default useViewController;
