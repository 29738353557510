import config from "../config";

declare global {
  interface Window {
    gtag: (data: string, id: string, context?: Record<string, any>) => void;
  }
}

const isGtagScriptExistsInHead = (id: string): boolean => {
  const $scripts = document.head.querySelectorAll("script");
  return [...$scripts].some((item) => item.src.endsWith(id));
};

const createScriptWithId = (id: string): HTMLScriptElement[] => {
  if (isGtagScriptExistsInHead(id)) return [];
  const $scriptWithSrc = document.createElement("script");
  $scriptWithSrc.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  $scriptWithSrc.async = true;

  const $script = document.createElement("script");
  $script.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag("js", new Date());        
      gtag("config", "${id}");
    `;
  return [$scriptWithSrc, $script];
};

export const appendScriptsToBody = (): void => {
  if (!config.gtagIds[0]) return;
  const $script = document.createElement("script");
  $script.textContent = `
    document.addEventListener('click', function(t) {
      for (let r of t.path ?? []) {

        if (r.matches && r.matches('[href*="/login"]')) {
          gtag('event', 'conversion', {
            'send_to': '${config.gtagIds[0]}/YOCxCN38ndsDEImu5-Ao'
          });
        }

        if (r.matches && r.matches('[href*="/signup"]')) {
          gtag('event', 'conversion', {
            'send_to': '${config.gtagIds[0]}/F9cZCNf8ndsDEImu5-Ao'
          });
        }

        if (r.matches && r.matches('[href*="/coaches"]')) {
          gtag('event', 'conversion', {
            'send_to': '${config.gtagIds[0]}/oZ-ICNr8ndsDEImu5-Ao'
          });
        }

      }
    })`;
  document.body.appendChild($script);
};

export const appendGtagScriptToHead = (): void => {
  if (config.gtagIds.length > 0 && !window.gtag) {
    const $scriptList = config.gtagIds.map((id) => createScriptWithId(id));
    document.head.append(...$scriptList.flat(2));
  }
};
