import React from "react";
import filesize from "filesize";
import config from "../../config";
import "./style.scss";

interface Props {
  fileName: string;
  fileSize: number;
  uploadProgress?: number;
  className?: string;
}

export default function FileType({ fileName, fileSize, uploadProgress, className }: Props) {
  const fileNameParts = fileName.split(".");
  const extension = (fileNameParts.length > 1 ? fileNameParts[fileNameParts.length - 1] : "").toLocaleLowerCase();
  const typeCls = ["file-type__type mr-1"];
  if (extension === "doc" || extension === "docx") {
    typeCls.push("__doc");
  } else if (config.fileTypes.image.includes(extension)) {
    typeCls.push("__image");
  } else if (config.fileTypes.video.includes(extension)) {
    typeCls.push("__video");
  } else if (config.fileTypes.audio.includes(extension)) {
    typeCls.push("__audio");
  } else if (config.fileTypes.document.includes(extension)) {
    typeCls.push("__document");
  }
  const size = filesize(fileSize);

  const cls = ["file-type"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      {extension && <div className={typeCls.join(" ")}>{extension}</div>}
      <div className="file-type__name mr-2" title={fileName}>
        {fileName}
      </div>
      <div className="file-type__size">{size}</div>
      {uploadProgress !== undefined && uploadProgress !== 100 && (
        <div className="file-type__progress ml-2" style={{ backgroundSize: `${uploadProgress}% 100%` }} />
      )}
    </div>
  );
}
