import { useState } from "react";

export default function useViewController() {
  const [showTipModal, setShowTipModal] = useState(false);

  return {
    showTipModal,
    setShowTipModal,
  };
}
