import React, { createContext, Dispatch, Reducer, useContext, useMemo, useReducer } from "react";
import { TagCategory, TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";
import providers from "../lib/providers";

export interface CategoriesState {
  categories: TagCategoryWithTags[];
  tags: TagInfo[];
}

export interface TagCategoryWithTags extends TagCategory {
  tags: TagInfo[];
}

const defaultState: CategoriesState = {
  categories: [],
  tags: [],
};

type Action =
  | {
      type: "setCategories";
      payload: TagCategoryWithTags[];
    }
  | {
      type: "setTags";
      payload: TagInfo[];
    };

const reducer: Reducer<CategoriesState, Action> = (state, action) => {
  switch (action.type) {
    case "setCategories":
      return { ...state, categories: action.payload };
    case "setTags":
      return { ...state, tags: action.payload };
  }
};

const CategoriesContext = createContext<[CategoriesState, Dispatch<Action>]>([defaultState, () => {}]);

export function CategoriesContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(reducer, {
    categories: [],
    tags: [],
  });

  return React.createElement(CategoriesContext.Provider, { value: [state, dispatch] }, props.children);
}

export default function useCategories() {
  const [{ categories, tags }, dispatch] = useContext(CategoriesContext);

  const loadData = useMemo(() => async () => {
    const tags = await providers.TagsProvider.findAll();
    dispatch({
      type: "setTags",
      payload: tags || []
    })

    const categories = await providers.TagsProvider.findCategories();
    const categoriesWithTags = [];
    for (const category of categories) {
      categoriesWithTags.push({
        ...category,
        tags: tags.filter(tag => tag.categoryId === category.id)
      })
    }
    dispatch({
      type: "setCategories",
      payload: categoriesWithTags,
    })
  }, [dispatch]);

  return { categories, tags, loadData };
}
