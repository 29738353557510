import { useEffect, useState } from "react";
import useAuthUser from "../../hooks/useAuthUser";
import { FieldInterface, FormValues } from "../../types";
import { MediaFilesController } from "../../lib/MediaFileController";
import { parseErrors } from "../../lib/requestHelpers";
import { useTranslation } from "../../locales";
import useController from "./controller";
import { questionnaireFields } from "./questionnaireConfig";
import config from "../../config";
import { TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";

function useViewController() {
  const { user } = useAuthUser();
  const { t } = useTranslation();

  const initialState: FormValues = {
    screenName: user?.screenName,
    email: user?.email,

    about: "",
    website: "",
  };

  let fields: FieldInterface[] = [
    {
      name: "screenName",
      type: "text",
      label: t("Name"),
      validation: {
        required: true,
      },
      largeText: true,
    },
    {
      name: "email",
      type: "email",
      label: t("Email"),
      validation: {
        required: true,
      },
      disabled: true,
      iconLeft: "mail",
    },
  ];

  if (user?.role === config.userPerformerRole) {
    const filtered = questionnaireFields
      .filter((item) => item.role.includes(user?.role || ""))
      .map((item) => item.field);
    fields = fields.concat(filtered);
  }

  const src = (user?.avatar && MediaFilesController.getImageThumbnail(user.avatar.urlPart, "720p")) || "";
  const [values, setValues] = useState<FormValues>(initialState);
  const [userTags, setUserTags] = useState<TagInfo[]>([]);
  const [allTags, setAllTags] = useState<TagInfo[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loadingForm, setLoadingForm] = useState(false);
  const onChange = (values: FormValues) => setValues(values);

  const { onSaveFields, onUploadAvatar, onDeleteAvatar, getUserQuestionnaire, getAllTags } = useController();
  useEffect(() => {
    (async function () {
      if (user?.role !== config.userPerformerRole) return;
      const questionnaire = await getUserQuestionnaire();
      const tags = await getAllTags();
      setAllTags(tags);
      setValues((prevState) => {
        return {
          ...prevState,
          about: questionnaire?.fields.about || "",
          website: questionnaire?.fields.website || "",
          tags: questionnaire?.fields.tags || [],
        };
      });
      setUserTags(questionnaire.tags ?? []);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onInputAvatar = async (files: FileList | null) => {
    const file = files && files[0];
    if (file) {
      await onUploadAvatar(file);
    } else if (user?.avatar) {
      await onDeleteAvatar();
    }
  };

  const onSave = async () => {
    try {
      setLoadingForm(true);
      await onSaveFields(fields, values, userTags);
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setLoadingForm(false);
    }
  };

  const onAddingTag = (tag: TagInfo) => {
    setUserTags((prevState) => {
      return prevState.concat(tag);
    });
  };

  const onDeleteTag = (index: number) => {
    setUserTags((prevState) => {
      return prevState.filter((i, iIndex) => iIndex !== index);
    });
  };

  return {
    fields,
    values,
    onChange,
    onSave,
    errors,
    setErrors,
    onInputAvatar,
    src,
    loadingForm,
    userTags,
    allTags,
    onAddingTag,
    onDeleteTag,
    role: user?.role
  };
}

export default useViewController;
