type RequireContext = ReturnType<typeof require.context>;

interface Module<T, C> {
  default: C;
  typeGuard: (item: T) => boolean;
}

export function getContextModules(context: RequireContext) {
  const out: any = [];

  context.keys().forEach((key) => {
    out.push(context(key));
  });
  return out;
}

export function getComponent<T, C>(modules: Module<T, C>[], item: T, defaultComponent: C): C {
  const module = modules.find((m) => m.typeGuard(item)) || { default: defaultComponent };
  return module.default;
}
