import React from "react";
import { isEqual } from "lodash";
import { BaseSelectField, FormValue, OptionItem } from "../../types";
import BaseIcon from "../BaseIcon";
import "./style.scss";

interface Props extends Omit<BaseSelectField, "type"> {
  value: FormValue;
  onChange: (value: FormValue) => void;
}

function BaseSelect({
  name,
  label,
  placeholder,
  options,
  onChange,
  value,
  autofocus,
  disabled,
  className,
  style,
  errorMessage,
  labelClassName,
}: Props) {
  const cls = ["base-select"];
  const isError: boolean = (errorMessage && errorMessage.trim() !== "") || false;

  if (disabled) cls.push("__disabled");
  if (className) cls.push(className);
  if (isError) cls.push("__error");

  const currentIndex = options.findIndex((o) => isEqual(o.value, value));
  const currentText = currentIndex !== -1 ? options[currentIndex].text : placeholder;

  const onChangeHandler = (index: number) => {
    const selected: OptionItem = options[index];
    if (selected) onChange(selected.value);
  };

  return (
    <div className={cls.join(" ")} style={style}>
      {label && <label className={"base-select__label " + (labelClassName ? labelClassName : "")}>{label}</label>}
      <div className="base-select__wrapper pl-5 pr-4">
        <div className={"base-select__text body" + (currentIndex === -1 ? " __disabled" : "")}>{currentText}</div>
        <div className="base-select__wrapper-icon">
          <BaseIcon className="base-select__icon" size={12.5}>
            fas-angle-down
          </BaseIcon>
        </div>
        <select
          name={name}
          className="base-select__select"
          onChange={(e) => onChangeHandler(+e.target.value)}
          value={currentIndex}
          disabled={disabled}
          autoFocus={autofocus}
        >
          {placeholder && (
            <option value="-1" disabled>
              {placeholder}
            </option>
          )}
          {options.map((elem, i) => (
            <option key={elem.text} value={i} disabled={elem.disabled}>
              {elem.text}
            </option>
          ))}
        </select>
      </div>
      {isError && <div className={"base-input__hint __error"}>{errorMessage}</div>}
    </div>
  );
}

export default BaseSelect;
