import React, { useRef } from "react";
import { BaseMenuItemInterface } from "../../../../types";
import { useTranslation } from "../../../../locales";
import BaseButton from "../../../BaseButton";
import BaseIcon from "../../../BaseIcon";
import BaseInputFile from "../../../BaseInputFile";
import BaseMenu from "../../../BaseMenu";
import "./style.scss";

interface Props {
  className?: string;
  onInput: (files: File[]) => void;
}

function MessageAttachButton({ className, onInput }: Props) {
  const clickImageRef = useRef<HTMLInputElement | null>(null);
  const clickVideoRef = useRef<HTMLInputElement | null>(null);
  const clickAudioRef = useRef<HTMLInputElement | null>(null);
  const clickDocumentRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const cls = [];
  if (className) cls.push(className);

  const itemsList: BaseMenuItemInterface[] = [
    {
      name: "image",
      icon: "image",
      title: t("Image"),
    },
    {
      name: "video",
      icon: "camera",
      title: t("Video"),
    },
    {
      name: "audio",
      icon: "audio",
      title: t("Audio"),
    },
    {
      name: "document",
      icon: "document",
      title: t("Document"),
    },
  ];

  const onClickItem = (itemName: string) => {
    if (itemName === "image" && clickImageRef.current) {
      return clickImageRef.current.click();
    }
    if (itemName === "video" && clickVideoRef.current) {
      return clickVideoRef.current.click();
    }
    if (itemName === "audio" && clickAudioRef.current) {
      return clickAudioRef.current.click();
    }
    if (itemName === "document" && clickDocumentRef.current) {
      return clickDocumentRef.current.click();
    }
  };

  return (
    <div className={cls.join(" ")}>
      <BaseMenu className="message-attach-btn__menu" itemsList={itemsList} onClick={onClickItem} spaced={true}>
        <BaseButton icon={true} borderless name="attach-file">
          <BaseIcon>link</BaseIcon>
        </BaseButton>
      </BaseMenu>
      <BaseInputFile name={"image"} accept="image" onInput={onInput} inputRef={clickImageRef} />
      <BaseInputFile name={"video"} accept="video" onInput={onInput} inputRef={clickVideoRef} />
      <BaseInputFile name={"audio"} accept="audio" onInput={onInput} inputRef={clickAudioRef} />
      <BaseInputFile name={"document"} accept="document" onInput={onInput} inputRef={clickDocumentRef} />
    </div>
  );
}

export default MessageAttachButton;
