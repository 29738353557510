import React, { useRef } from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { useTranslation } from "../../../../../../locales";
import BaseIcon from "../../../../../BaseIcon";
import BaseButton from "../../../../../BaseButton";
import InfiniteScroll from "../../../../../InfiniteScroll";
import CustomScrollBar from "../../../../../CustomScrollBar";
import UserListItem from "../UserListItem";
import useController from "./controller";
import "./style.scss";

interface Props {
  className?: string;
  userList: LiveChatUser[];
  onClose?: () => void;
}

const UserList = ({ className, userList, onClose }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const cls = ["users-list"];
  if (className) cls.push(className);

  const { guestCount, users } = useController(userList);

  return (
    <div className={cls.join(" ")}>
      <div className="users-list__header">
        <h2 className="body fw-semibold">{t("Member list")}</h2>

        <span className="users-list__guests small">({t("guests {{count}}", { count: guestCount })})</span>
        <BaseButton className="users-list__close" icon={true} borderless={true} onClick={onClose}>
          <BaseIcon>close</BaseIcon>
        </BaseButton>
      </div>
      <CustomScrollBar className="users-list__list" containerRef={containerRef}>
        <InfiniteScroll enable={true} scrollableWrapperRef={containerRef} className="users-list__list-container">
          {users?.map((u: LiveChatUser, index: number) => u.user && <UserListItem key={index} user={u.user} />)}
        </InfiniteScroll>
      </CustomScrollBar>
    </div>
  );
};

export default UserList;
