import React from "react";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";

interface Props {
  isActive: boolean;
  className?: string;
  onClickActivator: () => void;
  children: React.ReactNode;
  title: string;
}
export default function CoachingServiceItem({ isActive, className, children, title, onClickActivator }: Props) {
  const cls = ["coaching-service-item"];

  if (className) cls.push(className);
  if (isActive) cls.push("__active");

  return (
    <div className={cls.join(" ")}>
      <div onClick={onClickActivator} className="coaching-service-item__activator">
        <h3 className="coaching-service-item__title">{title}</h3>
        <BaseIcon className="coaching-service-item__activator-icon">back</BaseIcon>
      </div>
      <div className="coaching-service-item__content">{children}</div>
    </div>
  );
}
