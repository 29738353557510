import { Job } from "@scrile/api-provider/dist/api/QuestionaryInfoProvider";
import { useTranslation } from "../../../../locales";
import { FieldInterface, FormValues, OptionItem } from "../../../../types";
import useController from "./controller";
import { useCallback, useEffect, useState } from "react";
import { validateFields } from "../../../../lib/validation";

export default function useViewController({
  jobIndex,
  jobValues,
  onChange,
  setCheckValidation,
}: {
  jobIndex: number;
  jobValues?: Job;
  onChange: (job: Job) => void;
  setCheckValidation: (key: number, cb: () => boolean) => void;
}) {
  const { t } = useTranslation();
  const [isStillHere, setIsStillHere] = useState(jobValues?.to?.month === undefined);

  const monthOptions: OptionItem[] = [
    { text: t("January"), value: "0" },
    { text: t("February"), value: "1" },
    { text: t("March"), value: "2" },
    { text: t("April"), value: "3" },
    { text: t("May"), value: "4" },
    { text: t("June"), value: "5" },
    { text: t("July"), value: "6" },
    { text: t("August"), value: "7" },
    { text: t("September"), value: "8" },
    { text: t("October"), value: "9" },
    { text: t("November"), value: "10" },
    { text: t("December"), value: "11" },
  ];

  const yearOptions: OptionItem[] = (() => {
    const currentYear = new Date().getFullYear();
    return Array.from(new Array(100)).map((i, index) => ({
      text: String(currentYear - index),
      value: currentYear - index,
    }));
  })();

  const jobTemplateFields: FieldInterface[] = [
    {
      name: `title-${jobIndex}`,
      type: "text",
      className: "experience-view-field__job-title",
      label: t("Job title"),
      validation: {
        required: true,
      },
    },
    {
      name: `firm-${jobIndex}`,
      type: "text",
      label: t("Firm"),
      className: "experience-view-field__firm",
      validation: {
        required: true,
      },
    },
    {
      name: `location-${jobIndex}`,
      type: "text",
      label: t("Location"),
      className: "experience-view-field__location",
      validation: {
        required: true,
      },
    },
    {
      name: `fromMonth-${jobIndex}`,
      type: "select",
      label: t("From"),
      placeholder: t("Month"),
      className: "experience-view-field__from-month",
      options: monthOptions,
      validation: {
        required: true,
      },
    },
    {
      name: `fromYear-${jobIndex}`,
      type: "select",
      label: t("From"),
      placeholder: t("Year"),
      className: "experience-view-field__from-year",
      labelClassName: "experience-view-field__label",
      options: yearOptions,
      validation: {
        required: true,
      },
    },
    {
      name: `toMonth-${jobIndex}`,
      type: "select",
      label: t("To"),
      placeholder: t("Month"),
      className: "experience-view-field__to-month",
      options: [{ text: t("I still work here"), value: undefined }, ...monthOptions],
    },
  ];
  if (!isStillHere) {
    jobTemplateFields.push({
      name: `toYear-${jobIndex}`,
      type: "select",
      label: t("To"),
      placeholder: t("Year"),
      className: "experience-view-field__to-year",
      labelClassName: "experience-view-field__label",
      options: yearOptions,
      validation: {
        required: true,
      },
    });
  }

  const { fields, values, setValues, errors, setErrors } = useController(
    jobTemplateFields,
    jobValues
      ? {
          [`title-${jobIndex}`]: jobValues.title,
          [`firm-${jobIndex}`]: jobValues.firm,
          [`location-${jobIndex}`]: jobValues.location,
          [`fromMonth-${jobIndex}`]: jobValues.from?.month,
          [`fromYear-${jobIndex}`]: jobValues.from?.year,
          [`toMonth-${jobIndex}`]: jobValues.to?.month,
          [`toYear-${jobIndex}`]: jobValues.to?.year,
        }
      : {}
  );

  const checkValidation = useCallback((): boolean => {
    const validation = validateFields(fields, values);
    if (validation.valid) {
      return true;
    } else {
      setErrors(validation.errors);
      return false;
    }
  }, [fields, setErrors, values]);

  useEffect(() => {
    setCheckValidation(jobIndex, checkValidation);
  }, [checkValidation, setCheckValidation, jobIndex]);

  const onChangeHandler = (values: FormValues) => {
    const job: Job = {
      title: values[`title-${jobIndex}`],
      firm: values[`firm-${jobIndex}`],
      location: values[`location-${jobIndex}`],
      from: {
        month: values[`fromMonth-${jobIndex}`],
        year: values[`fromYear-${jobIndex}`],
      },
      ...(values[`toMonth-${jobIndex}`] !== undefined
        ? { to: { month: values[`toMonth-${jobIndex}`], year: values[`toYear-${jobIndex}`] } }
        : {}),
    };
    setIsStillHere(job.to?.month === undefined);
    onChange(job);
    setValues(values);
  };

  return { fields, values, errors, setErrors, onChangeHandler };
}
