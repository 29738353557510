import { useEffect } from "react";
import emitter, { EVENTS } from "../lib/emitter";

export function useHideAppointmentBanner() {
  useEffect(() => {
    Promise.resolve().then(() => {
      emitter.emit(EVENTS.APPOINTMENT_BANNER_HIDE);
    });

    return () => {
      emitter.emit(EVENTS.APPOINTMENT_BANNER_SHOW);
    };
  }, []);
}
