export default function createDeferred<T>() {
  let resolve: (v?: T) => void = () => {};
  let reject: (reason?: string) => void = () => {};
  const promise = new Promise<T>((r, rj) => {
    resolve = r;
    reject = rj;
  });
  return {
    promise,
    resolve,
    reject,
  };
}
