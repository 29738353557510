import React from "react";
import howItWorks1 from "../../../../assets/landing/how-it-works_01.png";
import howItWorks2 from "../../../../assets/landing/how-it-works_02.png";
import howItWorks3 from "../../../../assets/landing/how-it-works_03.png";
import howItWorks4 from "../../../../assets/landing/how-it-works_04.png";
import howItWorks5 from "../../../../assets/landing/how-it-works_05.png";
import paginationSrc from "../../../../assets/landing/pagination.svg";
import { t } from "../../../../locales";
import FullWidthPageBackground from "../../../../components/FullWidthPageBackground";
import "./style.scss";

export default function BlockHowItWorks() {
  return (
    <div className="block-how-it-works">
      <FullWidthPageBackground className="block-how-it-works__background" />
      <div className="block-how-it-works__container">
        <h2 className="block-how-it-works__title">{t("How It Works")}</h2>

        <div className="block-how-it-works__items">
          <div className="block-how-it-works__item">
            <img className="block-how-it-works__item-img" src={howItWorks1} alt="Sign up and create account" />
            <div className="block-how-it-works__item-description">{t("Sign up and create account")}</div>
          </div>
          <img src={paginationSrc} className="block-how-it-works__more" alt="more" />

          <div className="block-how-it-works__item">
            <img className="block-how-it-works__item-img" src={howItWorks2} alt="Purchase sessions" />
            <div className="block-how-it-works__item-description">{t("Purchase sessions")}</div>
          </div>
          <img src={paginationSrc} className="block-how-it-works__more" alt="more" />

          <div className="block-how-it-works__item">
            <img className="block-how-it-works__item-img" src={howItWorks3} alt="Browse and book a coach" />
            <div className="block-how-it-works__item-description">{t("Browse and book a coach")}</div>
          </div>
          <img src={paginationSrc} className="block-how-it-works__more" alt="more" />

          <div className="block-how-it-works__item">
            <img className="block-how-it-works__item-img" src={howItWorks4} alt="Have coaching session" />
            <div className="block-how-it-works__item-description">{t("Have coaching session")}</div>
          </div>
          <img src={paginationSrc} className="block-how-it-works__more" alt="more" />

          <div className="block-how-it-works__item">
            <img className="block-how-it-works__item-img" src={howItWorks5} alt="Rate and review coach" />
            <div className="block-how-it-works__item-description">{t("Rate and review coach")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
