import React from "react";
import "./style.scss";

interface Props {
  children: number;
  className?: string;
}

function BaseCounter({ children, className }: Props) {
  if (children === 0) return null;
  const cls = ["base-counter"];

  if (className) cls.push(className);

  return <div className={cls.join(" ")}>{children}</div>;
}

export default BaseCounter;
