import React from "react";
import { useTranslation } from "../../locales";
import BaseIcon from "../BaseIcon";
import BaseButton from "../BaseButton";
import BaseAvatar from "../BaseAvatar";
import useViewController from "./viewController";
import "./style.scss";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";

export default function AppointmentBanner() {
  const { t } = useTranslation();
  const { getLocation } = useModalLinkPrinter();
  const { meetingPartner, show, appointmentId, onClose } = useViewController();

  if (!show) return null;

  return (
    <div className="appointment-banner">
      <div className="appointment-banner__container container">
        <div className="appointment-banner__inner">
          <BaseAvatar className="appointment-banner__avatar mr-3" user={meetingPartner} size={40} />
          <div className="appointment-banner__inner-content">
            <div className="appointment-banner__text fw-normal">
              {t("The appointment with {{screenName}} has already started", {
                screenName: meetingPartner?.screenName || "",
              })}
            </div>
            <BaseButton
              className="appointment-banner__join-btn"
              live
              to={getLocation(`/appointment/join/${appointmentId}`)}
            >
              <BaseIcon className="appointment-banner__join-btn-icon mr-1">camera</BaseIcon>
              {t("Join chat")}
            </BaseButton>
          </div>
          <BaseButton className="appointment-banner__close-btn __close-banner" icon small onClick={onClose}>
            <BaseIcon>close</BaseIcon>
          </BaseButton>
        </div>
      </div>
    </div>
  );
}
