import React from "react";
import { t } from "../../../../locales";
import BaseButton from "../../../BaseButton";
import "./style.scss";

interface Props {
  className?: string;
  onClick?: () => void;
}

function PlayStreamButton({ className, onClick = () => {} }: Props) {
  const cls = ["play-stream-button"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")} onClick={onClick}>
      <BaseButton className="play-stream-button__button">{t("Start stream")}</BaseButton>
    </div>
  );
}

export default PlayStreamButton;
