import { useMemo } from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";

const useController = (userList: LiveChatUser[]) => {
  const guests = useMemo(() => userList.filter((u: LiveChatUser) => u.user === null), [userList]);
  const users = useMemo(() => userList.filter((u: LiveChatUser) => u.user), [userList]);

  return {
    guestCount: guests.length,
    users,
  };
};

export default useController;
