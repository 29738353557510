import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BasePage from "../../components/BasePage";
import "./style.scss";

export default function PageBlog() {
  const location = useLocation();
  const history = useHistory();
  const SOURCE_URL = "/blog/index.html";
  let blogObserverOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };
  const blogObserver: IntersectionObserver = useMemo(
    () => new IntersectionObserver(() => {
    setResizeTrigger({});
  }, blogObserverOptions),[blogObserverOptions]);

  const [onload, setOnload] = useState<boolean>(false);
  const [resizeTrigger, setResizeTrigger] = useState<object>({});
  const iFrame = useRef<HTMLIFrameElement>(null);

  const iframeOnload = () => {
    if (iFrame.current && iFrame.current.contentDocument && iFrame.current.contentWindow) {
      setOnload(true);
      iFrame.current.contentWindow.addEventListener("resize", () => setResizeTrigger({}));
      iFrame.current.contentWindow.addEventListener("unload", () => {
        setOnload(false);
      });
      const blogObserverTrigger = document.querySelector("#blogObserver");
      blogObserverTrigger && blogObserver.observe(blogObserverTrigger);
    }
  };

  const frameHeight = useMemo(
    () =>
      iFrame.current && iFrame.current.contentDocument && resizeTrigger
        ? iFrame.current.contentDocument.documentElement?.offsetHeight + 50
        : 0,
    [resizeTrigger]
  );

  useEffect(() => {
    if (onload && iFrame.current?.contentDocument) {
      const links = iFrame.current.contentDocument.querySelectorAll("a");
      links.forEach((link) => {
        if (link.href.search(/\/blog\/index\.html($|\?)/) > 0) {
          link.href = link.href.replace(SOURCE_URL, window.location.pathname);
          link.onclick = (e: Event) => {
            e.preventDefault();
            history.push(link.pathname + link.search);
            window.document.body.scrollTo({ top: 0 });
          };
        } else if (!link.target) link.target = "_parent";
      });

      for (const form of iFrame.current.contentDocument.forms) {
        form.action = form.action.replace(/.*\/blog\/index\.html/, SOURCE_URL);
      }

      const media: NodeListOf<HTMLElement> = iFrame.current.contentDocument.querySelectorAll("img, video, style");
      media.forEach((item) => (item.onload = () => setResizeTrigger({})));

      const relatedPost: NodeListOf<HTMLElement> = iFrame.current.contentDocument.querySelectorAll(".dib-related-post");
      relatedPost.forEach((item) => {
        const link: HTMLAnchorElement | null = item.querySelector("a.dib-post-title-link");
        item.onclick = () => link?.click();
      });
    }
  }, [onload, history]);

  return (
    <BasePage loading={!onload}>
      <iframe
        id="blog"
        name="blog"
        title="blog"
        ref={iFrame}
        className="page-blog"
        src={SOURCE_URL + location.search}
        frameBorder="0"
        height={frameHeight + "px"}
        onLoad={iframeOnload}
      />
      <span id="blogObserver"/>
    </BasePage>
  );
}
