import { useEffect, useRef, useState } from "react";
import { PaymentPackage, PaymentPackageEnum } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import useAuthUser from "../../hooks/useAuthUser";
import { useOnRouteChange } from "../../hooks/useOnRouteChange";
import useAppState from "../../hooks/useAppState";
import emitter, { EVENTS } from "../../lib/emitter";
import providers from "../../lib/providers";
import config from "../../config";
import { submitPaymentCB } from "./types";

export enum STEPS {
  PAYMENT_PACKAGES_LIST,
  REFILL_BALANCE,
  SUCCESS_MESSAGE,
}

function useController() {
  const { user } = useAuthUser();
  const { systemSettings } = useAppState();
  const refUser = useRef(user);
  const refSystemSettings = useRef(systemSettings);
  const [step, setStep] = useState(STEPS.PAYMENT_PACKAGES_LIST);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [packages, setPackages] = useState<PaymentPackage[]>([]);
  const [currentPackage, setCurrentPackage] = useState<PaymentPackage | null>(null);

  useEffect(() => {
    refUser.current = user;
    refSystemSettings.current = systemSettings;
  }, [user, systemSettings]);

  const getPaymentPackages = async () => {
    const paymentPackages = await providers.PaymentPackageProvider.findAll({ type: [PaymentPackageEnum.DEPOSIT] });
    return paymentPackages.filter((p) => {
      return p.costs.filter((c) => c.currency === refSystemSettings.current?.mainCurrency).length > 0;
    });
  };

  const showModal = () => {
    if (!refUser.current || refUser.current.role !== config.userJoinRole) {
      return;
    }
    setShow(true);
    setLoading(true);
    getPaymentPackages()
      .then((packages) => {
        setPackages(packages);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    setShow(false);
    if (loadingButton) setLoadingButton(false);
    setStep(STEPS.PAYMENT_PACKAGES_LIST);
    setCurrentPackage(null);
  };
  useOnRouteChange(onClose);
  useEffect(() => {
    emitter.on<any>(EVENTS.BALANCE_REFILL_SHOW, showModal);
    emitter.on<any>(EVENTS.BALANCE_REFILL_HIDE, onClose);
    return () => {
      emitter.off<any>(EVENTS.BALANCE_REFILL_SHOW, showModal);
      emitter.off<any>(EVENTS.BALANCE_REFILL_HIDE, onClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickPackage = (paymentPackage: PaymentPackage) => {
    setCurrentPackage(paymentPackage);
  };
  const emptyFunc = async () => {};
  const onUserSubmitCB = useRef<submitPaymentCB>(emptyFunc);
  const setOnSubmitPaymentCB = (cb: submitPaymentCB) => (onUserSubmitCB.current = cb);

  const stepBack = () => {
    setCurrentPackage(null);
    setStep((prev) => prev - 1);
  };

  const stepForward = async () => {
    if (step === STEPS.REFILL_BALANCE) {
      setLoadingButton(true);
      try {
        const r = await onUserSubmitCB.current();
        if (r) setStep((prev) => prev + 1);
      } finally {
        setLoadingButton(false);
      }

    } else {
      setStep((prev) => prev + 1);
    }
  };

  return {
    show,
    loading,
    loadingButton,
    packages,
    onClose,
    currentPackage,
    step,
    stepForward,
    stepBack,
    onClickPackage,
    setOnSubmitPaymentCB,
  };
}

export default useController;
