import { useState } from "react";
import useCategories from "../../../../hooks/useCategories";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import config from "../../../../config";

export default function useViewController() {
  const { categories } = useCategories();
  const { tabletUp } = useBreakPoints();
  const [isActiveIndex, setIsActiveIndex] = useState<number | undefined>(0);

  const filteredCategories = categories.filter((i) => i.id !== config.technicalCategoryId);
  const onClickPracticeArea = (index: number) => {
    setIsActiveIndex((oldIndex) => {
      if (oldIndex === index && !tabletUp) {
        return undefined;
      }
      return index;
    });
  };

  return {
    categories: filteredCategories,
    isActiveIndex,
    onClickPracticeArea,
  };
}
