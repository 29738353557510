import React from "react";
import { NavigationMenuItemInterface } from "../../../../types";
import { NavLink, useLocation } from "react-router-dom";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import BaseIcon from "../../../../components/BaseIcon";
import BaseCard from "../../../../components/BaseCard";
import "./style.scss";

interface Props {
  itemsList: NavigationMenuItemInterface[];
  className?: string;
  withoutIcons?: boolean;
  outline?: boolean;
  exact?: boolean;
}

function NavigationMenuMobile({ itemsList, withoutIcons, outline, exact = true, className }: Props) {
  const location = useLocation();

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const currentItem =
    itemsList.find((item) =>
      exact ? location.pathname === item.to : location.pathname.indexOf(item.to.toString()) >= 0
    ) ?? itemsList[0];

  const top = itemsList.filter((item) => !item.secondMenu);
  const bot = itemsList.filter((item) => !!item.secondMenu);

  const cls = ["navigation-menu-mobile"];
  if (isComponentVisible) cls.push("__active");
  if (className) cls.push(className);
  if (withoutIcons) cls.push("__without-icons");
  if (outline) cls.push("__outline");

  return (
    <div className={cls.join(" ")} ref={ref}>
      {currentItem && (
        <div
          className="navigation-menu-mobile__item __current"
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          {currentItem.icon && <BaseIcon className="navigation-menu-mobile__item-icon">{currentItem.icon}</BaseIcon>}
          {currentItem.title}
          <BaseIcon size={10}>fas-angle-down</BaseIcon>
        </div>
      )}
      <nav className="navigation-menu-mobile__nav">
        <BaseCard className="navigation-menu-mobile__list">
          <div onClick={() => setIsComponentVisible(!isComponentVisible)}>
            {top.map((item) => {
              const cls = ["navigation-menu-mobile__item"];
              if (item.className) cls.push(item.className);
              return (
                <NavLink exact to={item.to || "/coaches"} key={item.name} className={cls.join(" ")}>
                  <div>
                    {item.icon && <BaseIcon className="navigation-menu-mobile__item-icon">{item.icon}</BaseIcon>}
                    {item.title}
                  </div>
                </NavLink>
              );
            })}
            {bot.length > 0 && <div className="navigation-menu-mobile__separator" />}
            {bot.map((item) => {
              const cls = ["navigation-menu-mobile__item"];
              if (item.className) cls.push(item.className);
              return (
                <NavLink exact to={item.to || "/coaches"} key={item.name} className={cls.join(" ")}>
                  <div>
                    {item.icon && <BaseIcon className="navigation-menu-mobile__item-icon">{item.icon}</BaseIcon>}
                    {item.title}
                  </div>
                </NavLink>
              );
            })}
          </div>
        </BaseCard>
      </nav>
    </div>
  );
}

export default NavigationMenuMobile;
