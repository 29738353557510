import { useMemo, useRef, useState } from "react";
import fileSize from "filesize";
import { InputExtendedMessageCreate } from "@scrile/api-provider/dist/api/MessagesProvider";
import { getFileMimeType } from "@scrile/tools/dist/lib/FileHelpers";
import { AttachedFileData, AttachedFileError } from "../../types";
import providers from "../../lib/providers";
import emitter, { EVENTS } from "../../lib/emitter";
import useFiles from "../../hooks/useFiles";
import { useOnRouteChange } from "../../hooks/useOnRouteChange";
import useAuthorizedOnly from "../../hooks/useAuthorizedOnly";
import useAuthUser from "../../hooks/useAuthUser";
import { useTranslation } from "../../locales";
import config from "../../config";

type error = string;

const useMessageAttachments = () => {
  const { maxFileSizeUpload } = config;
  const { t } = useTranslation();

  const [attachedFiles, setAttachedFiles] = useState<(AttachedFileData | AttachedFileError)[]>([]);
  const { fileAddToQueue, removeFromQueue } = useFiles();
  const addAttachment = async (files: File[]) => {
    attachedFiles.forEach((f) => "error" in f && removeAttachment(f.id));
    if (files.length === 0) return;
    const filesToUpload: (File | error)[] = [];
    files.forEach((file) => {
      if (file.size > maxFileSizeUpload) {
        filesToUpload.push(
          t("The file size may not be over {{size}}", { size: fileSize(maxFileSizeUpload, { exponent: 3 }) })
        );
        return;
      }
      filesToUpload.push(file);
    });
    const updateFile = (id: string, data: Partial<AttachedFileData>) => {
      let fileId = id;
      setAttachedFiles((files) =>
        files.map((f) => {
          if (f.id === id) {
            const newFile = {
              ...f,
              ...data,
            };
            fileId = newFile.id;
            return newFile;
          }
          return f;
        })
      );
      return fileId;
    };
    const addAttachmentError = (attachedFileError: AttachedFileError) => {
      setAttachedFiles((files) => [...files, attachedFileError]);
    };
    for (const file of filesToUpload) {
      let fileIndex = Math.random().toString(36).substring(2, 15) + filesToUpload.indexOf(file);
      if (file instanceof File) {
        setAttachedFiles((files) => [
          ...files,
          {
            id: fileIndex,
            fileName: file.name,
            mediaType: getFileMimeType(file),
            size: file.size,
            uploadProgress: 0,
          },
        ]);
        const {
          uploadData: { uuid },
          uploadId,
        } = await fileAddToQueue({
          type: "message_attachment",
          file: file,
          onSuccess: async () => {
            fileIndex = updateFile(fileIndex, { id: uuid, uploadProgress: 100 });
          },
          onUploadProgress: (p) => {
            const uploadProgress = Math.floor((p.loaded / p.total) * 100);
            const id = uploadProgress < 100 ? uploadId : uuid;
            fileIndex = updateFile(fileIndex, { uploadProgress, id });
          },
          onError: (error) => {
            console.debug(`File uploading error: ${error.message}`);
            removeAttachment(fileIndex);
            addAttachmentError({
              id: fileIndex,
              error: t("Uploading error"),
            });
          },
        });
        fileIndex = updateFile(fileIndex, { id: uploadId });
      } else {
        addAttachmentError({
          id: fileIndex,
          error: file,
        });
      }
    }
  };

  const removeAttachment = (id: string) => {
    setAttachedFiles((files) => {
      const index = files.findIndex((f) => f.id === id);
      if (index < 0) {
        return files;
      }
      const [fileData] = files.splice(index, 1);
      if (fileData && "uploadProgress" in fileData && fileData.uploadProgress < 100) {
        removeFromQueue(fileData.id);
      }
      return [...files];
    });
  };

  const isUploading = useMemo(() => attachedFiles.some((i) => "uploadProgress" in i && i.uploadProgress < 100), [
    attachedFiles,
  ]);

  return {
    attachedFiles,
    addAttachment,
    removeAttachment,
    isUploading,
  };
};

function useController(recipientIds?: string[], threadIds?: string[]) {
  const { user } = useAuthUser();
  const [value, setValue] = useState("");
  const [textArea, setTextarea] = useState<HTMLTextAreaElement | null>(null);
  const sending = useRef(false);
  const { attachedFiles, addAttachment, removeAttachment, isUploading } = useMessageAttachments();
  const { t } = useTranslation();

  const removeAllAttachments = () => attachedFiles.forEach((f) => removeAttachment(f.id));
  const removeAllAttachmentsOnSend = () =>
    attachedFiles.forEach((f) =>
      "uploadProgress" in f ? f.uploadProgress === 100 && removeAttachment(f.id) : removeAttachment(f.id)
    );

  const onSendMessage = useAuthorizedOnly(
    async () => {
      const allowEmptyMessage = attachedFiles.length > 0 && !isUploading;
      if (sending.current || (!value.trim() && !allowEmptyMessage)) return;
      const data: InputExtendedMessageCreate = {
        text: value.trim(),
        threadIds,
        recipientIds,
        files: attachedFiles.filter((f) => "uploadProgress" in f && f.uploadProgress === 100).map((f) => ({
          publicAccess: true,
          uuid: f.id
        })),
        pricePackageId: "0",
      };

      textArea?.focus();
      sending.current = true;
      try {
        const message = await providers.MessagesProvider.sendExtendedMessages({ data });
        emitter.emit(EVENTS.MESSAGES_NEW_MESSAGE, message[0]);
        setValue("");
        removeAllAttachmentsOnSend();
      } finally {
        sending.current = false;
      }
    },
    {
      notification: t("Log in or register to send a message"),
    }
  );

  useOnRouteChange(() => {
    removeAllAttachments();
    setValue("");
  });

  const addAttachmentWrapper = (files: File[]) => {
    textArea?.focus();
    return addAttachment(files);
  };

  return {
    user,
    value,
    setValue,
    onSendMessage,
    setTextarea,
    attachedFiles,
    isUploading,
    addAttachment: addAttachmentWrapper,
    removeAllAttachments,
    removeAttachment,
  };
}

export default useController;
