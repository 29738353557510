import { useEffect, useState } from "react";
import emitter, { EVENTS } from "../../lib/emitter";

function useViewController() {
  const [isShowFooter, setIsShowFooter] = useState(true);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const showFooter = () => {
      setIsShowFooter(true);
    };
    const hideFooter = () => {
      setIsShowFooter(false);
    };

    emitter.on(EVENTS.FOOTER_SHOW, showFooter);
    emitter.on(EVENTS.FOOTER_HIDE, hideFooter);

    return () => {
      emitter.off(EVENTS.FOOTER_SHOW, showFooter);
      emitter.off(EVENTS.FOOTER_HIDE, hideFooter);
    };
  }, []);

  return {
    isShowFooter,
    showModal,
    setShowModal,
  };
}

export default useViewController;
