import React from "react";
import { useTranslation } from "../../../../locales";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import FileType from "../../../../components/FileType";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  className?: string;
}
export default function UploadCV({ className }: Props) {
  const { t } = useTranslation();

  const { inputRef, onButtonClick, onChange, removeFile, userCV, loading } = useViewController();

  const cls = ["upload-cv"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      {!userCV && (
        <div className="upload-cv__button-wrapper">
          <BaseButton className="upload-cv__button" loading={loading} outline={true} onClick={onButtonClick}>
            <BaseIcon className="upload-cv__icon mr-1">circle-plus</BaseIcon>
            <span>{t("Upload CV")}</span>
          </BaseButton>
          <input
            type="file"
            accept="file"
            onChange={onChange}
            style={{ display: "none" }}
            name="user_cv"
            ref={inputRef}
          />
        </div>
      )}
      {userCV && (
        <div className="upload-cv__file-wrapper d-flex">
          <div className="upload-cv__file-type-container d-center">
            <FileType
              className="upload-cv__file-type"
              fileName={userCV.metadata.filename}
              fileSize={Number(userCV.metadata.contentLength)}
            />
          </div>
          <BaseButton onClick={() => removeFile()} borderless icon>
            <BaseIcon>close</BaseIcon>
          </BaseButton>
        </div>
      )}
    </div>
  );
}
