import React from "react";
import { useTranslation } from "../../../../locales";
import BaseCard from "../../../../components/BaseCard";
import InfiniteScroll from "../../../../components/InfiniteScroll";
import MessageThreadItem from "../MessageThreadItem";
import BaseSpinner from "../../../../components/BaseSpinner";
import useController from "./controller";
import CustomScrollBar from "../../../../components/CustomScrollBar";

import "./style.scss";

interface Props {
  className?: string;
}

function MessageThreadList({ className }: Props) {
  const { t } = useTranslation();
  const cls = ["messages-thread-list"];
  const { messageThreadList, user, scrollThreads, loadMore, loadingThreads } = useController();

  if (loadingThreads || messageThreadList.length === 0) cls.push("__align-center");
  if (className) cls.push(className);

  if (!user) return null;
  return (
    <BaseCard className={cls.join(" ")}>
      {messageThreadList.length > 0 && (
        <CustomScrollBar className={"messages-thread-list__scrollbar"}>
          <InfiniteScroll enable={scrollThreads} onNext={loadMore} className="messages-thread-list__container">
            <div>
              {messageThreadList.map((messageThread) => (
                <MessageThreadItem authUser={user} messageThread={messageThread} key={messageThread.id} />
              ))}
            </div>
          </InfiniteScroll>
        </CustomScrollBar>
      )}
      {loadingThreads && <BaseSpinner className="mx-auto" />}
      {!loadingThreads && messageThreadList.length === 0 && <h3>{t("No threads")}</h3>}
    </BaseCard>
  );
}

export default MessageThreadList;
