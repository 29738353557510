import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import { getComponent, getContextModules } from "../../../lib/requireContextHelper";
import DefaultComponent from "./DefaultComponent";

const context = require.context(".", true, /\.\/[a-zA-Z]*\/index.ts/);
const modules = getContextModules(context);

function getIntegration(currentSystem: PaymentSystemPublic | null) {
  return getComponent(modules, currentSystem, DefaultComponent);
}

export default getIntegration;
