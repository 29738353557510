import React from "react";
import { Appointment } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { useTranslation } from "../../../../locales";
import AppointmentItem from "../AppointmentItem";
import BaseButton from "../../../../components/BaseButton";
import useCustomDomain from "../../../../hooks/useCustomDomain";
import "./style.scss";

interface Props {
  className?: string;
  appointments: Appointment[];
  isConsultant?: boolean;
  hideStatus?: boolean;
}

export default function AppointmentList({ className, appointments, isConsultant = true, hideStatus = false }: Props) {
  const { t } = useTranslation();
  const { isCustomDomain } = useCustomDomain();

  const cls = ["appointments-list"];
  if (className) cls.push(className);
  if (appointments.length === 0) cls.push("__empty");
  if (appointments.length % 2) cls.push("__hide-mobile-border");

  return (
    <div className={cls.join(" ")}>
      <div className="appointments-list__heading mb-6 label">
        <div className="appointments-list__heading-date">{t("Date")}</div>
        <div className="appointments-list__heading-time">{t("Time / Duration")}</div>
        <div className="appointments-list__heading-customer">{isConsultant ? t("Customer") : t("Consultant")}</div>
        <div className="appointments-list__heading-status">{t("Status")}</div>
      </div>
      {appointments.length === 0 && (
        <div className="d-flex __column __align-center">
          <div className="appointments-list__empty-content d-center mb-6">{t("You currently have no events")}</div>
          {!isConsultant && !isCustomDomain && (
            <BaseButton large to="/coaches">
              {t("Find a Consultant")}
            </BaseButton>
          )}
        </div>
      )}
      {appointments.map((appointment) => {
        return (
          <AppointmentItem
            key={appointment.id}
            appointment={appointment}
            isConsultant={isConsultant}
            hideStatus={hideStatus}
          />
        );
      })}
    </div>
  );
}
