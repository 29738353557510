import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { throttle } from "lodash";
import emitter, { EVENTS } from "../../lib/emitter";
import useAuthUser from "../../hooks/useAuthUser";
import providers from "../../lib/providers";

export default function useController() {
  const { user } = useAuthUser();
  const prevUser = usePrevious(user);

  const [unreadThreadsCount, setUnreadThreadsCount] = useState(0);

  const throttledCountOfThreadListener = useCallback(
    throttle(
      () => {
        providers.MessageThreadsProvider.getUnreadCount().then((r) => setUnreadThreadsCount(r));
      },
      1000,
    ),
    []
  );

  useEffect(() => {
    if (!prevUser && user) {
      throttledCountOfThreadListener();
      emitter.on(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.on(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);

    } else if (prevUser && !user) {
      emitter.off(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.off(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    }

    return () => {
      emitter.off(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.off(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, throttledCountOfThreadListener]);

  const onClickMemberBalance = () => {
    emitter.emit(EVENTS.BALANCE_REFILL_SHOW);
  };

  return {
    onClickMemberBalance,
    user,
    unreadThreadsCount,
  };
}
