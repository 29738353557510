import React from "react";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import PaymentPackageCard from "../PaymentPackageCard";
import "./style.scss";

interface Props {
  packages: PaymentPackage[];
  currentPackage: PaymentPackage | null;
  onClick: (paymentPackage: PaymentPackage) => void;
}

function PaymentPackageList({ packages, onClick, currentPackage }: Props) {
  return (
    <div className="payment-packages mb-8">
      {packages.map((p) => {
        const isCurrent = p.id === currentPackage?.id;

        return (
          <PaymentPackageCard
            onClick={() => onClick(p)}
            active={isCurrent}
            key={p.id}
            paymentPackage={p}
            className="payment-packages__card"
          />
        );
      })}
    </div>
  );
}

export default PaymentPackageList;
