import { InputPasswordResetStart } from "@scrile/api-provider/dist/api/PasswordResetProvider";
import { ErrorDTO } from "@scrile/api-provider/dist/lib/apiErrorHandler";
import providers from "../../../../lib/providers";

export function useController(onResetStart: (data: { email: string; status: boolean }) => void) {
  async function onSendEmail(email: string) {
    const data: InputPasswordResetStart = {
      email,
    };
    const status = await providers.PasswordResetProvider.start({ data });
    if (status) {
      onResetStart({ status, email });
    } else {
      const e = new Error("Email was not found");
      const error: ErrorDTO[] = [
        {
          code: "BAD_REQUEST",
          status: "error",
          message: e.message,
          error: e,
        },
      ];
      throw error;
    }
  }

  return { onSendEmail };
}
