import React, { createContext, Reducer, useContext, useEffect, useReducer, useRef } from "react";
import { setLoginData, removeLoginData, getLoginData, LoginData } from "../lib/authHelper";
import providers from "../lib/providers";
import emitter, { EVENTS } from "../lib/emitter";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import useMounted from "./useMounted";

interface UserState {
  user: User | null;
  authorities: string[];
}
type Action =
  | {
      type: "setUser";
      payload: User | null;
    }
  | {
      type: "setAuthorities";
      payload: string[];
    }
  | {
      type: "setState";
      payload: UserState;
    };

const reducer: Reducer<UserState, Action> = (state, action) => {
  switch (action.type) {
    case "setAuthorities":
      return { ...state, authorities: action.payload };
    case "setUser":
      return { ...state, user: action.payload };
    case "setState":
      return action.payload;
  }
};

const defaultState: UserState = {
  authorities: [],
  user: null,
};

const AuthUserContext = createContext<[UserState, React.Dispatch<Action>]>([
  defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export function AuthUserContextProvider(props: React.PropsWithChildren<{}>) {
  const isMounted = useMounted();
  const data = getLoginData();
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    user: data?.user ?? null,
    authorities: data?.authorities ?? [],
  });

  useEffect(() => {
    const onEvent = (data?: LoginData) => {
      if (!isMounted.current) return;
      dispatch({
        type: "setState",
        payload: {
          user: data?.user ?? null,
          authorities: data?.authorities ?? [],
        },
      });
    };
    emitter.on<LoginData>(EVENTS.SET_LOGIN_DATA, onEvent);
    return () => {
      emitter.off<LoginData>(EVENTS.SET_LOGIN_DATA, onEvent);
    };
  }, [isMounted]);

  return React.createElement(
    AuthUserContext.Provider,
    {
      value: [state, dispatch],
    },
    props.children
  );
}

export default function useAuthUser() {
  const [{ user, authorities }] = useContext(AuthUserContext);

  const setLogout = () => providers.LoginProvider.logout().then(removeLoginData);

  const setEmailVerificationStatus = (emailVerified: boolean) => {
    if (!user) return;
    const data: LoginData = {
      user: {
        ...user,
        emailVerified,
      },
      authorities,
    };
    setLoginData(data);
  };

  const userRef = useRef(user);
  userRef.current = user;

  return {
    user,
    userRef,
    authorities,
    setLoginData,
    setLogout,
    setEmailVerificationStatus,
  };
}
