import { useRef, useState } from "react";
import { useClickAway } from "react-use";

export default function useComponentVisible(initialVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialVisible);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    setIsComponentVisible(false);
  };
  useClickAway(ref, handleClickOutside, ["click"]);

  return { ref, isComponentVisible, setIsComponentVisible };
}
