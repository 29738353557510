import React from "react";
import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { useTranslation } from "../../../../../locales";
import DefaultTransaction from "../DefaultTransaction";

interface Props {
  transaction: Transaction;
}

function TransactionPayout({ transaction }: Props) {
  const { t } = useTranslation();

  return (
    <DefaultTransaction
      transaction={transaction}
      type={t("Payout")}
      description={<div className="__capitalize">{t(transaction.details.payeeInfo?.payoutSystem)}</div>}
    />
  );
}

export default TransactionPayout;
