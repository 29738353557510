import { useEffect, useState } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import providers from "../lib/providers";

export function useFindByUserName(
  username: string,
  searchFields: FragmentFields<UserPublicData>,
  onNotFoundCB: () => void
) {
  const [user, setUser] = useState<UserPublicData | null>(null);

  useEffect(() => {
    (async () => {
      if (user !== null && user.username === username) return;
      const response = await providers.UserPublicDataProvider.search({ data: { usernames: [username] } }, searchFields);
      const foundUser = response?.result.find((i) => i.username === username);

      if (foundUser) {
        setUser(foundUser);
      } else {
        onNotFoundCB();
      }
    })();
  }, [username, user, searchFields, onNotFoundCB]);

  return {
    user,
  };
}
