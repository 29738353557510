import React from "react";
import { TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";
import "./style.scss";

interface Props {
  tags: TagInfo[];
}

export default function PracticeAreaTags({ tags }: Props) {
  return (
    <div className="practice-area-tags">
      {tags.map((tag) => {
        return (
          <div className="practice-area-tags__tag" key={tag.id}>
            {tag.title}
          </div>
        );
      })}
    </div>
  );
}
