import React, { useEffect } from "react";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";

export default function useMobileBlurOnOrientationChanged(ref: React.MutableRefObject<HTMLElement | null>) {
  useEffect(() => {
    if (!ref.current || !isMobile()) return;
    const blurEffect = () => ref.current?.blur();
    window.addEventListener("orientationchange", blurEffect);
    return () => {
      window.removeEventListener("orientationchange", blurEffect);
    };
  }, [ref]);
}
