import React from "react";
import { useTranslation } from "../../../../locales";
import photoSrc from "../../../../assets/about-us/photo-owner-1.png";
import photoSrc__2x from "../../../../assets/about-us/photo-owner-1__2x.png";
import photoSrc2 from "../../../../assets/about-us/photo-owner-2.png";
import photoSrc2__2x from "../../../../assets/about-us/photo-owner-2__2x.png";
import FullWidthPageBackground from "../../../../components/FullWidthPageBackground";
import SliderCompanies from "../../../../components/SliderCompanies";
import { LINK_LINKEDIN_OWNER_1, LINK_LINKEDIN_OWNER_2 } from "../../config";
import "./style.scss";

export default function BlockAboutOwners() {
  const { t } = useTranslation();

  return (
    <div className="block-about-owners">
      <h1 className="block-about-owners__title fw-bold">{t("About SecureTheOffer")}</h1>
      <div className="block-about-owners__content">
        <div className="block-about-owners__owners">
          <div className="block-about-owners__owner-item">
            <div className="block-about-owners__block-photo">
              <a
                className="block-about-owners__link"
                href={LINK_LINKEDIN_OWNER_1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="block-about-owners__photo"
                  src={photoSrc}
                  alt="Wasim Tahir"
                  srcSet={photoSrc__2x + " 2x"}
                />
              </a>
            </div>
            <div className="block-about-owners__text">
              {t(
                "SecureTheOffer is co-founded by Wasim Tahir and Jonny Lewis. Wasim is a former consultant with extensive experience across several top-tier financial institutions, including Jeffries & Company, Credit Suisse, Lloyd’s Banking Group, and British International Investment."
              )}
            </div>
          </div>
          <div className="block-about-owners__owner-item">
            <div className="block-about-owners__block-photo">
              <a
                className="block-about-owners__link"
                href={LINK_LINKEDIN_OWNER_2}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="block-about-owners__photo"
                  src={photoSrc2}
                  alt="Jonny Lewis"
                  srcSet={photoSrc2__2x + " 2x"}
                />
              </a>
            </div>
            <div className="block-about-owners__text">
              {t(
                "Jonny is a former Board Director at the Clermont Group, a multi-billion dollar private investment fund, where he has held several roles, including Director of Listed Investments, Managing Director of Private Investments, and finally Group Chief Financial Officer."
              )}
            </div>
          </div>
        </div>
        <SliderCompanies className="block-about-owners__slider" list={"founder"} />
      </div>
      <FullWidthPageBackground className="block-about-owners__background" />
    </div>
  );
}
