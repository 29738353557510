import React from "react";
import { Link } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { MediaFilesController } from "../../lib/MediaFileController";
import { useTranslation } from "../../locales";
import BasePhoto from "../BasePhoto";
import OnlineStatus from "../OnlineStatus";
import "./index.scss";

interface Props {
  className?: string;
  classNameAvatar?: string;
  user: UserPublicData;
  to?: string;
}

function CardAvatar({ className = "", user, to, classNameAvatar = "" }: Props) {
  const { t } = useTranslation();
  const src = (user.avatar && MediaFilesController.getImageThumbnail(user.avatar.urlPart, "720p")) || "";

  const avatar = to ? (
    <Link to={to}>
      <BasePhoto className={classNameAvatar} src={src} alt="avatar" title={t("No photo")} />
    </Link>
  ) : (
    <BasePhoto className={classNameAvatar} src={src} alt="avatar" title={t("No photo")} />
  );

  return (
    <div className={"card-avatar " + className}>
      <OnlineStatus className="card-avatar__online-status" user={user} />
      {avatar}
    </div>
  );
}

export default CardAvatar;
