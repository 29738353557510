import { useRef } from "react";
import { isAndroid } from "@scrile/tools/dist/lib/browserUtils";
import useKeepScrollPosition from "../../../../../../hooks/useKeepScrollPosition";
import useBreakPoints from "../../../../../../hooks/useBreakPoints";
import useController from "./controller";

function useViewController(token: string) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { shouldScrollToBottom } = useKeepScrollPosition(containerRef);
  const { desktopUp, mobileLandscape } = useBreakPoints();

  const onFocus = () => {
    if (isAndroid()) {
      shouldScrollToBottom.current = true;
    }
  };

  const { messages } = useController(token, containerRef, shouldScrollToBottom);

  return {
    containerRef,
    messages,
    onFocus,
    desktopUp,
    mobileLandscape,
  };
}

export default useViewController;
