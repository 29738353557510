import { RefObject, useCallback, useEffect } from "react";
import { useGetSet } from "react-use";

export default function useResizeVideo({
  player,
  videoWrapper,
  excludeParameter = [],
}: {
  player: RefObject<HTMLVideoElement | null>;
  videoWrapper: RefObject<HTMLDivElement | null>;
  excludeParameter?: Array<"width" | "height">;
}) {
  const [getVideoWidth, setVideoWidth] = useGetSet(0);
  const [getVideoHeight, setVideoHeight] = useGetSet(0);

  const resize = useCallback(() => {
    if (!player.current || !videoWrapper.current) return;
    if (
      getVideoWidth() === player.current.videoWidth &&
      getVideoHeight() === player.current.videoHeight &&
      player.current.offsetWidth === videoWrapper.current.clientWidth &&
      player.current.offsetHeight === videoWrapper.current.clientHeight
    ) {
      return;
    }
    if (
      (getVideoWidth() !== 0 && player.current.videoWidth === 0) ||
      (getVideoHeight() !== 0 && player.current.videoHeight === 0)
    ) {
      return;
    }
    setVideoWidth(player.current.videoWidth);
    setVideoHeight(player.current.videoHeight);

    !excludeParameter.includes("width") && (videoWrapper.current.style.width = player.current.videoWidth + "px");
    !excludeParameter.includes("height") && (videoWrapper.current.style.height = player.current.videoHeight + "px");

    // @ts-ignore
    videoWrapper.current.style.aspectRatio = `${player.current?.videoWidth || 4} / ${player.current?.videoHeight || 3}`;
  }, [getVideoHeight, getVideoWidth, player, setVideoHeight, setVideoWidth, videoWrapper, excludeParameter]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    interval = setInterval(resize, 50);
    return () => {
      clearInterval(interval);
    };
  }, [resize]);
}
