import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentProvider";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import { Review } from "@scrile/api-provider/dist/api/ReviewProvider";
import { FileData } from "@scrile/api-provider/dist/api/FileProvider";
import { MediaFilesController } from "../../lib/MediaFileController";
import { useFindByUserName } from "../../hooks/useFindByUserName";
import usePager from "../../hooks/usePager";
import providers from "../../lib/providers";

const searchFields: FragmentFields<UserPublicData> = [
  "id",
  "role",
  "username",
  "screenName",
  "disabled",
  "calendlyScheduleUrl",
  { onlineStatus: ["isOnline"] },
  { publicLivechatUser: ["broadcasting"] },
  { avatar: ["urlPart"] },
  { questionary: ["fields", { tags: ["id", "slug", "title", "categoryId"] }] },
];

export interface ProfileVideoData {
  fileData: FileData;
  thumbnailUrl: string;
}

function useController(username: string) {
  const history = useHistory();
  const [appointments, setAppointments] = useState<AppointmentType[]>([]);
  const [video, setVideo] = useState<ProfileVideoData>();
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const { user } = useFindByUserName(
    username,
    searchFields,
    useCallback(() => {
      history.push("/");
    }, [history])
  );

  useEffect(() => {
    (async () => {
      if (user?.id) {
        try {
          setAppointmentsLoading(true);
          const response = await providers.AppointmentProvider.searchAppointmentTypes({ userId: user.id });
          setAppointments(response);
        } catch (e) {
          console.error(e);
        } finally {
          setAppointmentsLoading(false);
        }
        try {
          setVideoLoading(true);
          const response = await providers.FileProvider.find({
            fileIds: [{ subjectId: user?.id || "", type: "profile_video" }],
          });
          let thumbnailUrl = "";
          if (response.length) thumbnailUrl = MediaFilesController.getVideoThumbnail(response[0].urlPart, "1080p");
          setVideo({ fileData: response[0], thumbnailUrl: thumbnailUrl });
        } finally {
          setVideoLoading(false);
        }
      }
    })();
  }, [user, setAppointments]);

  const { enableScroll, items, getNextPage, getFirstPage, data, setData } = usePager(
    useCallback(
      (page) =>
        providers.ReviewProvider.find({
          data: {
            subjects: [{ subjectId: user?.id || "", subjectType: "user" }],
            sort: "TIME,DESC",
            page,
            size: 6,
          },
        }),
      [user]
    )
  );

  const onReviewAdded = (review: Review) => {
    const localData = items;

    if (!data || items.length === 0) {
      getFirstPage();
    } else {
      if (data.hasNextPage) {
        localData.pop();
      }
      localData.unshift(review);
      setData((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            totalRecords: prevState.totalRecords + 1,
            result: localData,
          };
        }
      });
    }
  };

  useEffect(() => {
    if (user?.id) {
      (async () => {
        setReviewsLoading(true);
        await getFirstPage();
        setReviewsLoading(false);
      })();
    }
  }, [user, getFirstPage]);

  return {
    user,
    appointments,
    appointmentsLoading,
    video,
    videoLoading,
    reviewsLoading,
    onReviewAdded,
    reviewItems: items,
    getNextReviewsPage: getNextPage,
    enableReviewsScroll: enableScroll,
    totalReviewsRecords: data?.totalRecords.toString(),
  };
}

export default useController;
