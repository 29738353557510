import React from "react";
import { Link } from "react-router-dom";
import { LocationDescriptor, Location } from "history";
import BaseSpinner from "../BaseSpinner";
import "./style.scss";

type LocationDescriptorFactory = (location: Location) => LocationDescriptor;

interface Props {
  name?: string;
  className?: string;
  children?: React.ReactNode;
  href?: string;
  to?: LocationDescriptor | LocationDescriptorFactory;
  onClick?: (event: React.MouseEvent) => void;
  opacity?: boolean;
  borderless?: boolean;
  live?: boolean;
  outline?: boolean;
  small?: boolean;
  large?: boolean;
  fluid?: boolean;
  loading?: boolean;
  icon?: boolean;
  round?: boolean;
  negative?: boolean;
  disabled?: boolean;
  negativeOpacity?: boolean;
}

function BaseButton({
  name,
  onClick,
  to,
  href,
  className = "",
  children,
  opacity,
  borderless,
  live,
  outline,
  small,
  large,
  fluid,
  icon,
  round,
  negative,
  loading,
  disabled,
  negativeOpacity,
}: Props) {
  const cls = ["base-button"];
  if (opacity) cls.push("__opacity");
  if (outline) cls.push("__outline");
  if (borderless) cls.push("__borderless");
  if (live) cls.push("__live");
  if (small) cls.push("__small");
  if (large) cls.push("__large");
  if (fluid) cls.push("__fluid");
  if (icon) cls.push("__icon");
  if (round) cls.push("__round");
  if (negative) cls.push("__negative");
  if (disabled) cls.push("__disabled");
  if (negativeOpacity) cls.push("__negative-opacity");

  cls.push(className);
  const propsData = {
    name,
    className: cls.join(" "),
  };
  if (to) {
    return (
      <Link to={to} {...propsData}>
        {children}
      </Link>
    );
  }
  if (href) {
    return (
      <a href={href} {...propsData}>
        {children}
      </a>
    );
  }

  const onClickHandler = (e: React.MouseEvent) => {
    if (onClick && !loading && !disabled) {
      onClick(e);
    }
  };

  return (
    <button disabled={disabled} onClick={onClickHandler} type="button" {...propsData}>
      <div className={`base-button__children ${loading ? "__hide" : ""}`}>{children}</div>
      {loading && <BaseSpinner size={25} />}
    </button>
  );
}

export default BaseButton;
