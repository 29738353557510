import { useEffect, useRef } from "react";
import useStreamHelper from "../../../../hooks/useStreamHelper";

export default function useViewController(stream: MediaStream) {
  const localStream = useRef<MediaStream>(stream);
  const { checkStreamForChanges } = useStreamHelper();

  useEffect(() => {
    if (!stream || !localStream.current) return;
    checkStreamForChanges(stream, localStream.current);
  }, [stream, checkStreamForChanges]);

  return {
    localStream: localStream.current,
  };
}
