import React, { useRef } from "react";
import "./style.scss";
import { CSSTransition } from "react-transition-group";

interface Props {
  show: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function FilterList({ show, children, className }: Props) {
  const listRef = useRef<HTMLDivElement>(null);

  const cls = ["filter-list d-flex __column"];
  if (className) cls.push(className);

  return (
    <CSSTransition nodeRef={listRef} in={show} timeout={300} unmountOnExit classNames="filter-list__fade">
      <div className={cls.join(" ")} ref={listRef}>
        {children}
      </div>
    </CSSTransition>
  );
}
