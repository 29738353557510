import React from "react";
import { FieldInterface, FormValue, FormValues, SetErrors } from "../../types";
import BaseIcon from "../BaseIcon";
import FieldPrinter from "../FieldPrinter";
import "./styles.scss";

interface Props {
  autofocus?: boolean;
  fields: FieldInterface[];
  values: FormValues;
  errors?: Record<string, string>;
  setErrors?: SetErrors;
  notification?: string;
  className?: string;
  children?: string | React.ReactNode;
  headline?: React.ReactNode;
  onChange: (values: FormValues) => void;
  onSubmitEnter?: () => void;
}

export default function BaseForm({
  autofocus,
  fields,
  values,
  errors,
  onChange,
  setErrors,
  notification,
  className,
  children,
  headline,
  onSubmitEnter,
}: Props) {
  const { commonErrors, elementErrors } = Object.keys(errors || {}).reduce<{
    elementErrors: Record<string, string>;
    commonErrors: Record<string, string>;
  }>(
    (out, key) => {
      if (!errors) return out;
      const field = fields.find((f) => f.name === key);
      if (field && field.type !== "checkbox") {
        out.elementErrors[key] = errors[key];
      } else out.commonErrors[key] = errors[key];
      return out;
    },
    { elementErrors: {}, commonErrors: {} }
  );
  const commonErrorsList = Object.values(commonErrors);
  const isCommonError = commonErrorsList.length > 0;

  const onChangeHandler = (value: FormValue, name: string) => {
    onChange({ ...values, [name]: value });
    setErrors &&
      setErrors((state) => {
        delete state[name];
        Object.keys(commonErrors).forEach((k) => delete state[k]);
        return state;
      });
  };

  const elements = fields.map((field, index) => {
    const value = values[field.name];
    const onChange = (value: FormValue) => onChangeHandler(value, field.name);
    const errorMessage = elementErrors[field.name];
    const autoFocus = autofocus && index === 0;
    const cls = ["base-form__field"];
    if (field.className) cls.push(field.className);
    const className = cls.join(" ");
    return (
      <FieldPrinter
        field={field}
        className={className}
        value={value}
        autoFocus={autoFocus}
        errorMessage={errorMessage}
        onChange={onChange}
        key={field.name}
      />
    );
  });
  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitEnter && onSubmitEnter();
      }}
    >
      {(isCommonError || notification) && (
        <div className={"base-form__error body" + (isCommonError ? "" : " __warning")}>
          <BaseIcon className="base-form__error-icon">fas-exclamation-circle</BaseIcon>
          <span className="base-form__error-text">{isCommonError ? commonErrorsList.join("\n") : notification}</span>
        </div>
      )}
      {headline}
      <div className="base-form__items">{elements}</div>
      {children}
      {onSubmitEnter && <button type="submit" className="d-none" />}
    </form>
  );
}
