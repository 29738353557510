import React from "react";
import MessageInput from "../../../../../MessageInput";
import MessagesListView from "../../../../../MessagesListView";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  threadId: string | undefined;
  className?: string;
  token: string;
  disableAttachments?: boolean;
}

function TextChat({ threadId, className, token, disableAttachments }: Props) {
  const { containerRef, messages, onFocus, desktopUp, mobileLandscape } = useViewController(token);

  const cls = ["text-chat"];
  if (className) cls.push(className);
  if (mobileLandscape) cls.push("__landscape-mode");

  return (
    <div className={cls.join(" ")}>
      <MessagesListView
        className="text-chat__message-list"
        avatarSize={30}
        messageItemClass="text-chat__message"
        containerRef={containerRef}
        messages={messages}
        infiniteScrollEnabled={false}
        showScreenName={true}
        isLiveChat={true}
      />
      {threadId && !mobileLandscape && (
        <MessageInput
          disableAttachments={!!disableAttachments}
          threadIds={[threadId]}
          onFocus={onFocus}
          className="text-chat__message-input"
          transparentOnBlur={!desktopUp}
          maxRows={!desktopUp ? 2 : undefined}
        />
      )}
    </div>
  );
}

export default TextChat;
