import { useState } from "react";
import { MediaConstraints } from "../../../../types";

const useController = (
  onChangeConstraints: (constraints: MediaConstraints) => void,
  constraints: MediaConstraints
) => {
  const [facingMode, setFacingMode] = useState<"user" | "environment" | string>(
    typeof constraints.video === "object" && typeof constraints.video.facingMode === "string"
      ? constraints.video.facingMode
      : "user" ?? "user"
  );

  const onSwitchCam = async () => {
    if (!onChangeConstraints) return;
    const mode = facingMode === "user" ? "environment" : "user";
    const constraints: MediaConstraints = {
      video: {
        facingMode: mode,
      },
    };
    onChangeConstraints(constraints);
    setFacingMode(mode);
  };

  return {
    onSwitchCam,
    facingMode,
  };
};

export default useController;
