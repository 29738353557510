import { useState } from "react";
import { useMount } from "react-use";
import { Faq } from "@scrile/api-provider/dist/api/FaqProvider";
import providers from "../../lib/providers";

export default function useController() {
  const [loading, setLoading] = useState(false);
  const [faqList, setFaqList] = useState<Faq[]>([]);

  useMount(() => {
    (async function () {
      setLoading(true);
      try {
        const response = await providers.FaqProvider.findAll();
        if (response.length) setFaqList(response);
      } finally {
        setLoading(false);
      }
    })();
  });

  return {
    faqList,
    loading,
  };
}
