import { getNameMimeType } from "@scrile/tools/dist/lib/FileHelpers";
import React, { useMemo, ChangeEvent, useRef, useCallback } from "react";
import config from "../../config";

const isConfigAcceptType = (type: string) => {
  return Object.keys(config.fileTypes).includes(type);
};

const getConfigMimeTypes = (configFileTypes: { [k: string]: string[] }) => {
  const configMimeTypes: Record<string, string[]> = {};
  for (const type in configFileTypes) {
    if (isConfigAcceptType(type)) {
      configMimeTypes[type] = configFileTypes[type].reduce<string[]>((acc, i) => {
        acc.push("." + i);
        acc.push(getNameMimeType(i));
        return acc;
      }, []);
    }
  }
  return configMimeTypes;
};

const configMimeTypes = getConfigMimeTypes(config.fileTypes);

type fileAccept = string | "image" | "video" | "audio" | "document";
interface Props {
  name: string;
  multiple?: boolean;
  accept?: fileAccept | fileAccept[];
  onInput: (files: File[]) => void;
  children?: React.FC<{ showFileUpload: () => void }>;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
}
export default function BaseInputFile({
  name,
  multiple = false,
  accept,
  onInput,
  children: Activator,
  inputRef,
}: Props) {
  const inputRefLocal = useRef<HTMLInputElement>(null);
  const acceptTypes = useMemo<string>(() => {
    if (!accept) return "file";
    const acceptArr = Array.isArray(accept) ? accept : [accept];

    return acceptArr
      .map((t) => {
        if (isConfigAcceptType(t)) {
          return Array.from(new Set(configMimeTypes[t])).join(", ");
        }
        const res = [t];
        if (t.lastIndexOf(".") > 0 && t.indexOf("/") === -1) res.push(getNameMimeType(t));
        return res.join(", ");
      })
      .join(", ");
  }, [accept]);

  const selectFile = (e: ChangeEvent) => {
    e.stopPropagation();
    const element = e.target as HTMLInputElement;
    const files = element.files
      ? Array.from(element.files).filter((f) => {
          const type = getNameMimeType(f.name);
          return !accept || (type && acceptTypes.includes(type));
        })
      : [];
    if (files.length > 0) {
      onInput(files);
    }
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    } else if (inputRefLocal.current) {
      inputRefLocal.current.value = "";
    }
  };

  const showFileUpload = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    } else if (inputRefLocal.current) {
      inputRefLocal.current.click();
    }
  }, [inputRef]);

  return (
    <>
      {Activator && <Activator showFileUpload={showFileUpload} />}
      <input
        ref={inputRef || inputRefLocal}
        name={name}
        style={{ display: "none" }}
        type="file"
        accept={acceptTypes}
        multiple={multiple}
        onChange={selectFile}
      />
    </>
  );
}
