import { useCallback, useEffect, useRef, useState } from "react";
import { useUnmount } from "react-use";
import { isEqual, uniqBy } from "lodash";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/selfie_segmentation";
import { LiveChatJoinResponse, LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { WebRTCConnection } from "@scrile/api-provider/dist/api/WebRTCProvider";
import { TrackOptions } from "@scrile/streaming-client/build/ConsumerClient";
import useLiveChat, { UserBroadcastingCB } from "../pages/PageLiveChat/hooks/useLiveChat";
import { MediaConstraints } from "../types";
import providers from "../lib/providers";
import {
  addTracksToStream,
  cloneScreenTracks,
  getScreenTracks,
  getVideoTracks,
  removeTracksFromStream,
} from "../lib/mediaStreamHelpers";
import useWebRTC from "./useWebRTC";
import useOnAuthorised from "./useOnAuthorised";
import useStreamHelper from "./useStreamHelper";
import useBlurController from "./useBlurController";
import useMediaConstraints from "./useMediaConstraints";

interface ProduceProps {
  token: string;
  joinData: LiveChatJoinResponse | null;
  autoProduceStream: boolean;
}

function useProduceController({ token, joinData, autoProduceStream }: ProduceProps) {
  const WebRTC = useWebRTC();
  const { blurStart, blurStop } = useBlurController();
  const { mediaConstraints, setMediaConstraints } = useMediaConstraints({
    video: true,
    audio: true,
    videoEnabled: autoProduceStream,
    audioEnabled: autoProduceStream,
    shareEnabled: false,
    blurEnabled: false,
  });
  const [stream, setStream] = useState<MediaStream>(new MediaStream([]));
  const [screenStream, setScreenStream] = useState<MediaStream>(new MediaStream([]));
  const [streamToProduce, setStreamToProduce] = useState<MediaStream>(new MediaStream([]));
  const connection = useRef<WebRTCConnection>();
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>();
  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const setStreams = (stream: MediaStream) => {
    setStreamToProduce(new MediaStream(stream));
    setStream(new MediaStream(getVideoTracks(stream)));
    setScreenStream(new MediaStream(getScreenTracks(stream)));
  };

  const onScreenShare = async (constraints: MediaConstraints) => {
    let data: MediaStreamConstraints | MediaStream;
    if (constraints.shareEnabled) {
      // @ts-ignore
      const screen = (await navigator.mediaDevices.getDisplayMedia()) as MediaStream;
      const screenTracks = screen.getVideoTracks();
      // @ts-ignore
      screenTracks.forEach((t) => (t.kindType = "screen"));
      data = new MediaStream([...screen.getTracks(), ...streamToProduce.getVideoTracks()]);
      addTracksToStream(data, streamToProduce.getAudioTracks());
    } else {
      removeTracksFromStream(streamToProduce, getScreenTracks(streamToProduce));
      data = new MediaStream(streamToProduce.getVideoTracks());
      addTracksToStream(data, streamToProduce.getAudioTracks());
    }
    return data;
  };

  const onVideoChange = async (constraints: MediaConstraints) => {
    let data: MediaStream;

    removeTracksFromStream(streamToProduce, getVideoTracks(streamToProduce));
    if (!constraints.videoEnabled) {
      data = new MediaStream(streamToProduce.getVideoTracks());
      addTracksToStream(data, streamToProduce.getAudioTracks());
    } else {
      const newStream = new MediaStream(await navigator.mediaDevices.getUserMedia({ video: constraints.video }));
      addTracksToStream(newStream, streamToProduce.getAudioTracks());
      cloneScreenTracks(getScreenTracks(streamToProduce)).forEach((t) => newStream.addTrack(t));
      data = newStream;
    }
    return data;
  };

  const onAudioChange = async (constraints: MediaConstraints) => {
    if (!isEqual(mediaConstraints.audio, constraints.audio) || !constraints.audioEnabled) {
      removeTracksFromStream(streamToProduce, streamToProduce.getAudioTracks());
    }
    if (constraints.audioEnabled) {
      let newStream = await navigator.mediaDevices.getUserMedia({
        audio: constraints.audio,
      });
      addTracksToStream(streamToProduce, newStream.getAudioTracks(), true);
    }
    return streamToProduce;
  };

  const onBlurChange = useCallback(
    async (constraints: MediaConstraints, outStream = streamToProduce) => {
      let data: MediaStream;

      if (constraints.blurEnabled) {
        const videoTracks = getVideoTracks(outStream);
        if (!videoTracks.length) {
          constraints.blurEnabled = false;
          return outStream;
        }
        data = await blurStart(videoTracks[0]);
      } else {
        data = await blurStop();
      }

      addTracksToStream(data, outStream.getAudioTracks());
      cloneScreenTracks(getScreenTracks(streamToProduce)).forEach((t) => data.addTrack(t));
      return data;
    },
    [blurStart, blurStop, streamToProduce]
  );

  const setDevicesList = useCallback(async () => {
    const { audioInputList, videoInputList } = await WebRTC.getDeviceList();
    setAudioInputs(uniqBy(audioInputList.reverse(), "groupId").reverse());
    setVideoInputs(videoInputList);
  }, [WebRTC]);

  const closeStream = () => {
    WebRTC.closeAllStreams();
    streamToProduce.getTracks().forEach((t) => t.stop());
    stream.getTracks().forEach((t) => t.stop());
    screenStream.getTracks().forEach((t) => t.stop());
  };

  const onProduceStream = useCallback(async () => {
    try {
      setLoading(true);
      if (!token) return false;
      const [connectionData] = await Promise.all([providers.LivechatsProvider.broadcast({ token }), setDevicesList()]);
      connection.current = connectionData;

      let localStream = new MediaStream([]);
      if (mediaConstraints.videoEnabled || mediaConstraints.audioEnabled) {
        localStream = await navigator.mediaDevices.getUserMedia({
          video: mediaConstraints.videoEnabled ? mediaConstraints.video : false,
          audio: mediaConstraints.audioEnabled ? mediaConstraints.audio : false,
        });

        if (mediaConstraints.blurEnabled) {
          localStream = await onBlurChange({ ...mediaConstraints }, localStream);
        }
      }

      localStream = await WebRTC.produceStream(connection.current, localStream);
      setStreams(localStream);
      return true;
    } finally {
      setLoading(false);
    }
  }, [WebRTC, setDevicesList, token, mediaConstraints, onBlurChange]);

  const onChangeConstraints = async (constraints: MediaConstraints) => {
    try {
      setProcessing(true);
      let data: MediaStream | null = null;
      const localConstraints: MediaConstraints = {
        ...mediaConstraints,
        ...constraints,
      };
      if (!isEqual(mediaConstraints.blurEnabled, localConstraints.blurEnabled)) {
        data = await onBlurChange(localConstraints);
      } else if (!isEqual(mediaConstraints.shareEnabled, localConstraints.shareEnabled)) {
        data = await onScreenShare(localConstraints);
      } else if (
        !isEqual(mediaConstraints.videoEnabled, localConstraints.videoEnabled) ||
        !isEqual(mediaConstraints.video, localConstraints.video)
      ) {
        if (localConstraints.blurEnabled) {
          await blurStop();
        }
        data = await onVideoChange(localConstraints);
        if (localConstraints.blurEnabled && localConstraints.videoEnabled) {
          data = await onBlurChange(localConstraints, data);
        }
      } else if (
        !isEqual(mediaConstraints.audio, localConstraints.audio) ||
        !isEqual(mediaConstraints.audioEnabled, localConstraints.audioEnabled)
      ) {
        data = await onAudioChange(localConstraints);
      }
      setMediaConstraints(localConstraints);

      if (!connection.current || !data) return;

      const newStream = await WebRTC.produceStream(connection.current, data);
      setStreams(newStream);
    } finally {
      setProcessing(false);
    }
  };

  const shouldCallProduceStream = useRef(true);
  useEffect(() => {
    if (joinData && shouldCallProduceStream.current && autoProduceStream) {
      shouldCallProduceStream.current = false;
      onProduceStream();
    }
  }, [joinData, onProduceStream, autoProduceStream]);

  useUnmount(closeStream);

  return {
    stream,
    screenStream,
    mediaConstraints,
    audioInputs,
    videoInputs,
    onChangeConstraints,
    onScreenShare,
    isShare: !!mediaConstraints.shareEnabled,
    closeStream,
    onProduceStream,
    connection: connection.current,
    processing,
    loading,
  };
}

interface ConsumeProps {
  producerUserId: string;
  token: string;
  joinData: LiveChatJoinResponse | null;
  setOnUserBroadcastingCB: (cb: UserBroadcastingCB) => UserBroadcastingCB;
}

function useConsumeController({ producerUserId, token, joinData, setOnUserBroadcastingCB }: ConsumeProps) {
  const WebRTC = useWebRTC();
  const [stream, setStream] = useState<MediaStream>(new MediaStream([]));
  const [screenStream, setScreenStream] = useState<MediaStream>(new MediaStream([]));
  const connection = useRef<WebRTCConnection>();
  const [mediaConstraints, setMediaConstraints] = useState<MediaConstraints | null>(null);

  const setStreams = (stream: MediaStream, constraints: MediaConstraints | null) => {
    const screenTracks = getScreenTracks(stream);
    if (constraints?.shareEnabled) {
      const audioTracks = stream.getAudioTracks();
      audioTracks.forEach((t) => stream.removeTrack(t));
      screenTracks.push(...audioTracks);
    }
    setScreenStream(new MediaStream(screenTracks));
    setStream(new MediaStream(stream));
  };

  const onConsumeStream = useCallback(
    async (broadcasterChatUserId: string) => {
      connection.current = await providers.LivechatsProvider.watch({
        token,
        broadcasterChatUserId,
      });
      const newStream = await WebRTC.consumeStream({
        connection: connection.current,
        tracks: {
          audio: !!mediaConstraints?.audioEnabled,
          video: !!mediaConstraints?.videoEnabled,
          screen: !!mediaConstraints?.shareEnabled,
        },
      });

      newStream && setStreams(newStream, mediaConstraints);
      setMediaConstraints(null);
    },
    [WebRTC, mediaConstraints, token]
  );

  setOnUserBroadcastingCB(({ chatUserId, broadcasting }) => {
    if (joinData?.me.id === chatUserId) return;
    broadcasting ? onConsumeStream(chatUserId).then() : closeStream();
  });

  const closeStream = () => {
    WebRTC.closeAllStreams();
    setStream(new MediaStream([]));
    setScreenStream(new MediaStream([]));
  };

  const shouldCallConsumeStream = useRef(true);
  useEffect(() => {
    if (joinData && shouldCallConsumeStream.current) {
      const producer = joinData.users.find((i) => i.userId === producerUserId);
      if (producer && producer.broadcasting) {
        shouldCallConsumeStream.current = false;
        onConsumeStream(producer.id).then();
      }
    }
  }, [joinData, producerUserId, onConsumeStream]);

  useOnAuthorised(() => {
    shouldCallConsumeStream.current = true;
  });

  useEffect(() => {
    const CB = async (data: Required<TrackOptions>) => {
      const newMediaConstraints = { videoEnabled: data.video, audioEnabled: data.audio, shareEnabled: data.screen };
      const newStream = await WebRTC.consumeStream({
        connection: connection.current!,
        tracks: { audio: data.audio, video: data.video, screen: data.screen },
      });
      setStreams(newStream, newMediaConstraints);
      setMediaConstraints(newMediaConstraints);
    };
    WebRTC.on("producers-updated", CB);
    return () => {
      WebRTC.off("producers-updated", CB);
    };
  }, [WebRTC]);

  useUnmount(closeStream);

  return {
    stream,
    screenStream,
    mediaConstraints,
    closeStream,
  };
}

export default function useProduceConsumeController(
  producerUserId: string,
  livechatSubject: Subject<LivechatSubjectType>,
  autoProduceStream: boolean
) {
  const { loading, token, joinData, setOnUserBroadcastingCB, endChat } = useLiveChat({
    userId: "",
    livechatSubject,
  });

  const {
    stream: produceStream,
    screenStream: produceScreenStream,
    mediaConstraints: produceMediaConstraints,
    connection: produceConnection,
    closeStream: closeProduceStream,
    onChangeConstraints,
    onScreenShare,
    isShare,
    onProduceStream,
    audioInputs,
    videoInputs,
    processing,
    loading: loadingProduceData,
  } = useProduceController({ token, joinData, autoProduceStream });

  const {
    stream: consumeStream,
    screenStream: consumeScreenStream,
    mediaConstraints: consumeMediaConstraints,
    closeStream: closeConsumeStream,
  } = useConsumeController({
    producerUserId,
    joinData,
    token,
    setOnUserBroadcastingCB,
  });

  const { checkStreamForChanges } = useStreamHelper();

  const localProduceStream = useRef<MediaStream>(produceStream);
  const localProduceScreenStream = useRef<MediaStream>(produceScreenStream);
  const localConsumeStream = useRef<MediaStream>(consumeStream);
  const localConsumeScreenStream = useRef<MediaStream>(consumeScreenStream);
  const [hasProduceTracks, setHasProduceTracks] = useState(false);
  const [hasProduceScreenTracks, setHasProduceScreenTracks] = useState(false);
  const [hasConsumeTracks, setHasConsumeTracks] = useState(false);
  const [hasConsumeScreenTracks, setHasConsumeScreenTracks] = useState(false);

  useEffect(() => {
    if (!produceStream || !localProduceStream.current) return;
    checkStreamForChanges(produceStream, localProduceStream.current);
    setHasProduceTracks(localProduceStream.current.getTracks().length > 0);
  }, [produceStream, checkStreamForChanges]);

  useEffect(() => {
    if (!produceScreenStream || !localProduceScreenStream.current) return;
    checkStreamForChanges(produceScreenStream, localProduceScreenStream.current);
    setHasProduceScreenTracks(localProduceScreenStream.current.getTracks().length > 0);
  }, [produceScreenStream, checkStreamForChanges]);

  useEffect(() => {
    if (!consumeStream || !localConsumeStream.current) return;
    checkStreamForChanges(consumeStream, localConsumeStream.current);
    setHasConsumeTracks(localConsumeStream.current.getTracks().length > 0);
  }, [consumeStream, checkStreamForChanges]);

  useEffect(() => {
    if (!consumeScreenStream || !localConsumeScreenStream.current) return;
    checkStreamForChanges(consumeScreenStream, localConsumeScreenStream.current);
    setHasConsumeScreenTracks(localConsumeScreenStream.current.getTracks().length > 0);
  }, [consumeScreenStream, checkStreamForChanges]);

  useEffect(() => {
    function beforeOnload() {
      endChat();
      return "Confirm refresh";
    }
    window.addEventListener("beforeunload", beforeOnload);
    return () => window.removeEventListener("beforeunload", beforeOnload);
  }, [endChat]);

  const onCloseStream = () => {
    closeProduceStream();
    closeConsumeStream();
    endChat();
  };

  return {
    threadId: joinData?.threadId,
    users: joinData?.users ?? [],
    produceStream: localProduceStream.current,
    produceScreenStream: localProduceScreenStream.current,
    consumeStream: localConsumeStream.current,
    consumeScreenStream: localConsumeScreenStream.current,
    produceMediaConstraints,
    consumeMediaConstraints,
    onProduceStream,
    onCloseStream,
    onScreenShare,
    isShare,
    onChangeConstraints,
    loading: loading || loadingProduceData || processing,
    token,
    audioInputs,
    videoInputs,
    hasConsumeTracks,
    hasConsumeScreenTracks,
    hasProduceTracks,
    hasProduceScreenTracks,
    produceConnection,
  };
}
