import { useRef, useState } from "react";
import { useMount } from "react-use";
import { Job, QuestionaryInfo } from "@scrile/api-provider/dist/api/QuestionaryInfoProvider";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import useAuthUser from "../../../../hooks/useAuthUser";
import providers from "../../../../lib/providers";

const performerSearchFields: FragmentFields<QuestionaryInfo> = ["fields"];

export default function useController() {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const validationCallbacks = useRef<Array<() => boolean>>([]);
  const { user } = useAuthUser();

  const getJobsFromProvider = async (): Promise<Job[]> => {
    const questionnaire = await providers.QuestionaryInfoProvider.find({ id: user?.id || "" }, performerSearchFields);
    const jobs = questionnaire.fields.professional?.jobs || [];
    if (!jobs.length) jobs.push({ title: "", location: "", firm: "" });
    return jobs;
  };

  useMount(async () => {
    setLoading(true);
    try {
      const response = await getJobsFromProvider();
      setJobs(response);
    } finally {
      setLoading(false);
    }
  });

  const onAdd = () => {
    setJobs((jobs) => [...jobs, { title: "", firm: "", location: "" }]);
  };

  const onDelete = (index: number) => {
    validationCallbacks.current.splice(index, 1);
    setJobs((prevJobs) => {
      const jobs = [...prevJobs];
      jobs.splice(index, 1);
      return jobs;
    });
  };

  const onChange = (job: Job, index: number) => {
    setJobs((jobs) => {
      jobs.splice(index, 1, job);
      return jobs;
    });
  };

  const setCheckValidation = (index: number, cb: () => boolean) => {
    validationCallbacks.current.splice(index, 1, cb);
  };

  const onSave = async () => {
    if (
      validationCallbacks.current.reduce((result, cb) => {
        return cb() && result;
      }, true)
    ) {
      const data: QuestionaryInfo = {
        fields: {
          userId: user?.id || "",
          professional: {
            jobs,
          },
        },
      };
      try {
        setProcessing(true);
        await providers.QuestionaryInfoProvider.update({ data });
      } finally {
        setProcessing(false);
      }
    }
  };

  return {
    onSave,
    onChange,
    onDelete,
    onAdd,
    loading,
    processing,
    jobs,
    setCheckValidation,
  };
}
