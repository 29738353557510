import { NavigationMenuItemInterface } from "../../types";
import { useTranslation } from "../../locales";
import useController from "./controller";

function useViewController() {
  const { t } = useTranslation();
  const { user, isPerformer } = useController();
  const itemsList: NavigationMenuItemInterface[] = [
    {
      title: t("Main info"),
      name: "settings",
      icon: "signup",
      to: "/user/settings",
    },
    ...(isPerformer
      ? [
          {
            title: t("Payment info"),
            name: "payment_info",
            icon: "wallet",
            to: "/user/payment-info",
          },
        ]
      : []),
    {
      title: t("Balance history"),
      name: "history",
      icon: "history",
      to: "/user/transactions",
    },
    ...(isPerformer
      ? [
          {
            title: t("Availability"),
            name: "availability",
            icon: "available",
            to: "/user/availability",
          },
        ]
      : []),
    {
      title: t("Change password"),
      name: "password",
      icon: "show-off",
      to: "/user/change-password",
    },
    {
      title: t("Log out"),
      name: "logout",
      icon: "logout",
      to: "/logout",
    },
  ];

  return {
    user,
    itemsList,
    isPerformer,
  };
}

export default useViewController;
