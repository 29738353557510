import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../locales";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import BaseAvatar from "../BaseAvatar";
import BaseMenu from "../BaseMenu";
import BaseBalance from "../BaseBalance";
import BaseDivider from "../BaseDivider";
import QuickSmallNavigator from "./components/QuickSmallNavigator";
import Navigation from "./components/Navigation";
import useViewController from "./viewController";
import "./style.scss";

function PageHeader() {
  const {
    logoSrc,
    setDefaultLogo,
    itemsList,
    user,
    isCustomer,
    onClickMemberBalance,
    unreadThreadsCount,
  } = useViewController();
  const { getLocation } = useModalLinkPrinter();
  const { t } = useTranslation();

  return (
    <header className="header">
      <div className="header__container __row container">
        <div className="header__inner">
          <Link to="/" className={"header__logo" + (!user ? " __center" : "")}>
            <img src={logoSrc} alt="logo" onError={setDefaultLogo} />
            <h2 className="header__logo-text">SecureTheOffer</h2>
          </Link>
          <Navigation className="header__desktop-nav" />
          {user && <QuickSmallNavigator unreadThreadsCount={unreadThreadsCount} />}
          {user ? (
            <div className="header__logged-user">
              <BaseMenu
                className="header__base-menu"
                itemsList={itemsList}
                title={user.screenName ?? ""}
                prepend={
                  <>
                    <BaseBalance
                      className="header__balance-button"
                      showRightIcon={isCustomer}
                      to={isCustomer ? undefined : "/user/transactions"}
                      onClick={isCustomer ? onClickMemberBalance : undefined}
                    />
                    <BaseDivider className="mt-3 mb-2" />
                  </>
                }
                spaced={true}
              >
                <div className="header__logged-user-menu">
                  <BaseAvatar user={user} className="header__logged-user-avatar" size={40} />
                  <BaseIcon size={12.5}>fas-angle-down</BaseIcon>
                </div>
              </BaseMenu>
            </div>
          ) : (
            <div className="header__buttons">
              <BaseButton className="header__buttons-button" borderless={true} to={getLocation("/login")}>
                <BaseIcon>signin</BaseIcon>
                <span className="text">{t("Log In")}</span>
              </BaseButton>
              <BaseButton className="header__buttons-button __signup" to={getLocation("/signup")}>
                <BaseIcon>signup</BaseIcon>
                <span className="text">{t("Sign Up")}</span>
              </BaseButton>
            </div>
          )}
        </div>
        <Navigation className="header__mobile-nav" />
      </div>
    </header>
  );
}

export default PageHeader;
