import { useCallback } from "react";

export default function useStreamHelper() {
  const replaceTracksIfChanged = (stream: MediaStream, localTrack: MediaStreamTrack, remoteTrack: MediaStreamTrack) => {
    if (remoteTrack && !localTrack) {
      stream.addTrack(remoteTrack);
    } else if (!remoteTrack && localTrack) {
      localTrack.stop();
      stream.removeTrack(localTrack);
    } else if (remoteTrack && localTrack) {
      if (remoteTrack?.id !== localTrack?.id) {
        localTrack.stop();
        stream.removeTrack(localTrack);
        stream.addTrack(remoteTrack);
      }
    }
  };

  const checkStreamForChanges = useCallback((remoteStream: MediaStream, localStream: MediaStream) => {
    const localVideoTrack = localStream.getVideoTracks()[0];
    const localAudioTrack = localStream.getAudioTracks()[0];
    const remoteVideoTrack = remoteStream.getVideoTracks()[0];
    const remoteAudioTrack = remoteStream.getAudioTracks()[0];
    replaceTracksIfChanged(localStream, localVideoTrack, remoteVideoTrack);
    replaceTracksIfChanged(localStream, localAudioTrack, remoteAudioTrack);
  }, []);

  return {
    checkStreamForChanges,
  };
}
