import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import useMounted from "../../hooks/useMounted";
import useResizeVideo from "./hooks/useResizeVideo";
import useBreakPoints from "../../hooks/useBreakPoints";
import emitter, { EVENTS } from "../../lib/emitter";
import useController from "./controller";
import { MediaConstraints } from "../../types";
import { RightPanelView } from "./components/RightPanel";

interface Props {
  isProducerUser: boolean;
  produce: boolean;
  stream: MediaStream;
  managedStream: MediaStream | null;
  constraints: MediaConstraints | null;
  managedConstraints: MediaConstraints | null;
  onChangeConstraints?: (constraints: MediaConstraints) => void;
  userList: LiveChatUser[];
  onClose: () => void;
  showConfirmationExit: boolean;
}

interface DisplayControls {
  rightPanel: RightPanelView;
  controls: boolean;
  confirmationExit: boolean;
}

function useViewController({
  isProducerUser,
  produce,
  stream,
  managedStream,
  constraints,
  managedConstraints,
  onChangeConstraints,
  userList,
  onClose,
  showConfirmationExit,
}: Props) {
  const usersCount = userList.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const player = useRef<HTMLVideoElement>(null);
  const videoWrapper = useRef<HTMLDivElement>(null);
  const streamView = useRef<HTMLDivElement>(null);
  const leftSide = useRef<HTMLDivElement>(null);
  const rightSide = useRef<HTMLDivElement>(null);

  const [showStreamPlayButton, setShowStreamPlayButton] = useState<boolean>(false);

  useResizeVideo({
    player: player.current,
    videoWrapper: videoWrapper.current,
    streamView: streamView.current,
    leftSide: leftSide.current,
    rightSide: rightSide.current,
  });

  const debounceTime = 200;

  const [displayControls, setDisplayControls] = useState<DisplayControls>({
    rightPanel: RightPanelView.HIDE_ALL,
    controls: false,
    confirmationExit: false,
  });

  const { desktopUp, mobileLandscape } = useBreakPoints();

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isMounted = useMounted();

  const { stopLocalTracks, onSwitchCam, facingMode } = useController(
    player.current,
    managedStream,
    managedConstraints,
    onChangeConstraints
  );

  const changeRightPanel = (rightPanelView: RightPanelView) => {
    setDisplayControls((settings) => ({
      ...settings,
      rightPanel: rightPanelView,
    }));
  };

  const changeControls = (name: keyof DisplayControls, state: boolean) => {
    setDisplayControls((settings) => ({
      ...settings,
      [name]: state,
    }));
  };

  const onShowControls = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    if (displayControls.controls) {
      changeControls("controls", false);
    } else {
      changeControls("controls", true);
      timeout.current = setTimeout(() => {
        if (isMounted.current) changeControls("controls", false);
      }, 3000);
    }
  };

  const onToggleCam = debounce(async () => {
    if (!onChangeConstraints || !managedConstraints) return;
    onChangeConstraints({ videoEnabled: !managedConstraints.videoEnabled });
  }, debounceTime);

  const onToggleMic = debounce(async () => {
    if (!onChangeConstraints || !managedConstraints) return;
    onChangeConstraints({ audioEnabled: !managedConstraints.audioEnabled });
  }, debounceTime);

  const onToggleBlur = debounce(async () => {
    if (!onChangeConstraints || !managedConstraints) return;
    onChangeConstraints({ blurEnabled: !managedConstraints.blurEnabled });
  }, debounceTime);

  const onCloseEvent = () => {
    onClose();
    stopLocalTracks();
    changeControls("confirmationExit", false);
  };

  const onClickEndCall = () => {
    if (showConfirmationExit) changeControls("confirmationExit", true);
    else onCloseEvent();
  };

  const playStream = () => {
    if (player.current) {
      player.current.muted = false;
      player.current.play();
      setShowStreamPlayButton(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!player.current) return;
      player.current.pause();
      player.current.srcObject = stream;
      await player.current.play().catch((e) => {
        if (produce || !player.current) {
          throw e;
        }
        player.current.muted = true;
        emitter.emit(EVENTS.PLAYER_MUTED_STATE_CHANGED, player.current);
        player.current.play().catch(() => {
          setShowStreamPlayButton(true);
        });
      });
    })();
  }, [stream, player, produce]);

  return {
    player,
    onClickEndCall,
    mobileLandscape,
    onShowControls,
    onToggleCam,
    onToggleMic,
    onToggleBlur,
    showControls: displayControls.controls || desktopUp,
    showControlsButtons:
      ((displayControls.controls || desktopUp) && !isMobile()) ||
      ((displayControls.controls || desktopUp) && isMobile() && displayControls.rightPanel !== RightPanelView.USERS),
    showStreamPlayButton,
    displayControls,
    onCloseEvent,
    changeControls,
    changeRightPanel,
    onSwitchCam,
    facingMode,
    desktopUp,
    hasVideo: constraints?.videoEnabled ?? true,
    hasAudio: constraints?.audioEnabled ?? true,
    hasShare: isProducerUser ? managedConstraints?.shareEnabled : constraints?.shareEnabled ?? false,
    videoWrapper,
    streamView,
    leftSide,
    rightSide,
    usersCount,
    playStream,
  };
}

export default useViewController;
