import React from "react";
import { useTranslation } from "../../../../locales";
import useModalLinkPrinter from "../../../../hooks/useModalLinkPrinter";
import ContentHeader from "../../../ContentHeader";
import BaseForm from "../../../BaseForm";
import BaseButton from "../../../BaseButton";
import ActionStatus from "../../../ActionStatus";
import { useViewController } from "./viewController";
import "./style.scss";

interface Props {
  token: string;
  onCancel: () => void;
  onSuccess?: (status: boolean) => void;
  promptLogin?: boolean;
}

export default function SetPassword({ token, onCancel, promptLogin = true, onSuccess }: Props) {
  const { isComplete, fields, values, errors, setErrors, onChange, setPassword } = useViewController(onSuccess);
  const { getLocation } = useModalLinkPrinter();
  const { t } = useTranslation();

  if (isComplete)
    return (
      <ActionStatus
        className="change-password-set"
        status={"success"}
        title={t("Password changed successfully")}
        description={(promptLogin && t("Now you can log in without any problems")) || ""}
      >
        {promptLogin && (
          <div className="change-password-set__buttons mt-6">
            <BaseButton fluid={true} large={true} to={getLocation("/login")}>
              <span className="text">{t("Log in")}</span>
            </BaseButton>
          </div>
        )}
      </ActionStatus>
    );
  return (
    <>
      <BaseForm
        fields={fields}
        values={values}
        onChange={(v) => onChange({ ...v, token })}
        errors={errors}
        setErrors={setErrors}
        className="change-password-set"
        headline={<ContentHeader className="change-password-set__header">{t("Set Password")}</ContentHeader>}
        onSubmitEnter={setPassword}
      >
        <div className="change-password-set__buttons mt-6">
          <BaseButton name="cancel" fluid={true} large={true} outline={true} onClick={onCancel}>
            <span className="text">{t("Cancel")}</span>
          </BaseButton>
          <BaseButton name="submit" fluid={true} large={true} onClick={setPassword}>
            <span className="text">{t("Confirm")}</span>
          </BaseButton>
        </div>
      </BaseForm>
    </>
  );
}
