import React from "react";
import { TagCategoryWithTags } from "../../../../hooks/useCategories";
import BaseIcon from "../../../../components/BaseIcon";
import PracticeAreaTags from "../PracticeAreaTags";
import "./style.scss";

interface Props {
  category: TagCategoryWithTags;
  onClickActivator: () => void;
  className?: string;
  isActive: boolean;
}
export default function PracticeAreaItem({ category, className, isActive, onClickActivator }: Props) {
  const cls = ["practice-area-item"];
  if (className) cls.push(className);
  if (isActive) cls.push("__active");

  return (
    <div className={cls.join(" ")}>
      <div onClick={onClickActivator} className="practice-area-item__activator">
        <h3 className="practice-area-item__title fw-semibold">{category.title}</h3>
        <BaseIcon className="practice-area-item__activator-icon">back</BaseIcon>
      </div>
      <div className="practice-area-item__content">
        <div className="practice-area-item__content-text">{category.description}</div>
        <PracticeAreaTags tags={category.tags} />
      </div>
    </div>
  );
}
