import { useEffect, useState } from "react";
import useAuthUser from "../../../../hooks/useAuthUser";
import useMounted from "../../../../hooks/useMounted";
import {
  InputPersonalInformation,
  PersonalInformation,
} from "@scrile/api-provider/dist/api/PersonalInformationProvider";
import providers from "../../../../lib/providers";
import { FormValues } from "../../../../types";

export default function useController() {
  const isMounted = useMounted();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<PersonalInformation | null>(null);
  const { user } = useAuthUser();

  const sendData = async (values: FormValues) => {
    if (!user) return;
    const data: InputPersonalInformation = {
      userId: user.id,
      firstName: values["fullName"].trim().split(" ", 1).toString(),
      lastName: values["fullName"].trim().split(" ").slice(1).join(" ").trim(),
      contactEmail: values["contactEmail"],
      phone: values["phone"],
    };
    const result = await providers.PersonalInformationProvider.update({ data });
    isMounted.current && setInfo(result);
  };

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    providers.PersonalInformationProvider.find({ id: user.id })
      .then((r) => {
        isMounted.current && setInfo(r);
      })
      .finally(() => isMounted.current && setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    info,
    sendData,
  };
}
