import React from "react";
import { useTranslation } from "../../locales";
import ChangeEmail from "../../components/ChangeEmail";
import useController from "./controller";

interface Props {
  onCancel: () => void;
}

function ConfirmEmail({ onCancel }: Props) {
  const { onSendEmail, email, onSetCancel } = useController(onCancel);
  const { t } = useTranslation();

  return (
    <ChangeEmail onCancel={onSetCancel} sendEmail={onSendEmail} defaultEmail={email ?? ""} title={t("Confirm email")} />
  );
}

export default ConfirmEmail;
