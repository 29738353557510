import React from "react";
import ElementLoading from "../../../../components/ElementLoading";
import BaseCard from "../../../../components/BaseCard";
import BaseForm from "../../../../components/BaseForm";
import BaseButton from "../../../../components/BaseButton";
import useViewController from "./viewController";
import { t } from "../../../../locales";
import "./style.scss";

export default function PaymentInfo() {
  const { loading, processing, values, setValues, fields, errors, setErrors, onSendData } = useViewController();
  return (
    <BaseCard className="payment-info">
      <div className="payment-info__inner">
        <h2 className="payment-info__title headline-2">{t("Payment info")}</h2>
        <p className="payment-info__subtitle body">{t("Please fill in the payment information.")}</p>
        {loading ? (
          <ElementLoading loading={loading} />
        ) : (
          <BaseForm fields={fields} values={values} onChange={setValues} errors={errors} setErrors={setErrors}>
            <BaseButton className="payment-info__save" onClick={onSendData} loading={processing} large>
              {t("Save Changes")}
            </BaseButton>
          </BaseForm>
        )}
      </div>
    </BaseCard>
  );
}
