import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentProvider";
import useAuthUser from "../../hooks/useAuthUser";
import providers from "../../lib/providers";

export default function useController() {
  const { user } = useAuthUser();

  const syncAppointmentTypes = async (cb: (appointmentTypes: AppointmentType[]) => void) => {
    const response = await providers.AppointmentProvider.syncAppointmentTypes({ userId: user?.id || "" });
    cb && cb(response);
  };

  return { syncAppointmentTypes };
}
