import React from "react";
import { t } from "../../../../locales";
import CoachingServiceItem from "../CoachingServiceItem";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import useViewController from "./viewController";
import "./style.scss";

export default function BlockCoachingServices() {
  const { coachingServiceItems, isActiveIndex, onClickCoachingService } = useViewController();

  return (
    <div className="block-coaching-services">
      <h2 className="block-coaching-services__title">{t("Coaching Services")}</h2>
      <div className="block-coaching-services__container">
        {coachingServiceItems.map((item, index) => {
          return (
            <CoachingServiceItem
              key={index}
              className="block-coaching-services__service-item"
              onClickActivator={() => onClickCoachingService(index)}
              isActive={index === isActiveIndex}
              title={item.title}
            >
              {item.content}
            </CoachingServiceItem>
          );
        })}
        <div className="block-coaching-services__description">
          <div className="block-coaching-services__description-text">
            {t(
              "There is no one-size-fits-all approach. Sessions will be tailored to provide you with the help you need. Let " +
                "your coach know what you would like to focus on or let them assess you and suggest a plan."
            )}
          </div>
          <BaseButton className="block-coaching-services__description-btn mx-auto" to={"/coaches"} large>
            {t("Find a Coach")}
            <BaseIcon className="block-coaching-services__description-btn-icon ml-1">back</BaseIcon>
          </BaseButton>
        </div>
      </div>
    </div>
  );
}
