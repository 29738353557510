import React from "react";
import { I18n, useTranslation } from "../../locales";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import BaseForm from "../BaseForm";
import ContentHeader from "../ContentHeader";
import { useViewController } from "./viewController";
import "./style.scss";

interface Props {
  email: string;
  onCancel: () => void;
  resendCode: () => Promise<void>;
  sendCode: (code: string) => Promise<void>;
  title: string;
}

function ShortCodeVerification({ email, onCancel, resendCode, sendCode, title }: Props) {
  const { fields, values, onChange, errors, setErrors, timer, onResendCode, onSendCode } = useViewController({
    onResendCode: resendCode,
    onSendCode: sendCode,
  });

  const { t } = useTranslation();

  const headline = (
    <>
      <ContentHeader className="mb-4">{title}</ContentHeader>
      <div className="text-center caption mb-6 body">
        <I18n
          path={"Enter the code we just sent to <formatted>{{ email }}</formatted>"}
          components={{
            formatted: <span className="caption__mark" />,
          }}
          values={{ email }}
        />
      </div>
    </>
  );

  return (
    <>
      <BaseForm
        fields={fields}
        values={values}
        onChange={onChange}
        errors={errors}
        setErrors={setErrors}
        className="code-verification"
        headline={headline}
        onSubmitEnter={onSendCode}
      >
        <>
          {timer > 0 ? (
            <div className="d-center body mt-2" style={{height: 40}}>{t("Get a new code in {{timer}} seconds", { timer })}</div>
          ) : (
            <BaseButton name="resend" className="mx-auto mt-2" borderless={true} onClick={onResendCode}>
              <BaseIcon className="mr-2">repeat</BaseIcon>
              <span className="fw-normal">{t("Resend verification code")}</span>
            </BaseButton>
          )}
          <div className="code-verification__buttons">
            <BaseButton name="cancel" fluid={true} large={true} outline={true} onClick={onCancel}>
              <span className="text">{t("Cancel")}</span>
            </BaseButton>
            <BaseButton name="submit" fluid={true} large={true} onClick={onSendCode}>
              <span className="text">{t("Confirm")}</span>
            </BaseButton>
          </div>
        </>
      </BaseForm>
    </>
  );
}

export default ShortCodeVerification;
