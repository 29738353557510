import { useEffect } from "react";
import { useGetSet } from "react-use";
import useBreakPoints from "../../../hooks/useBreakPoints";

export default function useResizeVideo({
  player,
  videoWrapper,
  streamView,
  leftSide,
  rightSide,
}: {
  player: HTMLVideoElement | null;
  videoWrapper: HTMLDivElement | null;
  streamView: HTMLDivElement | null;
  leftSide: HTMLDivElement | null;
  rightSide: HTMLDivElement | null;
}) {
  const { desktopUp } = useBreakPoints();
  const [getVideoWidth, setVideoWidth] = useGetSet(0);
  const [getVideoHeight, setVideoHeight] = useGetSet(0);
  const [getWindowWidth, setWindowWidth] = useGetSet(0);
  const [getWindowHeight, setWindowHeight] = useGetSet(0);

  /* resizes the video depending on the screen size and video aspect ratio,
  it will be possible to delete and make a decision in css
  when all browsers start to support the aspect-ratio css property */
  const resizeVideoWidth = () => {
    if (!streamView || !rightSide || !leftSide || !videoWrapper || !player) return;
    if (
      getVideoWidth() === player.videoWidth &&
      getVideoHeight() === player.videoHeight &&
      getWindowWidth() === document.body.offsetWidth &&
      getWindowHeight() === document.body.offsetHeight
    ) {
      return;
    }
    if ((getVideoWidth() !== 0 && player.videoWidth === 0) || (getVideoHeight() !== 0 && player.videoHeight === 0)) {
      return;
    }

    setVideoWidth(player.videoWidth);
    setVideoHeight(player.videoHeight);
    setWindowWidth(document.body.offsetWidth);
    setWindowHeight(document.body.offsetHeight);

    videoWrapper.style.width = player.offsetWidth + "px";

    const { marginRight } = getComputedStyle(leftSide);
    if (streamView.offsetWidth < leftSide.offsetWidth + rightSide.offsetWidth + parseInt(marginRight)) {
      videoWrapper.style.width = streamView.offsetWidth - rightSide.offsetWidth - parseInt(marginRight) + "px";
      player.style.width = streamView.offsetWidth - rightSide.offsetWidth - parseInt(marginRight) + "px";
    } else {
      player.style.width = "unset";
      const { minWidth } = getComputedStyle(rightSide);

      if (player.offsetWidth + parseInt(minWidth) + parseInt(marginRight) > streamView.offsetWidth) {
        videoWrapper.style.width = streamView.offsetWidth - parseInt(minWidth) - parseInt(marginRight) + "px";
        player.style.width = streamView.offsetWidth - parseInt(minWidth) - parseInt(marginRight) + "px";
      } else {
        videoWrapper.style.width = player.offsetWidth + "px";
      }
    }
  };

  /* fixes the height of the video
  and prevents it from changing when the mobile keyboard opens */
  const resizeMobileHeight = () => {
    if (!player || !videoWrapper) return;
    if (
      getVideoWidth() === player.videoWidth &&
      getVideoHeight() === player.videoHeight &&
      getWindowHeight() === window.screen.availHeight
    ) {
      return;
    }
    setVideoWidth(player.videoWidth);
    setVideoHeight(player.videoHeight);
    setWindowHeight(window.screen.availHeight);
    player.style.height = videoWrapper.offsetHeight + "px";
  };

  const resetSettings = () => {
    if (!videoWrapper || !player) return;
    videoWrapper.style.width = "";
    player.style.width = "";
    player.style.height = "";
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (desktopUp) {
      interval = setInterval(resizeVideoWidth, 50);
    } else {
      interval = setInterval(resizeMobileHeight, 50);
    }
    return () => {
      clearInterval(interval);
      resetSettings();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, desktopUp]);
}
