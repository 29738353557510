import { useMemo } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { isPerformer } from "../../../../lib/usersHelper";
import useAuthUser from "../../../../hooks/useAuthUser";
import useMessages from "../../../../hooks/useMessages";

const useViewController = (user: UserPublicData) => {
  const authUser = useAuthUser();
  const { getMessageUrl } = useMessages();

  const askLink = useMemo(() => getMessageUrl(user), [user, getMessageUrl]);
  const showBooking = useMemo(() => user.calendlyScheduleUrl && !isPerformer(authUser.user), [user, authUser]);
  const showButtons = useMemo(() => askLink || showBooking, [askLink, showBooking]);
  const firmName = useMemo(
    () => user.questionary?.fields.professional?.jobs[user.questionary.fields.professional.jobs.length - 1].firm ?? null,
    [user]
  );
  const universityName = useMemo(
    () =>
      user.questionary?.fields.academic?.educations[user.questionary.fields.academic.educations.length - 1].university ?? null,
    [user]
  );

  return {
    askLink,
    showBooking,
    showButtons,
    firmName,
    universityName,
  };
};

export default useViewController;
