import { useRef, useState } from "react";
import { useMount } from "react-use";
import { Education, QuestionaryInfo } from "@scrile/api-provider/dist/api/QuestionaryInfoProvider";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import useAuthUser from "../../../../hooks/useAuthUser";
import providers from "../../../../lib/providers";

const performerSearchFields: FragmentFields<QuestionaryInfo> = ["fields"];

export default function useController() {
  const [educations, setEducations] = useState<Education[]>([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const validationCallbacks = useRef<Array<() => boolean>>([]);
  const { user } = useAuthUser();

  const geteducationsFromProvider = async (): Promise<Education[]> => {
    const questionnaire = await providers.QuestionaryInfoProvider.find({ id: user?.id || "" }, performerSearchFields);
    const educations = questionnaire.fields.academic?.educations || [];
    if (!educations.length) educations.push({ university: "", location: "", degree: "" });
    return educations;
  };

  useMount(async () => {
    setLoading(true);
    try {
      const response = await geteducationsFromProvider();
      setEducations(response);
    } finally {
      setLoading(false);
    }
  });

  const onAdd = () => {
    setEducations((educations) => [...educations, { university: "", degree: "", location: "" }]);
  };

  const onDelete = (index: number) => {
    validationCallbacks.current.splice(index, 1);
    setEducations((prevEducations) => {
      const educations = [...prevEducations];
      educations.splice(index, 1);
      return educations;
    });
  };

  const onChange = (education: Education, index: number) => {
    setEducations((educations) => {
      educations.splice(index, 1, education);
      return educations;
    });
  };

  const setCheckValidation = (index: number, cb: () => boolean) => {
    validationCallbacks.current.splice(index, 1, cb);
  };

  const onSave = async () => {
    if (
      validationCallbacks.current.reduce((result, cb) => {
        return cb() && result;
      }, true)
    ) {
      const data: QuestionaryInfo = {
        fields: {
          userId: user?.id || "",
          academic: {
            educations,
          },
        },
      };
      try {
        setProcessing(true);
        await providers.QuestionaryInfoProvider.update({ data });
      } finally {
        setProcessing(false);
      }
    }
  };

  return {
    onSave,
    onChange,
    onDelete,
    onAdd,
    loading,
    processing,
    educations,
    setCheckValidation,
  };
}
