import React from "react";
import { Link, useParams } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { useTranslation } from "../../../../locales";
import { isPerformer } from "../../../../lib/usersHelper";
import useViewController from "./viewController";
import BaseCard from "../../../../components/BaseCard";
import BaseSpinner from "../../../../components/BaseSpinner";
import MessageInput from "../../../../components/MessageInput";
import MessagesListView from "../../../../components/MessagesListView";
import "./style.scss";

interface Props {
  setSenderUser: (userName: UserPublicData | null) => void;
}

function MessagesList({ setSenderUser }: Props) {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();

  const {
    onFocus,
    messages,
    senderUser,
    loadMore,
    scrollMessages,
    loadingMessages,
    setObserve,
    minUnreadMessageId,
    showUnread,
    reverseContentRef,
    thread,
  } = useViewController(userId, setSenderUser);

  const headingContent = <h2 className="messages-list__heading-text headline-2">{senderUser?.screenName}</h2>;

  return (
    <BaseCard className="messages-list">
      <div className="messages-list__heading">
        {isPerformer(senderUser) ? (
          <Link to={`/${senderUser?.username}`} className="d-block">
            {headingContent}
          </Link>
        ) : (
          headingContent
        )}
      </div>
      {messages.length > 0 && (
        <MessagesListView
          avatarSize={40}
          containerRef={reverseContentRef}
          messages={messages}
          infiniteScrollEnabled={scrollMessages}
          loadMore={loadMore}
          messageObserve={setObserve}
          showUnread={showUnread}
          minUnreadMessageId={minUnreadMessageId}
          showScreenName={false}
        />
      )}
      {loadingMessages && <BaseSpinner className="ma-auto" />}
      {!loadingMessages && messages.length === 0 && (
        <h3 className="messages-list__empty-text ma-auto">{t("No messages here yet")}</h3>
      )}
      <MessageInput threadIds={thread ? [thread.id] : undefined} userIds={[userId]} onFocus={onFocus} />
    </BaseCard>
  );
}

export default MessagesList;
