import React from "react";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { useTranslation } from "../../locales";
import { DefaultStreamViewProps } from "../../types";
import BaseIcon from "../BaseIcon";
import BaseButton from "../BaseButton";
import BaseAvatar from "../BaseAvatar";
import ModalWindow from "../ModalWindow";
import TransitionControl from "./components/TransitionControl";
import RippleAvatarAnimation from "./components/RippleAvatarAnimation";
import PanelControlButtons from "./components/PanelControlButtons";
import PlayStreamButton from "./components/PlayStreamButton";
import RightPanel, { RightPanelView } from "./components/RightPanel";
import useViewController from "./viewController";
import "./style.scss";

interface Props extends DefaultStreamViewProps {
  slotContentConfirmationExit?: JSX.Element;
  disableUserList?: boolean;
  slotTimer?: JSX.Element;
  disabledVideo?: boolean;
  slotVideoScreen?: JSX.Element;
  slotHeaderButtons?: JSX.Element;
  slotControlButtons?: JSX.Element;
  forceHideControlButtons?: boolean;
}

function StreamView({
  produceUser,
  isProducerUser,
  consumeUser,
  produce,
  consume,
  stream,
  managedStream,
  loading,
  threadId,
  userList,
  streamStartedDate,
  onClose,
  token,
  constraints,
  managedConstraints,
  audioInputs,
  videoInputs,
  onChangeConstraints,
  disableAttachments,
  disableUserList,
  showConfirmationExit,
  forceHideControlButtons,
  slotTimer,
  disabledVideo,
  slotVideoScreen,
  slotHeaderButtons,
  slotContentConfirmationExit,
  slotControlButtons,
}: Props) {
  const { t } = useTranslation();
  const {
    player,
    changeControls,
    changeRightPanel,
    onClickEndCall,
    desktopUp,
    mobileLandscape,
    showControls,
    showControlsButtons,
    showStreamPlayButton,
    onShowControls,
    onToggleCam,
    onToggleMic,
    onToggleBlur,
    onCloseEvent,
    onSwitchCam,
    facingMode,
    displayControls,
    hasVideo,
    hasShare,
    hasAudio,
    videoWrapper,
    streamView,
    leftSide,
    rightSide,
    usersCount,
    playStream,
  } = useViewController({
    isProducerUser,
    produce,
    stream,
    managedStream,
    constraints,
    managedConstraints,
    onChangeConstraints,
    userList,
    onClose,
    showConfirmationExit,
  });

  const mobileChatMode = displayControls.rightPanel === RightPanelView.CHAT && !desktopUp;
  const settingsMode = displayControls.rightPanel === RightPanelView.SETTINGS;
  const usersListMode = displayControls.rightPanel === RightPanelView.USERS;

  const cls = ["stream-view"];
  if (mobileChatMode) cls.push("__chat-mode");
  if (mobileLandscape) cls.push("__landscape-mode");

  return (
    <div className={cls.join(" ")} ref={streamView} onClick={onShowControls}>
      <div className="stream-view__left-side" ref={leftSide}>
        <div className={"stream-view__header" + (usersListMode && !desktopUp ? " __users-mode" : "")}>
          <TransitionControl className="stream-view__header-inner" showControl={showControls || usersListMode}>
            {slotTimer ? slotTimer : null}
            <div className="stream-view__header-buttons">
              {!disableUserList && (
                <>
                  <div className="stream-view__header-bubble mr-2">
                    <span>{usersCount}</span>
                  </div>
                  <BaseButton
                    className={
                      "stream-view__header-btn" + (produce ? "" : " __disabled") + (usersListMode ? " __active" : "")
                    }
                    name={"users"}
                    disabled={!produce}
                    onClick={() => changeRightPanel(RightPanelView.USERS)}
                  >
                    <BaseIcon className="stream-view__header-icon">consultants</BaseIcon>
                  </BaseButton>
                </>
              )}
              {slotHeaderButtons && slotHeaderButtons}
              {produce && desktopUp && !isMobile() && (
                <BaseButton
                  className={"stream-view__header-btn" + (settingsMode ? " __active" : "")}
                  name={"settings"}
                  onClick={() => changeRightPanel(RightPanelView.SETTINGS)}
                >
                  <BaseIcon className="stream-view__header-icon">settings</BaseIcon>
                </BaseButton>
              )}
            </div>
          </TransitionControl>
        </div>

        <div
          className={
            "stream-view__video-wrapper" + ((!hasVideo && !hasShare) || disabledVideo ? " __video-disabled" : "")
          }
          ref={videoWrapper}
        >
          {slotVideoScreen && <div className="stream-view__replace-video-screen">{slotVideoScreen}</div>}
          {streamStartedDate && !hasVideo && !hasShare && (
            <RippleAvatarAnimation
              className="stream-view__animation"
              size={60}
              user={isProducerUser && consume ? consumeUser : produceUser}
            />
          )}
          <TransitionControl
            className="stream-view__video-wrapper-header"
            showControl={(desktopUp || !showControls) && !usersListMode}
          >
            <div className="stream-view__live-user">
              <BaseAvatar className="stream-view__live-user-avatar" size={30} user={produceUser} />
              <p className="stream-view__live-user-name label fw-semibold">{produceUser.screenName}</p>
            </div>
            {consume && !hasAudio && streamStartedDate && (
              <div className="stream-view__muted-notify">
                <BaseIcon className="stream-view__muted-ico" size={20}>
                  mic_off
                </BaseIcon>
                <span>{t("Muted")}</span>
              </div>
            )}
          </TransitionControl>
          <video
            className={
              "stream-view__video" +
              (isProducerUser && !consume && facingMode !== "environment" && !hasShare ? " __mirror-reflection" : "")
            }
            ref={player}
            muted={isProducerUser && produce}
            playsInline
          />
          {(showStreamPlayButton || player.current?.paused) && <PlayStreamButton onClick={playStream} />}
          <PanelControlButtons
            className="stream-view__control-buttons"
            produce={produce}
            consume={isProducerUser && !!managedConstraints?.shareEnabled ? false : consume}
            loading={loading}
            showControlsButtons={!forceHideControlButtons && showControlsButtons}
            constraints={managedConstraints}
            onClickEndCall={onClickEndCall}
            onSwitchCam={onSwitchCam}
            onToggleMic={onToggleMic}
            onToggleCam={onToggleCam}
            onToggleBlur={onToggleBlur}
            player={player.current}
            slotControlButtons={slotControlButtons}
          />
          <div className="stream-view__shadow" />
        </div>

        {displayControls.confirmationExit && (
          <ModalWindow
            show={displayControls.confirmationExit}
            onClose={() => changeControls("confirmationExit", false)}
            className="stream-view__confirm-exit"
          >
            <>
              {slotContentConfirmationExit ? (
                slotContentConfirmationExit
              ) : (
                <h1 className="headline-1 text-center">{t("Do you want to end the chat?")}</h1>
              )}
              <div className="stream-view__confirm-exit-buttons">
                <BaseButton
                  large={true}
                  outline={true}
                  onClick={() => changeControls("confirmationExit", false)}
                  className="stream-view__confirm-exit-btn"
                >
                  {t("Cancel")}
                </BaseButton>
                <BaseButton
                  name="confirm-exit"
                  large={true}
                  negative={true}
                  onClick={onCloseEvent}
                  className="stream-view__confirm-exit-btn"
                >
                  <BaseIcon className="stream-view__control-icon mr-1">call</BaseIcon>
                  {t("Leave Call")}
                </BaseButton>
              </div>
            </>
          </ModalWindow>
        )}
      </div>
      <div
        className={
          "stream-view__right-side" +
          (mobileChatMode ? " __chat-mode" : "") +
          (usersListMode ? " __users-mode" : "") +
          (mobileLandscape ? " __landscape-mode" : "")
        }
        ref={rightSide}
      >
        <RightPanel
          disableAttachments={disableAttachments}
          token={token}
          threadId={threadId}
          devices={audioInputs && videoInputs && { audioInputs, videoInputs }}
          onChangeConstraints={onChangeConstraints}
          constraints={managedConstraints}
          userList={userList}
          showSection={displayControls.rightPanel}
          changeControls={changeRightPanel}
        />
      </div>
    </div>
  );
}

export default StreamView;
