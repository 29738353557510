import React, { useMemo } from "react";
import { UserVerificationStatus } from "@scrile/api-provider/dist/api/UserVerificationProvider";
import useAuthUserVerificationStatus from "../../hooks/useAuthUserVerificationStatus";
import { useTranslation } from "../../locales";
import BaseIcon from "../../components/BaseIcon";
import ContentHeader from "../../components/ContentHeader";
import BaseButton from "../../components/BaseButton";
import ElementLoading from "../../components/ElementLoading";
import PersonalInformation from "./components/PersonalInformation";
import useViewController from "./useViewController";
import "./style.scss";
import {useMount} from "react-use";

interface Props {
  onClose: () => void;
}

export default function PerformerVerification({ onClose }: Props) {
  const { t } = useTranslation();
  const { onCancel, onNext, success } = useViewController(onClose);
  const { userVerification, loading, getVerificationStatus } = useAuthUserVerificationStatus();
  const hasSendInfo = useMemo<boolean>(
    () => !success && userVerification?.status === UserVerificationStatus.UNVERIFIED,
    [success, userVerification]
  );
  const title = hasSendInfo ? "Become a Coach" : "Account was successfully sent for verification.";
  useMount(async () => {
    await getVerificationStatus();
  });

  return (
    <div className="performer-verification">
      <BaseIcon className="performer-verification__icon mb-4" size={70}>
        rocket
      </BaseIcon>
      <ContentHeader className="mb-8">{t(title)}</ContentHeader>
      {hasSendInfo && (
        <PersonalInformation className="performer-verification__step1" onCancel={onCancel} onNext={onNext} />
      )}
      {userVerification?.status === UserVerificationStatus.WAITING_FOR_APPROVAL && (
        <BaseButton className="mt-5" onClick={onCancel} large={true}>
          {t("Log out")}
        </BaseButton>
      )}
      <ElementLoading loading={loading} />
    </div>
  );
}
