import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import BasePage from "../../components/BasePage";
import BaseCard from "../../components/BaseCard";
import AppointmentsRange from "./components/AppointmentsRange";
import NavigationTabs from "../PageUserArea/components/NavigationTabs";
import ContentHeader from "../../components/ContentHeader";
import { useTranslation } from "../../locales";
import useViewController from "./viewController";
import "./style.scss";

const PageAppointments = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { topNavigationMenu } = useViewController();

  return (
    <BasePage
      className="page-appointments"
      prepend={<ContentHeader className="page-appointments__header">{t("Appointments")}</ContentHeader>}
    >
      <BaseCard className="page-appointments__card">
        <NavigationTabs itemsList={topNavigationMenu} />
        <div className="page-appointments__wrapper">
          <Switch>
            <Route path={`${match.url}/upcoming`}>
              <AppointmentsRange startTimeFrom={new Date().toISOString()} />
            </Route>
            <Route path={`${match.url}/past`}>
              <AppointmentsRange endTimeTo={new Date().toISOString()} hideStatus={true} sort="START_TIME,DESC" />
            </Route>
            <Route path={`*`}>
              <Redirect to={`${match.url}/upcoming`} />
            </Route>
          </Switch>
        </div>
      </BaseCard>
    </BasePage>
  );
};

export default PageAppointments;
