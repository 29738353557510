import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { useTranslation } from "../../locales";
import BaseButton from "../BaseButton";
import useController from "./controller";
import "./style.scss";

interface Props {
  providerUser: UserPublicData;
  scheduleUrl: string;
  className?: string;
  fullwidth?: boolean;
  children?: string;
}

const CalendlyButton = ({ providerUser, scheduleUrl, className, fullwidth, children }: Props) => {
  const { t } = useTranslation();
  const { loginLink, onClickButton } = useController(providerUser, scheduleUrl);

  const cls = ["calendly-button"];
  if (className) cls.push(className);

  return (
    <BaseButton className={cls.join(" ")} fluid={fullwidth} to={loginLink} onClick={onClickButton}>
      {children ? children : t("Book now")}
    </BaseButton>
  );
};

export default CalendlyButton;
