import { useHistory } from "react-router-dom";
import { InputUserUpdate } from "@scrile/api-provider/dist/api/UserProvider";
import { setSocialLoginStatus } from "../../lib/authHelper";
import providers from "../../lib/providers";
import useAuthUser from "../../hooks/useAuthUser";

function useController(onCancel: () => void) {
  const { user, setLogout } = useAuthUser();
  const email = user?.email;
  const history = useHistory();

  async function onSendEmail(email: string) {
    const data: InputUserUpdate = {
      id: user?.id || "",
      email: email,
    };
    await providers.UserProvider.update({ data });
    setSocialLoginStatus(false);
    history.push("/signup/confirm");
  }

  async function onSetCancel() {
    try {
      await setLogout();
      setSocialLoginStatus(false);
    } finally {
      onCancel();
    }
  }

  return {
    onSendEmail,
    onSetCancel,
    email,
  };
}

export default useController;
