import { NavigationMenuItemInterface } from "../../types";
import { t } from "../../locales";

const useViewController = () => {
  const topNavigationMenu: NavigationMenuItemInterface[] = [
    {
      title: t("Upcoming"),
      name: "upcoming",
      to: "/appointments/upcoming",
    },
    {
      title: t("Past"),
      name: "past",
      to: "/appointments/past",
    },
  ];

  return { topNavigationMenu };
};

export default useViewController;
