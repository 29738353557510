import React, { MutableRefObject } from "react";
import { useMount } from "react-use";
import { Message } from "@scrile/api-provider/dist/api/MessagesProvider";
import useAuthUser from "../../hooks/useAuthUser";
import useKeepScrollPosition from "../../hooks/useKeepScrollPosition";
import { useTranslation } from "../../locales";
import CustomScrollBar from "../CustomScrollBar";
import InfiniteScroll from "../InfiniteScroll";
import { getMessageItem } from "./components/MessageItem";
import "./style.scss";

interface Props {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  messages: Message[];
  infiniteScrollEnabled: boolean;
  className?: string;
  messageItemClass?: string;
  loadMore?: (cb: Function) => void;
  messageObserve?: (ref: HTMLDivElement | null) => void;
  showUnread?: boolean;
  minUnreadMessageId?: string;
  avatarSize: number;
  showScreenName: boolean;
  isLiveChat?: boolean;
}

const diffMinutes = (startDate: number | string | Date, endDate: number | string | Date) => {
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);
  if (d1.toDateString() !== d2.toDateString()) return;
  d1.setSeconds(0);
  d2.setSeconds(0);
  return Math.round((((d1.getTime() - d2.getTime()) % 86400000) % 3600000) / 60000);
};

function MessagesListView({
  containerRef,
  messages,
  infiniteScrollEnabled,
  className,
  messageItemClass,
  loadMore,
  messageObserve,
  showUnread,
  minUnreadMessageId,
  showScreenName,
  avatarSize,
  isLiveChat,
}: Props) {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { shouldScrollToBottom } = useKeepScrollPosition(containerRef);

  useMount(() => {
    shouldScrollToBottom.current = true;
  });

  const cls = ["messages-list-view"];
  if (className) cls.push(className);

  return (
    <CustomScrollBar className={cls.join(" ")} containerRef={containerRef}>
      <InfiniteScroll
        enable={infiniteScrollEnabled}
        onNext={loadMore}
        scrollableWrapperRef={containerRef}
        className="messages-list-view__container"
      >
        <div className="messages-list-view__clamp-block" />
        {messages.map((message, index, messages) => {
          const MessageItem = getMessageItem(message);
          if (!MessageItem) return null;

          const splitNewMessage = showUnread && minUnreadMessageId === message.id;
          const previousMessage = messages[index + 1];
          const nextMessage = messages[index - 1];

          let startMessage = true;
          let endMessage = true;

          if (previousMessage) {
            const t = diffMinutes(message.time, previousMessage.time);
            if (previousMessage.senderUser?.id === message.senderUser?.id && t === 0) startMessage = false;
          }

          if (nextMessage) {
            const t = diffMinutes(nextMessage.time, message.time);
            if (nextMessage.senderUser?.id === message.senderUser?.id && t === 0) endMessage = false;
          }

          if (splitNewMessage) startMessage = true;

          return (
            <React.Fragment key={message.id}>
              <MessageItem
                message={message}
                className={messageItemClass}
                rendered={messageObserve}
                avatarSize={avatarSize}
                showScreenName={showScreenName}
                isLiveChat={isLiveChat}
                startMessage={startMessage}
                endMessage={endMessage}
                authUser={user}
              />
              {splitNewMessage && (
                <div className="messages-list-view__new-message">
                  <div className="messages-list-view__new-message-content small">{t("New messages")}</div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </CustomScrollBar>
  );
}

export default MessagesListView;
