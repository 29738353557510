import React, { useLayoutEffect, useRef } from "react";

export default function useKeepScrollPosition(ref: React.MutableRefObject<HTMLElement | null>) {
  const shouldKeepScroll = useRef(false);
  const shouldScrollToBottom = useRef(false);
  const prevScrollPos = useRef({
    scrollHeight: ref.current?.scrollHeight ?? 0,
    scrollTop: ref.current?.scrollTop ?? 0,
    offsetHeight: ref.current?.offsetHeight ?? 0,
  });

  // store current scroll data before each react render
  prevScrollPos.current = {
    scrollHeight: ref.current?.scrollHeight ?? 0,
    scrollTop: ref.current?.scrollTop ?? 0,
    offsetHeight: ref.current?.offsetHeight ?? 0,
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!ref.current?.scrollTo) return;
      ref.current.scrollTo(0, ref.current.scrollHeight - ref.current.offsetHeight);
    });
  };
  const keepScrollPos = () => {
    if (!ref.current) return;
    const fromBottomOffset = prevScrollPos.current.scrollHeight - prevScrollPos.current.scrollTop;
    ref.current?.scrollTo(0, ref.current.scrollHeight - fromBottomOffset);
  };
  useLayoutEffect(() => {
    if (shouldKeepScroll.current) {
      keepScrollPos();
    } else if (shouldScrollToBottom.current) {
      scrollToBottom();
    }
  });
  useLayoutEffect(() => {
    shouldKeepScroll.current = false;
    shouldScrollToBottom.current = false;
  });
  return {
    shouldKeepScroll,
    shouldScrollToBottom,
  };
}
