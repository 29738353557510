import React from "react";
import { Education } from "@scrile/api-provider/dist/api/QuestionaryInfoProvider";
import { useTranslation } from "../../../../locales";
import BaseForm from "../../../../components/BaseForm";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  education?: Education;
  educationIndex: number;
  onChange: (jobValues: Education) => void;
  onDelete?: () => void;
  setCheckValidation: (key: number, cb: () => boolean) => void;
}

export default function AcademicFields({ education, educationIndex, onChange, onDelete, setCheckValidation }: Props) {
  const { t } = useTranslation();
  const { fields, values, errors, setErrors, onChangeHandler } = useViewController({
    educationIndex,
    educationValues: education,
    onChange,
    setCheckValidation,
  });

  return (
    <BaseForm
      className="experience-view-field"
      fields={fields}
      values={values}
      onChange={onChangeHandler}
      errors={errors}
      setErrors={setErrors}
    >
      {onDelete && (
        <BaseButton className="experience-view-field__button" negativeOpacity onClick={onDelete}>
          <BaseIcon size="20" className="mr-1">
            close
          </BaseIcon>
          {t("Delete")}
        </BaseButton>
      )}
    </BaseForm>
  );
}
