import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { TagInfo } from "@scrile/api-provider/dist/api/TagsProvider";
import { useTranslation } from "../../locales";
import useCategories, { TagCategoryWithTags } from "../../hooks/useCategories";
import "./style.scss";
import config from "../../config";

interface CategoryForPrinting extends TagCategoryWithTags {
  tagsForPrinting: TagInfo[];
  isMarked: boolean;
}

interface Props {
  user: UserPublicData;
  className?: string;
}

function BaseTags({ user, className = "" }: Props) {
  const { t } = useTranslation();
  const { categories } = useCategories();

  const cls = ["base-tags"];
  cls.push(className);

  const tags = user.questionary?.tags || [];

  const categoriesForPrint: CategoryForPrinting[] = categories.map((i) => ({
    ...i,
    isMarked: i.id === config.technicalCategoryId,
    tagsForPrinting: tags.filter((j) => i.id === j.categoryId),
  }));

  return (
    <ul className={cls.join(" ")}>
      {categoriesForPrint.map((category) => {
        const h: JSX.Element[] = [];
        const cls = ["base-tags__item"]
        if (category.isMarked) cls.push("__marked")
        category.tagsForPrinting.forEach((tag) => {
          h.push(
            <li className={cls.join(" ")} key={tag.id}>
              {t(tag.title)}
            </li>
          );
        });
        return h.length ? h : null;
      })}
    </ul>
  );
}

export default BaseTags;
