import useAuthUser from "../../hooks/useAuthUser";
import { isPerformer } from "../../lib/usersHelper";

const useController = () => {
  const { user } = useAuthUser();

  return {
    user,
    isPerformer: isPerformer(user),
  };
};

export default useController;
