import useAuthUser from "./useAuthUser";
import { useEffect, useRef } from "react";

export default function useOnAuthorised(cb: () => void) {
  const { user } = useAuthUser();
  const oldVal = useRef(user);
  useEffect(() => {
    if (!oldVal.current && user) {
      cb();
    }
    oldVal.current = user;
  });
}
