import { useEffect, useState } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useController from "./controller";

export default function useViewController(user: UserPublicData) {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [isHavePastAppointments, setIsHavePastAppointments] = useState(false);
  const { getIsHavePastAppointments } = useController(user);

  const onShowReviewModal = () => setShowReviewModal(true);
  const onCloseReviewModal = () => setShowReviewModal(false);

  useEffect(() => {
    (async () => {
      const status = await getIsHavePastAppointments();
      setIsHavePastAppointments(status);
    })();
  }, [getIsHavePastAppointments]);

  return {
    showReviewModal,
    onShowReviewModal,
    onCloseReviewModal,
    isHavePastAppointments,
  };
}
