import { useMount } from "react-use";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import { InputPayPalDepositPackagePurchase } from "@scrile/api-provider/dist/api/PayPalPurchaseProvider";
import useAppState from "../../../../hooks/useAppState";
import useBaseForm from "../../../../hooks/useBaseForm";
import { parseErrors } from "../../../../lib/requestHelpers";
import { submitPaymentCB } from "../../types";
import useController from "./controller";

export default function useViewController(
  setOnSubmitPaymentCB: (cb: submitPaymentCB) => void,
  pricePackageId: string,
  currentSystem: PaymentSystemPublic | null
) {
  const { systemSettings } = useAppState();
  const { sendSubmit } = useController();
  const { fields, values, errors, setValues, setErrors } = useBaseForm([]);

  const onSubmit = async () => {
    const data: InputPayPalDepositPackagePurchase = {
      pricePackageId: pricePackageId,
      paymentSystemId: currentSystem?.id || "",
      currency: systemSettings?.mainCurrency || "",
    };
    try {
      setErrors({});
      return await sendSubmit(data);
    } catch (e) {
      const errors = parseErrors(e);
      setErrors(errors);
    }
  };

  useMount(() => {
    setOnSubmitPaymentCB(onSubmit);
  });

  return {
    fields,
    values,
    errors,
    setValues,
    setErrors,
  };
}
