import { useMemo } from "react";
import { Props } from "./index";

export default function useViewController({ filtersDTO, setFilters }: Props) {
  const onStatusInput = (online: undefined | boolean) => {
    const filters = { ...filtersDTO, online, page: 0 };
    setFilters(filters);
  };
  const onNameChange = (name: string) => {
    const filters = { ...filtersDTO, name, page: 0 };
    setFilters(filters);
  };

  const onFilterTags = (tags: number[] | undefined) => {
    const filters = { ...filtersDTO, tags, page: 0 };
    setFilters(filters);
  };

  const counter = useMemo(() => {
    let count = 0;
    Object.entries(filtersDTO).forEach(([key, value]) => {
      if (["page", "sort", "name"].includes(key)) return;
      if (
        (typeof value === "string" && value) ||
        (Array.isArray(value) && value.length > 0) ||
        typeof value === "boolean"
      ) {
        count++;
      }
    });
    return count;
  }, [filtersDTO]);

  return {
    counter,
    onStatusInput,
    onFilterTags,
    onNameChange,
  };
}
