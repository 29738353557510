import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";

interface Props {
  children: React.ReactNode;
  showControl: boolean;
  className?: string;
}

function TransitionControl({ children, showControl, className }: Props) {
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition nodeRef={nodeRef} in={showControl} timeout={300} unmountOnExit classNames="show-control">
      <div ref={nodeRef} className={className}>
        {children}
      </div>
    </CSSTransition>
  );
}

export default TransitionControl;
