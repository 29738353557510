import { useHistory } from "react-router-dom";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { useHideFooter } from "../../hooks/useHideFooter";
import { useFindByUserName } from "../../hooks/useFindByUserName";

const searchFields: FragmentFields<UserPublicData> = [
  "id",
  "role",
  "username",
  "screenName",
  "disabled",
  { onlineStatus: ["isOnline"] },
  { publicLivechatUser: ["broadcasting"] },
  { avatar: ["urlPart"] },
];

export default function useController(username: string) {
  const history = useHistory();
  useHideFooter();
  const { user } = useFindByUserName(username, searchFields, () => {
    history.push("/");
  });

  const onClose = () => {
    history.replace(`/${username}`);
  };

  return {
    user,
    onClose,
  };
}
