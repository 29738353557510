import { useEffect, useState } from "react";

export default function useViewController(totalPages: number, currentPageChanged: (currentPage: number) => void) {
  const [currentPage, setCurrentPage] = useState(0);
  const pages = [0];

  if (totalPages > 5) {
    const maxItemInRow = 3;
    if (currentPage < maxItemInRow) {
      for (let i = 1; i < maxItemInRow; i++) {
        pages.push(i);
      }
    } else if (currentPage + maxItemInRow < totalPages) {
      pages.push(-1);
      pages.push(currentPage);
    }

    if (currentPage + maxItemInRow >= totalPages) {
      pages.push(-1);
      for (let i = totalPages - maxItemInRow; i < totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(-1);
      pages.push(totalPages - 1);
    }
  } else {
    for (let i = 1; i < totalPages; i++) {
      pages.push(i);
    }
  }

  const onClickPrev = () => {
    setCurrentPage((prevState) => {
      return prevState === 0 ? prevState : prevState - 1;
    });
  };

  const onClickNext = () => {
    setCurrentPage((prevState) => {
      return prevState === totalPages - 1 ? prevState : prevState + 1;
    });
  };

  useEffect(() => {
    currentPageChanged(currentPage);
  }, [currentPage, currentPageChanged]);

  return {
    pages,
    onClickPrev,
    onClickNext,
    currentPage,
    setCurrentPage,
  };
}
