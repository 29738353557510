import { useHistory } from "react-router-dom";
import useAuthUser from "./useAuthUser";
import useModalLinkPrinter from "./useModalLinkPrinter";
import { useCallback } from "react";

export default function useAuthorizedOnly<T extends (...args: any) => any>(
  cb: T,
  { notification }: { notification: string }
): (...args: Parameters<T>) => ReturnType<T> {
  const { user } = useAuthUser();
  const { getLocation } = useModalLinkPrinter();
  const history = useHistory();
  return useCallback(
    (...args) => {
      if (!user) {
        history.push(getLocation("/login", { notification }));
        console.error("Authorisation required");
      } else {
        // eslint-disable-next-line prefer-spread
        return cb.apply(undefined, args);
      }
    },
    [cb, getLocation, history, notification, user]
  );
}
