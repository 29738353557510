import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useAuthUser from "../../hooks/useAuthUser";
import BaseButton from "../../components/BaseButton";
import { useTranslation } from "../../locales";
import "./style.scss";
import StreamPreview from "./components/StreamPreview";
import { useController } from "./controller";
import MediaSettings from "../../components/StreamView/components/RightPanel/components/MediaSettings";
import BaseSpinner from "../../components/BaseSpinner";
import useBreakPoints from "../../hooks/useBreakPoints";

function GoAppointmentChatModal({ onClose }: { onClose: () => void }) {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const { user } = useAuthUser();
  const history = useHistory();
  const {  mobileLandscape } = useBreakPoints();
  const { t } = useTranslation();
  const {
    stream,
    mediaConstraints,
    onChangeConstraints,
    processing,
    loading,
    audioInputs,
    videoInputs,
  } = useController();

  const onClickStart = () => {
    history.push(`/appointment/${appointmentId}`);
  };

  const cls = ["go-appointment-chat-modal"];
  (mobileLandscape) && cls.push("__mobile-landscape");

  if (!appointmentId) onClose();
  if (!user) return null;

  return (
    <div className={cls.join(" ")}>
      {loading ? (
        <div className="d-center pa-6">
          <BaseSpinner size={140} />
        </div>
      ) : (
        <>
          <StreamPreview
            produceUser={user}
            stream={stream}
            constraints={mediaConstraints}
            onChangeConstraints={onChangeConstraints}
            loading={processing}
          />
          <div className="go-appointment-chat-modal__settings d-flex __column">
            <div className="headline-2 mb-3">{t("Join live chat")}</div>
            <MediaSettings
              className="f-grow"
              audioInputs={audioInputs}
              videoInputs={videoInputs}
              onChangeConstraints={onChangeConstraints}
              constraints={mediaConstraints}
              hideTitle
            />
            <div className="go-appointment-chat-modal__buttons">
              <BaseButton className="go-appointment-chat-modal__btn" outline large onClick={onClose}>
                {t("Cancel")}
              </BaseButton>
              <BaseButton className="go-appointment-chat-modal__btn" large onClick={onClickStart}>
                {t("Join")}
              </BaseButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default GoAppointmentChatModal;
