import React from "react";
import { t } from "../../../../locales";
import useViewController from "./viewController";
import PracticeAreaItem from "../PracticeAreaItem";
import PracticeAreaTags from "../PracticeAreaTags";
import "./style.scss";

export default function BlockPracticeAreas() {
  const { categories, isActiveIndex, onClickPracticeArea } = useViewController();

  return (
    <div className="block-practice-areas">
      <h2 className="block-practice-areas__title">{t("Our Practice Areas")}</h2>
      <div className="block-practice-areas__container">
        {categories.map((category, index) => (
          <PracticeAreaItem
            key={category.id}
            className="block-practice-areas__category-item"
            category={category}
            isActive={isActiveIndex === index}
            onClickActivator={() => onClickPracticeArea(index)}
          />
        ))}
      </div>
      {categories.length && isActiveIndex !== undefined && (
        <div className="block-practice-areas__category-container">
          <h3 className="block-practice-areas__category-title">{categories[isActiveIndex].title}</h3>
          <div className="block-practice-areas__category-description">{categories[isActiveIndex].description}</div>
          <PracticeAreaTags tags={categories[isActiveIndex].tags} />
        </div>
      )}
    </div>
  );
}
