import React, { CSSProperties } from "react";
import { useTranslation } from "../../../../locales";
import ElementLoading from "../../../../components/ElementLoading";
import useViewController from "./viewController";
import BaseForm from "../../../../components/BaseForm";
import BaseButton from "../../../../components/BaseButton";
import UploadCV from "../UploadCV";

interface Props {
  onCancel: () => void;
  onNext: () => Promise<void>;
  className?: string;
  style?: CSSProperties;
}

export default function PersonalInformation({ onCancel, onNext, style, className }: Props) {
  const { t } = useTranslation();
  const { processing, loading, fields, values, errors, setValues, setErrors, onSubmit } = useViewController(onNext);
  return (
    <div className={className} style={style}>
      <BaseForm fields={fields} values={values} onChange={setValues} errors={errors} setErrors={setErrors}>
        <UploadCV className="mt-8"/>
        <div className="d-flex mt-8">
          <BaseButton className="ml-auto mr-2" outline={true} large={true} onClick={onCancel}>
            {t("Cancel")}
          </BaseButton>
          <BaseButton loading={processing} large={true} onClick={onSubmit}>
            <span>{t("Submit")}</span>
          </BaseButton>
        </div>
      </BaseForm>
      <ElementLoading loading={loading} />
    </div>
  );
}
