import React from "react";
import BasePage from "../../components/BasePage";
import BlockAboutUsHeader from "./components/BlockAboutUsHeader";
import BlockFromFounders from "./components/BlockFromFounders";
import BlockAboutOwners from "./components/BlockAboutOwners";
import BlockAboutUsFooter from "./components/BlockAboutUsFooter";
import "./style.scss";

export default function PageAboutUs() {
  return (
    <BasePage className="page-about-us">
      <BlockAboutUsHeader />
      <BlockAboutOwners />
      <BlockFromFounders />
      <BlockAboutUsFooter />
    </BasePage>
  );
}
