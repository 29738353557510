import React from "react";
import BaseForm from "../BaseForm";
import ContentHeader from "../ContentHeader";
import BaseButton from "../BaseButton";
import { useTranslation } from "../../locales";
import { useViewController } from "./viewController";
import "./style.scss";

interface Props {
  onCancel: () => void;
  sendEmail: (email: string) => Promise<void>;
  title: string;
  defaultEmail?: string;
}

function ChangeEmail({ onCancel, sendEmail, title, defaultEmail }: Props) {
  const { fields, values, errors, setErrors, onChange, onSendEmail } = useViewController({
    onSendEmail: sendEmail,
    defaultEmail,
  });
  const { t } = useTranslation();

  return (
    <>
      <BaseForm
        fields={fields}
        values={values}
        onChange={onChange}
        errors={errors}
        setErrors={setErrors}
        className="change-password"
        headline={<ContentHeader className="change-password__header">{title}</ContentHeader>}
        onSubmitEnter={onSendEmail}
      >
        <div className="change-password__buttons">
          <BaseButton name="cancel" fluid={true} large={true} outline={true} onClick={onCancel}>
            <span className="text">{t("Cancel")}</span>
          </BaseButton>
          <BaseButton name="submit" fluid={true} large={true} onClick={onSendEmail}>
            <span className="text">{t("Continue")}</span>
          </BaseButton>
        </div>
      </BaseForm>
    </>
  );
}

export default ChangeEmail;
