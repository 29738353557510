import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export function useOnRouteChange(cb: () => void) {
  const history = useHistory();
  useEffect(() => {
    const unregisterHistoryListener = history.listen(() => {
      cb();
    });
    return () => {
      unregisterHistoryListener();
    };
  }, [cb, history]);
}
