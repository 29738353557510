import twitter from "../../lib/twitter";
import { setSocialLoginStatus } from "../../lib/authHelper";
import providers from "../../lib/providers";
import config from "../../config";
import facebook from "../../lib/facebook";
import { useState } from "react";

function useController(onClose: () => void) {
  const [isLoading, setIsLoading] = useState(false);
  const twLogin = () => {
    twitter
      .login()
      .then(async (response) => {
        if (response) {
          setSocialLoginStatus(true);
          setIsLoading(true);
          await providers.LoginProvider.twitterLogin({
            data: { ...response, role: config.userJoinRole },
          });
          onClose();
        }
      })
      .catch((e) => {
        setSocialLoginStatus(false);
        setIsLoading(false);
        throw e;
      });
  };

  const fbLogin = () => {
    facebook
      .login()
      .then(async (token) => {
        if (!token || !token.authResponse) return;
        setSocialLoginStatus(true);
        setIsLoading(true);
        await providers.LoginProvider.facebookLogin({
          data: { role: config.userJoinRole, token: token.authResponse.accessToken },
        });
        onClose();
      })
      .catch((e) => {
        setSocialLoginStatus(false);
        setIsLoading(false);
        throw e;
      });
  };

  return {
    fbLogin,
    twLogin,
    isLoading,
  };
}

export default useController;
