import React from "react";
import { t } from "../../../../locales";
import FullWidthPageBackground from "../../../../components/FullWidthPageBackground";
import "./style.scss";

export default function BlockWhyCoaching() {
  return (
    <div className="block-why-coaching">
      <FullWidthPageBackground className="block-why-coaching__background" />
      <div className="block-why-coaching__content">
        <h2 className="block-why-coaching__title">{t("Why Coaching")}</h2>
        <p className="block-why-coaching__description">
          {t(
            "Securing an offer from a financial institution is challenging. The interview process is rigorous " +
              "and involves behavioural questions, brainteasers, case studies, modelling, and more. You will also " +
              "be competing against the best candidates. Don’t short-change your career and book with one of our " +
              "hand-picked coaches now."
          )}
        </p>
      </div>
    </div>
  );
}
