import React from "react";
import BasePage from "../../components/BasePage";
import BlockAbout from "./components/BlockAbout";
import BlockWhyCoaching from "./components/BlockWhyCoaching";
import BlockPracticeAreas from "./components/BlockPracticeAreas";
import BlockOurCoaches from "./components/BlockOurCoaches";
import BlockCoachingServices from "./components/BlockCoachingServices";
import BlockHowItWorks from "./components/BlockHowItWorks";
import BlockBookACoach from "./components/BlockBookACoach";
import BlockTestimonials from "./components/BlockTestimonials";
import "./style.scss";

export default function PageLanding() {
  return (
    <BasePage className="page-landing">
      <BlockAbout />
      <BlockWhyCoaching />
      <BlockPracticeAreas />
      <BlockOurCoaches />
      <BlockCoachingServices />
      <BlockHowItWorks />
      <BlockTestimonials />
      <BlockBookACoach />
    </BasePage>
  );
}
