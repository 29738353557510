import { useMemo, useRef } from "react";
import { useMount } from "react-use";
import { Message } from "@scrile/api-provider/dist/api/MessagesProvider";
import useBreakPoints from "../../../../../hooks/useBreakPoints";
import { get24formatTime } from "../../../../../lib/timeHelper";

export default function useController(message: Message, rendered?: (ref: HTMLDivElement | null) => void) {
  const time = useMemo(() => get24formatTime(message.time), [message.time]);

  const ref = useRef<HTMLDivElement>(null);
  useMount(() => {
    rendered && rendered(ref.current);
  });
  const { desktopUp } = useBreakPoints();

  return {
    desktopUp,
    senderUser: message.senderUser,
    time,
    ref,
  };
}
