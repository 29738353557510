import { useState } from "react";
import { AppointmentType } from "@scrile/api-provider/dist/api/AppointmentProvider";
import useController from "./controller";

export default function useViewController() {
  const [syncing, setSyncing] = useState(false);
  const { syncAppointmentTypes } = useController();

  const onSyncButtonClick = async (cb: (appointmentTypes: AppointmentType[]) => void) => {
      if (syncing) return;
      try {
        setSyncing(true);
        await syncAppointmentTypes(cb);
      } finally {
        setSyncing(false);
      }
  };

  return {
    syncing,
    onSyncButtonClick,
  };
}
