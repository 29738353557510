import { openPopupWidget } from "react-calendly";
import { Prefill } from "react-calendly/typings/calendly";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useAuthUser from "../../hooks/useAuthUser";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import emitter, { EVENTS } from "../../lib/emitter";

const useController = (providerUser: UserPublicData, scheduleUrl: string) => {
  const { user: loggedUser } = useAuthUser();
  const { getLocation } = useModalLinkPrinter();
  const loginLink = loggedUser ? undefined : getLocation("/login");

  const prefill: Prefill = {
    email: loggedUser?.email || "",
    name: loggedUser?.screenName || "",
  };

  const onClickButton = () => {
    openPopupWidget({ url: scheduleUrl, prefill });
    emitter.emit(EVENTS.SET_BOOKING_USER, providerUser);
  };

  return {
    loginLink,
    onClickButton,
  };
};

export default useController;
