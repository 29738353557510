import React from "react";
import { useParams } from "react-router-dom";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import BasePage from "../../components/BasePage";
import ProduceAppointmentController from "./components/ProduceAppointmentController";
import ConsumeAppointmentController from "./components/ConsumeAppointmentController";
import useViewController from "./viewController";
import "./style.scss";

export default function PageAppointmentChat() {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const { onClose, appointment, produce, authUser, key } = useViewController(appointmentId);

  if (!authUser || !appointment) return null;
  return (
    <BasePage className="page-appointment-chat">
      {produce ? (
        <ProduceAppointmentController
          key={key}
          appointment={appointment}
          produceUser={appointment.provider as UserPublicData}
          consumeUser={appointment.consumer as UserPublicData}
          livechatSubject={{ subjectId: appointmentId, subjectType: LivechatSubjectType.APPOINTMENT }}
          onClose={onClose}
        />
      ) : (
        <ConsumeAppointmentController
          key={key}
          appointment={appointment}
          produceUser={appointment.provider as UserPublicData}
          livechatSubject={{ subjectId: appointmentId, subjectType: LivechatSubjectType.APPOINTMENT }}
          onClose={onClose}
        />
      )}
    </BasePage>
  );
}
