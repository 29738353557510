import React from "react";
import FullWidthPageBackground from "../../../../components/FullWidthPageBackground";
import BaseButton from "../../../../components/BaseButton";
import { t } from "../../../../locales";
import "./style.scss";

export default function BlockBookACoach() {
  return (
    <div className="block-book-a-coach">
      <FullWidthPageBackground className="block-book-a-coach__background" />
      <div className="block-book-a-coach__content">
        <h2 className="block-book-a-coach__title">
          {t("Book a session with a coach today and be one step closer to securing an offer")}
        </h2>
        <BaseButton className="block-book-a-coach__btn" to={"/coaches"} large borderless>
          {t("Find a Coach")}
        </BaseButton>
      </div>
    </div>
  );
}
